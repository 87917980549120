.nav-overlay {
    width: 100%;
    display: none;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.38);
    z-index: 2;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1013;	
    display: none;
    &.active {
    	display: block;
    }
}

