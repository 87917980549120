.form-edit-account {
	.box-content {
		margin-bottom: 0 !important;
	}
	input {
	    margin-bottom: 25px;
	    padding-left: 25px;
	    background: none !important;

	    color: #5d5d5d;
	    border: 1px solid #5d5d5d;
	    font-size: 16px;
	    font-weight: 300;
	    height: 50px;
	    max-width: 100%;
	    width: 100%;
	    display: block;
	    background-position: center left 20px;
	    font-size: 16px;
	    background-repeat: no-repeat;
	    font-family: "Lato", sans-serif;
	    border-radius: 2px;

	    	&#firstname, &#lastname {
	    		background-repeat: no-repeat;
			    background-image: url(../images/icon-set/name-icon.png);
	    	}
	    	&#email {
	    		background-repeat: no-repeat;
			    background-image: url(../images/icon-set/email-icon.png);
			    &::placeholder {
			    	color: $grey-font;
			    }
	    	}
	    	&#current-password {
	    		background-repeat: no-repeat;
			    background-image: url(../images/icon-set/padlock.png);
			    background-size: 23px 23px;
			    &::placeholder {
			    	color: $grey-font;
			    }
	    	}
	    	&#password {
	    		background-repeat: no-repeat;
			    background-image: url(../images/icon-set/padlock.png);
			    background-size: 23px 23px;
			   &::placeholder {
			    	color: $grey-font;
			    }
	    	}
	    	&#password-confirmation {
	    		background-repeat: no-repeat;
			    background-image: url(../images/icon-set/password-confirm-icon.svg);
			    background-size: 26px 26px;
			   &::placeholder {
			    	color: $grey-font;
			    }
	    	}
	}

	.fieldset.password {
		float: none;
		width: 100%;
		display: block;
	}

	.field {
		&.choice {
			display: block;
			margin: 0 0 25px 0;

				input {
					display: none;
				}

				input[type='checkbox']:checked + label {
					background-color: $grey-font;
    				color: #fff;
				}


				#change-email + label {
				    @include icon-email;
				}

				// Change the icon of the email when pressed / hovered
				#change-email {
					&:checked + label, &:hover + label {
						@include icon-email-white;
					}
				}

				#change-password + label {
					background-repeat: no-repeat;
					@include icon-password;
				}
				

				// Change the icon of the password when hovered/pressed
				#change-password {
					&:checked + label, &:hover + label {
						@include icon-password-white;
					}
				}

				label {
					-webkit-appearance: none;
				    -moz-appearance: none;
				    appearance: none;
				    background-color: #f4f4f4;
				    font-weight: normal;
				    border: none;
				    min-height: 52px;
				    display: block;
				    text-align: left;
				    padding-top: 17px;
				    text-transform: uppercase;
				    letter-spacing: 1px;
				    color: $grey-font;
				    margin-bottom: 25px;
				    font-size: 16px;
				    background-repeat: no-repeat;
				    background-position: left 15px center;
				    background-position-x: left 15px;
				    background-position-y: center;
				    padding-left: 58px;
				    cursor: pointer;
				    white-space: normal;
				    &:hover {
				    	background-color: $grey-font;
				    	color: $brand-secondary;
				    }
				}
		}
	}

	button {
		width: 100%;
	    max-width: 100%;
	    border: none;
	    height: 50px;
	    @include standard-font-regular;
	    background-color: $brand-primary;
	    text-transform: uppercase;
	    text-decoration: none;
	    box-shadow: none;
	    letter-spacing: 1px;
	    border-radius: 2px;
    		&:hover {
	    		background-color: #eae4e1;
			    opacity: 0.8;
			    color: black;
	    	}
	}
}