.product-info-wrapper {

	.block.crosssell, .block.related {
		opacity: 0;
		padding: 50px 0 0 0;
		clear: both;
		width: 100%;
	    float: none;
		transition: 250ms all;
		float: left;
		@media(max-width: $screen-md) {
			margin-top: 0;
		}
		&.fade-in-active {
			opacity: 1;
		}

		.product-image-photo {
			border: none;
		}

		.products-grid .item.product {
			margin: 0px 0px 15px 0px;
		}

		.product-item-image {
			a {
				outline: none;
			}
		}
		.product-item-name {
			margin: 0;
			a {
				font-weight: 300;
			}
		}
		.block-title {
			display: block;
			text-align: center;
			font-weight: 400;
			margin: 0;
			text-transform: uppercase;
			font-size: 16px;
			margin: 0 0 30px 0;
			color: $text-primary;
		}
		.block-actions {
			clear: both;
			margin: 0px 0 0 0;
			@media(max-width: 767px) {
				display: none;
			}
			button {
				max-width: 480px;
				margin: 0px auto;
				font-size: 16px;
				text-transform: uppercase;
				background-color: #f2f2f2;
				display: block;
				width: 100%;
				height: 50px;
				color: $text-primary;
				letter-spacing: 1px;
			    box-shadow: none;
			    border-radius: 0;
			    margin-top: 25px;
			    border: none;
				&:hover {
					opacity: 0.8;
					background-color: #f2f2f2;
					color: $text-primary;
					text-decoration: none;
				}
				span {
					color: $text-primary;
				}
			}
		}
		#block-related-heading {
			font-weight: 400;
			font-size: 16px;
			@media(max-width: $screen-sm) {
				margin-bottom: 25px;
				display: block;
			}
		}
	}



	.products-related, .products-crosssell {
		margin: 30px 0 15px 0;
		overflow: hidden;


		.product-items {
			margin: 0 0 0 -15px;
		}

		@media(max-width: 767px) {
			margin-bottom: 20px;
			overflow: visible;
		}

		.slick-list {
			padding: 0 45px 0 0px;

			@media (max-width: $screen-sm-max) {
				padding: 0;
			}
		}
		.slick-arrow {
			opacity: 0;
			transition: 250ms all;
			transform: none;
		}

		&:hover {
			.slick-arrow {
				opacity: 1;
			}
		}

		.slick-arrow {
		    background-color: rgba(255, 255, 255, 0.45);
		    position: absolute;
			height: 100%;
			width: 50px;
			top: 0;
			background-repeat: no-repeat;
			border: none;
			color: transparent;
			text-indent: -99999px;
			background-size: 14px 30px;
			border-radius: 0;
			box-shadow: none;
		}

		.slick-prev {
			background-position: center center;
			@include icon-related-arrow-left;
			background-size: 17px 32px;
			z-index: 10;
			left: 15px;
			@media(max-width: $screen-sm) {
				left: 0px;
			}
		}

		.slick-next {
			right: 0px;
			@include icon-related-arrow-right;
	   		background-position: center center;
			background-size: 17px 32px;
	   		@media(max-width: $screen-sm) {
	   			right: 0px;
	   		}
		}

		@media(max-width: 767px) {
			margin-top: 5px;
		}
		.product-item-photo {
			display: block;
		}
		.product-item-image {
			position: relative;
		}
		.product-item-info {
			width: 100% !important;
			color: $text-primary;
			a {
				outline: none;
			}
			.product-image-wrapper {
			}
			&:hover {
				background: none;
				box-shadow: none;
				border: none;
				padding: 0;
				margin: 0;
			}
			.field.choice.related {
				bottom: auto;
				left: 15px;
				right: auto;
				top: 20px;
				right: 0;
				position: absolute;
				input {
					float: left;
				    border: 1px solid #5d5d5d;
				    margin: 0 25px 0 0;
				    width: 20px;
				    height: 20px;
				    z-index: 20000;
				    border-radius: 100%;
				    appearance: none;
				    outline: none;
				    @media(max-width: 767px) {
				    	display: none;
				    }
				    &:checked {
						background: #5d5d5d;
				    }
				}
			}
		}
		.product-item-details {
			text-align: center;
			display: block;
			font-size: 16px;
			padding-top: 0;

			.product-item-name {
				font-size: 16px;
				color: $text-primary;
				font-weight: 400;
				margin: 0 0 22px 0;

			}

			@media(max-width: 767px) {
				padding-top: 5px;
			}
			.product-item-link {
				display: block;
				margin: 0 0 0px 0;
			}
			strong {
				margin: 0;
			}
			.price {
				font-size: 16px;
			}
			.old-price {
				.price {
					@include text-line-through;
					margin-right: 5px;
				}
			}
		}
	}
}