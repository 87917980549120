.toolbar.toolbar-products {
	position: relative;

}
.page-products .toolbar select#sorter {
	border-radius: 0px;
	border-color: $text-primary;
}

.page-products .toolbar-products-end {
	margin-bottom: 10px;
}
.toolbar-segment-wrapper {
	position: absolute;
	right: 15px;
	top: 5px;
	@media(max-width: $screen-md) {
		top: 110px;
		right: 0;
	}
}