body {
	&.checkout-cart-index {
		.cart-container {
			.form-cart {
				width: 100%;
				max-width: 100%;
				padding: 0;
					.actions {
						&.main {
							.continue {
								display: none;
							}
							.clear {
								display: none;
							}
						}
					}
			}
			.action {
				&.update {
					text-transform: uppercase;
					font-weight: 300;
					max-width: 300px;
					border: none;
					width: 100%;
				    border: none;
				    color: #000;
				    height: 50px;
				    background-color: #f4f4f4;
				    text-transform: uppercase;
				    font-size: 16px;
				    font-weight: 300;
				    text-decoration: none;
				    box-shadow: none;
				    letter-spacing: 1px;
				    border-radius: 0;
				    &:before {
				    	display: none;
				    }
				    @media(max-width: 767px) {
						max-width: 100%;
					}
				}
				&.primary {
				    	&.checkout {
				    		@extend .standard-button;
				    		font-weight: 600;
				    		@media(max-width: $screen-md) {
				    			width: 50%;
				    			margin-left: auto;
				    			margin-right: auto;
				    		}
				    		@media(max-width: $screen-sm) {
				    			width: 100%;
				    		}
				    		@media(max-width: $screen-md) {
				    			font-size: 20px;
				    		}
				    	}

				    }
			}
			.continue-shopping-button {
				display: none;
			}
			.summary {
				&.title {
					display: none;
				}
			}
			#block-shipping {
				border: 1px solid $grey-font;
				padding: 25px;
				.item-title {
					display: none;
				}
				@media(max-width: $screen-md) {
					border-left: none;
					border-right: none;
					border-bottom: none !important;
					padding: 40px 0;

					#block-shipping-heading {
						padding: 0 0 40px 0;
					}
				}
				#block-summary {
					#shipping-zip-form {
						display: none;
					}
				}
					#block-shipping-heading {
						text-align: center;
					    text-transform: uppercase;
					    display: block;
					    font-weight: 300;
					    margin: 0;
					    color: $text-primary;
				    	font-size: 16px;
					    letter-spacing: 1px;
					    padding: 0 0 35px 0;
					}
					.shipping-note {
						font-size: 10px;
					    color: #000;
					    text-align: center;
					    padding: 10px 0px 0px 0px;
					    @media(max-width: $screen-sm) {
					    	font-size: 16px;
					    	a {
					    		font-weight: 600;
					    	}
					    }
					    a {
					    	color: $text-primary;
					    }
					}
			}
			#cart-totals {
				border: 1px solid #000;
			    padding: 25px 25px 10px 25px;
			    margin-bottom: 25px;
					@media(max-width:$screen-sm) {
						border-bottom: none;
						margin-bottom: 10px;
						padding-bottom: 0;
					}
			    @include standard-font-light;
			    text-transform: uppercase;
			    text-align: right;
			    h2 {
			    	text-align: center;
					text-transform: uppercase;
					display: block;
					font-weight: 300;
					margin: 0;
					color: $text-primary;
					font-size: 16px;
					letter-spacing: 1px;
					padding: 0 0 35px 0;
			    }
			    	// .table-wrapper {
			    	// 	max-width: 300px;
			    	// 	display: inline-block;
			    	// }
			    	.data {
			    		&.table {
			    			&.totals {
			    				margin: 0;
			    			}
			    		}
			    	}
			    	.mark {
			    		background: none;
			    		border: none !important;
			    		padding-bottom: 30px;
			    		@media(max-width: $screen-sm) {
			    			text-align: left;
			    			padding-bottom: 15px;
			    		}
			    	}
			    	.amount {
			    		border: none !important;
			    	}
			    	.label {
			    		font-size: 16px;
					    font-weight: 400;
					    color: #000;
					    padding: 0;
			    	}
			    	.totals {
			    		&.shipping {
			    			&.excl {
			    				.value {
			    					display: none;
			    				}
			    			}
			    		}
			    	}
			    	.grand {
						&.incl {
							.order-total-title {
				    			font-weight: 700;
				    			padding: 3px 0 0;
				    			font-size: 20px;
				    		}
				    		strong {
								font-weight: 700;
								text-transform: none;
								font-size: 20px;
				    		}
						}


			    		&.excl {
			    			font-size: 16px;
				    		font-weight: 400;

			    			strong {
			    				text-transform: none;
			    				font-weight: normal;
			    			}
			    		}
			    	}

			}

			.cart {
				&.main {
					&.actions {
						padding-bottom: 20px;
					}

				}
			}

		}
	}
}

#shopping-cart-table {
	tr {
		border-bottom: 1px solid $grey-font;
		position: relative;
			@media(max-width: 767px) {
				border: none;
			}
	}
	.col {
		height: 100%;
		@media(max-width: 767px) {
			float: right;
			width: 50%;
			text-align: right;
			border: none;
		}

			&.subtotal {
				position: relative;
				display: table-cell;
				height: 100%;
				@media(max-width: $screen-sm) {
					.cart-price {
						padding-right: 35px;
					}
				}
			}
			@media(max-width: 767px) {
				&.item {
					width: 50%;
					float: left;
					padding: 0;
					text-align: left;
				}
				&.price {
					display: none;
				}
				&.qty {
					padding-bottom: 10px !important;
					padding-top: 14px !important;
					&:before {
						display: none;
					}
				}
				&.subtotal {
					padding-bottom: 10px !important;
					padding-top: 14px !important;
					&:before {
						display: none;
					}
				}
				&.sku {
					padding: 0px 6px 0px 0px;
					@media(max-width: $screen-md) {
						margin-bottom: 15px;
					}
				}
				&.remove {
					display: none;
				}
			}

	}
	th {
	    @include standard-font-light;
	    text-transform: uppercase;
	    padding-left: 30px;
	    padding-top: 0;
	    border: none !important;

	    	&.remove {
				float: right;
				opacity: 0;
			}
	}

	td {
	    @include standard-font-light;
	    padding-left: 30px;
	    vertical-align: middle;
	    padding-top: 29px;
	    padding-bottom: 29px;

	    	span {
				font-weight: 100;
	    	}
	}
	.product-item-photo {
		display: block;
	    width: 210px;
	    display: inline-block;
	    vertical-align: middle;
	    outline: none;
	    border: none;
	    margin-bottom: 0px;
	    	@media(max-width: 767px) {
				position: static;
			    float: left;
			    width: 160px;
			    height: 160px;
			    max-width: none;
			    margin-bottom: 25px;
			}
	}
	@media(max-width: 767px) {
		.photo {
			&.image {
				width: auto;
				height: auto;
				max-width: none;
				padding-bottom: 0;
			}
		}
	}
	.product-image-photo {
	    border: none;
	    margin-bottom: 0px;
	}
	.product-item-details {
		display: inline-block;
		vertical-align: middle;
		width: auto;
		@include standard-font-light;
		padding: 0;
			@media(max-width: 767px) {
				display: none;
			}
	}
	.product-mobile-name {
		display: none;
			@media(max-width: 767px) {
				margin-bottom: 15px;
				display: block;
				text-align: right;
			}
	}
	.action-edit {
		display: none;
	}
	.basket-action-remove {
		span {
			text-indent: -999px;
		    overflow: hidden;
		    height: 20px;
		    width: 20px;
		    background-repeat: no-repeat;
		    background-image: url(../images/icon-set/CLOSE-CROSS-DESKTOP.svg);
		    color: transparent;
		    display: block;
		    float: right;
		}
	}
}

#block-discount {
	display: none;
}
