body {
	&.account {

		#maincontent {
			
			@media(max-width: $screen-md) {
				.actions-toolbar {
					margin-left: 0 !important;
					padding: 0 10px;
				}
			}

			.block-content, .guest-rma-listing .block-content {
			    border: 1px solid #5d5d5d;
			    padding: 25px;
			    border-radius: 2px;
			    margin-bottom: 25px;
			    border-radius: 0;
			    min-height: 326px;
				
				.box-content {
					min-height: 95px;
				}

		    	@media(max-width: $screen-md) {
					padding: 40px 10px;	
					border: none;
					border-top: 1px solid #5d5d5d;
					margin-bottom: 0;
				}
			} 
			.block-content-order {
				border: 1px solid #5d5d5d;
			    padding: 25px 25px 0 25px;
			    border-radius: 2px;
			    margin-bottom: 25px;
			    	@media(max-width: $screen-md) {
						padding: 40px 0;	
						border: none;
						border-top: 1px solid #5d5d5d;
						margin-bottom: 0;
					}
			}
			.box-content, .guest-rma-listing .box-content {
				margin: 0 0 41px 0;
			}
			.box-content-order {
				margin: 0;
			}
			.box-actions {
				a {
					text-align: center;
				    display: block;
				    padding: 15px 0 0 0;
				    font-weight: 300;
				    letter-spacing: 1px !important;
				    line-height: 1;

				    	width: 100%;
					    max-width: 100%;
					    border: none;
					    height: 50px;
					    background-color: $brand-primary;
					    @include standard-font-regular;
					    text-transform: uppercase;
					    text-decoration: none;
					    box-shadow: none;
					    letter-spacing: 1px;
					    border-radius: 0px;
					    &:hover {
				    		background-color: #eae4e1;
						    opacity: 0.8;
						    color: black;
				    	}
				}
				p {
					@include standard-font-light;
					text-align: center;
					margin: 0 0 5px;
				}
			} 

			.box-title, .guest-rma-listing .box-title {
				@include standard-font-light;
				text-align: center;
			    display: block;
			    text-transform: uppercase;
			    padding-bottom: 63px;
			    @media(max-width: $screen-sm) {
			    	padding-bottom: 50px;
			    }
			}
		} 
		
	} 
}

@media (max-width: $screen-sm) {
	body.account.sales-order-view #maincontent .block-content-order {
	    padding: 40px 0;
	    border: none;
	    border-top: 1px solid #5d5d5d;
	    border-bottom: 1px solid #5d5d5d;
	    margin-bottom: 0;
	}
}