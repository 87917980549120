.row-eq {
    display: flex;
    flex-wrap: wrap;
	flex-direction: row;

	&:before, &:after {
		content: none;
		display: none;
	}

    &> div {
        display: flex;
        flex-direction: column;
		flex: 0 1 auto;
        @media(max-width: 991px) {
        	display: block;
        }
    }
    @media(max-width: 991px) {
    	display: block;
    	flex-wrap: initial;
    }
}

.standard-title {
	font-size: 24px;
	letter-spacing: 2px;
	text-align: center;
	display: block;
	margin: 0 0 30px 0;
	text-transform: uppercase;
	font-weight: 300;
	color: $text-primary;
}

.standard-input {
	@include input-styling;
	height: 50px;
	max-width: 100%;
	width: 100%;
	display: block;
	padding: 0 20px 0 20px;
	background-position: center left 7px;
	font-size: 16px;
	background-repeat: no-repeat;
	font-family: $font-family-lato;
	border-radius: 0px;
	&:focus {
		border-width: 2px;
    	outline: none;
    	box-shadow: none;
	}
	&::placeholder {
		@include input-placeholder;
		@media(max-width: $screen-sm) {
			font-size: 13px;
		}
	}
	&.mage-error {
		@include input-error;
	}
	@media(max-width: $screen-sm) {
		height: 45px;
		font-size: 13px;
	}
}

.standard-button {
	width: 100%;
	max-width: 100%;
	border: none;
	color: $text-primary;
	height: 50px;
	background-color: $brand-primary;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: map-get($weight, medium);
	text-decoration: none;
	box-shadow: none;
	letter-spacing: 1px;
	border-radius: 0px;
	@media(max-width: $screen-md) {
		font-size: 13px;
		height: 45px;
	}
	&:hover {
		opacity: 0.8;
		border: none;
	}
	&:active, &:focus {
		color: $text-primary;
		opacity: 0.8;
	}
}

.alternate-button {
	width: 100%;
	max-width: 100%;
	border: none;
	color: $brand-primary;
	height: 50px;
	background-color: #f4f4f4;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: 300;
	text-decoration: none;
	box-shadow: none;
	letter-spacing: 1px;
	border-radius: 0;
	@media(max-width: $screen-md) {
		font-size: 13px;
		height: 40px;
	}
	&:hover {
		color: $brand-primary;
		opacity: 0.8;
		background-color: #f4f4f4;
		border: none;
	}
	&:active, &:focus {
		color: $brand-primary;
		background-color: #f4f4f4;
		opacity: 0.8;
	}
}


.standard-input-with-icon {
	@extend .standard-input;
	padding-left: 25px;
	background-image: none !important;
}

.standard-select {
	@extend .standard-input;
	@include icon-dropdown;
	background-position: right 20px center;
}

.standard-select-with-icon {
	@extend .standard-select;
	padding-left: 65px;	
	padding-left: 25px !important;
}

.standard-table {
	tr {
		th {
			padding:  0 0 25px 0;
			text-transform: uppercase;
			font-weight: 300;
			font-size: 16px;
		}
		td, th {
			border: none;
			vertical-align: middle;
		}
	}
	thead {
		tr {
			border-bottom: 1px solid $brand-primary;
		}
	}
	tbody {
		tr {
			border-bottom: 1px solid $brand-primary;
			padding: 25px 0 25px 0;
			td {
				padding: 25px 0 25px 0;
			}

			&:last-child {
				border-bottom: none;
			}
		}

	}
}

.standard-textarea {
	@include input-styling;
	min-height: 200px;
	max-width: 100%;
	width: 100%;
	display: block;
	padding: 15px 30px;

	&:focus {
		border-width: 2px;
    	outline: none;
    	box-shadow: none;
	}

	&.mage-error {
			@include input-error;
	}

	&::placeholder {
		@include input-placeholder;
	}
}

.standard-textarea-with-icon {
	@extend .standard-textarea;
	//padding-left: 65px;
	background-position: 15px 13px;
	background-repeat: no-repeat;

}

#contact textarea {
	padding: 15px 25px;
}
.wholesale-application textarea {
	padding: 15px 25px;
	@media(max-width: $screen-sm) {
		font-size: 13px;
	}
}

.standard-button-link {
	@extend .standard-button;
	text-align: center;
	display: block;
	padding: 17px 0 0 0;
	font-weight: map-get($weight, medium);
	letter-spacing: 1px !important;
	line-height: 1;
	@media(max-width: $screen-sm) {
		padding-top: 18px;
	}
}


input.input-name {
	@include icon-name;
}

input.input-email {
	@include icon-email;
}

input.input-phone {
	@include icon-phone;
}

input.input-order {
	@include icon-return;
}

// textarea.input-message {
// 	@include icon-message;
// }

input.input-sku {
	@include icon-barcode;
}

h1 {
	font-size: 18px;
	text-transform: uppercase;
}

#maincontent {
	max-width: 100%;
	padding: 0 15px 0 15px;
}

.standard-heading {
	font-size: 18px;
    text-transform: uppercase;
    margin: 5px 0 40px 0;
    color: #5d5d5d;
}

body {
	font-size: 16px;
	font-family: $font-family-serif;
	color: $brand-primary;
}



