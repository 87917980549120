body {
	#search-section {
	    margin-bottom: 0px;
	    border: none;
	    height: 0px;
	    width: 100%;
		max-width: 100%;
		background-color: #fff;
	    text-align: center;
	    overflow: hidden;
	    transition: 250ms all;
	    position: relative;
	    z-index: 101;
	    border-bottom: 1px solid #7f7f7f;
	    pointer-events: none;

	    &.search-section--is-sticky {
		    position: fixed;
		    top: 125px;
		    @media(max-width: 1200px) {
		    	top: 113px;
		    }
		    @media(max-width: $screen-md) {
		    	top: 50px;
		    }
		    @media(max-width: $screen-sm) {
		    	top: 50px;
		    }

    	 }

	    .block-search {
		    margin-top: 15px;
		    margin-bottom: 0px;
		    display: inline-block !important;
		    width: 100% !important;
		    padding-left: 0px !important;
		    float: none !important;
		    max-width: 450px;

		    @media(max-width: $screen-md) {
		    	.control {
		    		padding: 0;
		    		margin: 0;
		    		border-top: none;
		    	}
		    }

		    	@media(max-width: 520px) {
		    		.control {
					    margin: 0px;
				  	}
			    }

			    input {
			    	color: #5d5d5d;
				    border: 1px solid #5d5d5d;
				    font-size: 16px;
				    font-weight: 300;
				    height: 50px;
				    max-width: 100%;
				    width: 100%;
				    display: block;
				    padding: 0 20px 0 20px;
				    background-position: center left 20px;
				    font-size: 16px;
				    background-repeat: no-repeat;
				    font-family: "Lato", sans-serif;
				    border-radius: 0px;
				    @media(max-width: $screen-md) {
				    	left: 0;
				    	margin: 0 auto;
				    	position: relative;
				    }
				    &:focus {
				    	border-width: 2px;
				    	outline: none;
				    	box-shadow: none;

				    } 
			    }

			    .nested {
			    	display: none;
			    }
		}


		.field.search {
			label {
				@media(max-width: $screen-md) {
					display: none;
				}
			}
		}

		.action.search {
		    background-repeat: no-repeat;
		    background-image: url(../images/icon-set/DESKTOP-SMALL-SEARCH.svg);
		    width: 30px;
		    height: 26px;
		    overflow: hidden;
		    text-indent: -9999px;
		    top: 33%;
		    -webkit-transform: translateY(-50%);
		    transform: translateY(-50%);
		    @media(max-width: $screen-md) {
		    	display: block;
		    	border: none;
		    	background-color: transparent;
		    	position: absolute;
		    	box-shadow: none;
		    	background-size: 19px;
		    	right: 30px;
		    	height: 20px;
		    	top: 50%;
		    	width: 20px;
		    	background-image: url('/media/search.png');
		    	background-repeat: no-repeat;
		    	background-position: center center;
		    	opacity: 1;
		    }
		}
		&.open {
		    height: 80px !important;
		    z-index: 100 !important;
		    pointer-events: all;
		}
		
	}

}
