.standard-bordered-button {
	border: 1px solid $text-primary;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 20px;
    align-content: center;
    display: inline-block;
    font-weight: 400;
    position: relative;
    @media(max-width: 767px) {
		font-size: 16px;
	}
	span {
		z-index: 2;
		position: relative;
	}
	&:after {
	    width: 0%;
	    height: 100%;
	    top: 0;
	    z-index: 1;
	    left: 0;
	    background: $text-primary;
	    content: '';
	    position: absolute;
	    transition: all 0.3s;
    }
	&:hover {
		color: #fff;
		&:after {
			width: 100%;
		}
	}
}
