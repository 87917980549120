.product-options-wrapper {
	.field {
		label.label {
			padding-left: 0;
		}
	}

	.control {
		width: 100% !important;
	}

	 input[type='text'] {
		height:  49px;
		letter-spacing: 2px;
		width: 100%;
		max-width: 100%;
		padding: 0 40px;
		border: 1px solid $text-primary;
		text-transform: uppercase;
		margin-bottom: 15px;
		&:placeholder {
			color: #e1e2e6;
	  }
	}
}
