.wholesale-application {
	max-width: 580px;
	margin: 0 auto;
	border: 1px solid $text-primary;
	color: $text-primary;
	padding: 35px;
	@media(max-width: $screen-sm) {
		border: none;
		padding: 35px 10px 0 10px;
		border-top: 1px solid black;
	}

	h2 {
		@extend .standard-heading;
		text-align: center;
		margin: 0;
		padding: 0 0 60px 0;
		@media(max-width: $screen-sm) {
			padding: 0 0 40px 0;
		}
	}
	input[type='text'], input[type='email'], input[type='tel'] {
		@extend .standard-input-with-icon;
		@media(max-width: $screen-sm) {
			margin: 0 0 20px 0;
		}
	}
	
	.wholesale-company {
		@include icon-state;
	}

	.wholesale-name {
		@include icon-name;
	}

	.wholsale-postcode {
		@include icon-postcode;
	}

	.wholesale-bulding-name {
		@include icon-building-number;
	}

	.wholesale-street {
		@include icon-street;
	}

	.whoelsale-city {
		@include icon-city;
	}

	.wholesale-county {
		@include icon-country;
	}

	.wholesale-telephone {
		@include icon-phone;
	}

	.wholesale-email {
		@include icon-email;
	}

	// .wholesale-comments {
	// 	@include icon-message;
	// }
	.wholesale-website {
		@include icon-cursor;
	}

	textarea {
		@extend .standard-textarea-with-icon;
		@media(max-width: $screen-sm) {
			margin: 0 0 20px 0;
		}
	}

	button {
		@extend .standard-button;
	}
	
	.wholesale-agreement {
		padding: 0 0 35px 0;
		&__inner {
			input[type='checkbox'], label {
				display: inline-block;
				vertical-align: middle;
			}

			label {
				margin: 0;
				font-weight: 300;
				color: $text-primary;
				@media(max-width: 350px) {
					font-size: 14px;
				}
				strong a {
					font-weight: 600;
					color: black;
				}
			}

			input[type='checkbox'] {
				appearance: none;
				webkit-appearance: none;
    			height: 18px;
    			width: 18px;
    			border: 1px solid #000;
    			margin: 0 15px 0 0;
    			outline: none;
    			&:checked {
    				background-color: #000;
    			}
			}
		}
	}
		.wholesale-radio {
			padding: 0 0 35px 0;
			label {
				color: #5d5d5d;
				text-transform: uppercase;
				font-weight: normal;
				font-size: 16px;
				text-transform: uppercase;
				display: inline-block;
				margin: 0 25px 0 0;
				float: left;
				padding: 15px 0 15px 0;
				@media(max-width: 510px) {
					margin: 0;
				}
				@media(max-width: 450px) {
					float: none;
					width: 100%;
					text-align: center;
				}
			}
			
			input[type=radio] {
				opacity: 0;
				position: absolute;
			}

			input {
				display: inline-block;
			}
			.wholesale-radio-options {
				display: inline-block;
				text-align: right;
				float: right;
				input[type=radio] + label {
					width: 50px;
					height: 50px;
					border: 1px solid $text-primary;
					border-radius: 2px;
					backgroud-color: #fff;
					font-size: 16px;
					font-weight: 300;
					padding: 12px 10px 10px 10px;
					margin: 0 0 0 35px;
					@media(max-width: 510px) {
						margin: 0 0 0 25px;
					}
					@media(max-width: 465px) {
						margin: 0 0 0 5px;
					}

				}
				@media(max-width: 450px) {
					width: 100%;
					float: none;
					text-align: center;
				}

				input[type=radio]:checked + label{
					background-color: #eae4e1;
					border-color: transparent;
				}
			}
			.clear {
				clear: both;
			}
		}
}
.wholesale-application__submit {
	@media(max-width: $screen-sm) {
		padding-bottom: 20px;
	}
	
}