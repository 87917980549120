.my-account-heading-container { 
}

.account.page-layout-2columns-left .sidebar-main, .account.page-layout-2columns-left .sidebar-additional{
	@media(max-width: $screen-sm) {
		width: 100%;
		-webkit-flex-grow: 1;
		flex-grow: 1;
		-webkit-flex-basis: 100%;
		flex-basis: 100%;
		-ms-flex-order: 1;
		-webkit-order: 1;
		order: 1;
		height: 0;
	}
}

.account.page-layout-2columns-left .column.main {
	@media(max-width: $screen-sm) {
		float: none;
		-webkit-flex-basis: 100%;
		flex-basis: 100%;
		-webkit-flex-grow: 1;
		flex-grow: 1;
		-ms-flex-order: 1;
		-webkit-order: 2;
		order: 2;
		width: 100%;
	}
}