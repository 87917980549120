body {
	&.cms-new-in {
		#content-container-full-width {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		.breadcrumbs {
			display: none;
		}

		@media(max-width: 767px) {
			#maincontent{
				padding: 40px 0;

					.column {
						&.main {
							padding-bottom: 0;
						}
					}
			}
		}

		.page-title-wrapper {
			text-align: center;
			// padding-bottom: 35px;
			// padding-top: 25px;
			padding-bottom: 30px;
			padding-top: 20px;
			@media(max-width: 991px) {
				padding-bottom: 5px;
			}
				h1{
					@include standard-font-heading-light;
					text-transform: uppercase;
					@media(max-width: 767px) {
						// padding-top: 40px;
						// padding-bottom: 40px;
						margin: 0;
						font-size: 20px;
					}
				}
		}
    }
}