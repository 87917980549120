.products-grid {
	&.wishlist {

		.stock.unavailable {
			@extend .standard-button-link;
      		opacity: 0.6;
		}

		.wishlist-product-item {
		    position: relative;
		    z-index: 2;
		    font-weight: 300;
		    font-size: 25px;
		    margin-bottom: 30px;

		    	a {
		    		color: #232323;
    				text-decoration: none;
		    	}
		}
		.product-item-photo {
			margin: 0;
			display: block;
			img {
				&.product-image-photo {
					border: none;
					margin-bottom: 0;
				}
			}
		}

		&.photo {
			&.image {
				margin: 0 0 30px 0;
			}
		}

		.product-item-name {
			font-size: 16px;
		    color: #5d5d5d;
		    text-align: center;
		    margin-bottom: 5px;
		    	@media(max-width: $screen-sm) {
					clear: both;
					margin-bottom: 15px;
					display: block;
					margin: 0;
					float: none;
				}
		}
		// Product SKU 
				.SKU {
					@include small-standard-font-heading;
					text-align: center;
					font-weight: 100;
					padding: 0 0 28px 0;
					min-height: 45px;
				}
		.price-box {
			color: #5d5d5d;
		    font-weight: 600;
		    font-size: 16px;
		    text-align: center;
		    padding: 0;
		    padding-bottom: 17px;

		    	.price-wrapper {
		    		@include standard-font-regular;
		    	}


		}




		.remove-wishlist-item {
			position: absolute;
			top: 25px;
			right: 25px;
			width: 25px;
			height: 25px;
			overflow: hidden;
			text-indent: -1000px;
			a {
				height: 100%;
				width: 100%;
				display: block;
			}
			@include icon-close;
			@media(max-width: $screen-sm) {
				top: 20px;
				right: 20px;
			}
			@media(max-width: $screen-xs) {
				top: 15px;
				height: 15px;
				width: 15px;
				right: 15px;
			}
		}

		.box-tocart {
			position: relative;
			.actions-primary {
				width: 100%;
				display: block;
				max-width: 100%;
			}
			.action {
				&.tocart {
					width: 100%;
				    max-width: 100%;
				    border: none;
				    height: 50px;
				    @include standard-font-regular;
				    background-color: $brand-primary;
				    text-transform: uppercase;
				    text-decoration: none;
				    box-shadow: none;
				    letter-spacing: 1px;
				    border-radius: 2px;
				    padding: 0 0 0 45px;
				    @media(max-width: $screen-md){
				    	height: 45px;
						font-size: 11px;
				    }
				    @media(max-width: $screen-md) {
				    	padding-left: 30px;
				    }
				}
			}
			.field {
				&.qty {
					position: absolute;
					width: 15%;
					height: 100%;
						label {
							padding: 10px 15px 0 0;
							display: none !important;
						}
							span {
								display: none;
							}
						
						.control {
							input {
								border: 1px solid #5d5d5d;
								border-right: none;
							    width: 50px;
							    font-size: 16px;
							    padding: 0;
							    display: inline-block;
							    height: 50px;
							    @media(max-width: $screen-md) {
							    	height: 45px;
							    }
							    width: 56px;
							    @media(max-width: $screen-sm) {
							    	width: 30px;
							    }
							    outline: none;
							    margin: 0;
							    border-radius: 2px;
							    box-shadow: none;
							    left: 0;
							    top: 0;
							    background-color: #fff;
							    z-index: 10;
							    text-align: center;
							    color: $text-primary;
							}
						}
				}
			}
		}
	} 
}