body {
	&.cms-our-story, &.category-our-story {
		#content-container-full-width {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		.breadcrumbs {
			display: none;
		}

		.page-title-wrapper {
			text-align: center;
			padding-bottom: 30px;
			padding-top: 20px;
			@media(max-width: 991px) {
				padding: 0 0 30px;
			}
				h1{
					@include standard-font-heading-light;
					text-transform: uppercase;
					@media(max-width: 767px) {
						padding-top: 30px;
						margin: 0;
					}
				}
		}
		@media(max-width: 767px) {
			#maincontent{
				padding: 0;

					.column {
						&.main {
							padding-bottom: 40px;
						}
					}
			}
		}

		.text {
			padding-right: 9%;
			padding-top: 124px;
			@media(max-width: 1500px) {
				padding-top: 100px;
			}
			@media(max-width: 1270px) {
				padding-top: 65px;
			}
			@media(max-width: 1100px) {
				padding-top: 45px;
			}
			@media(max-width: 925px) {
				padding-top: 12px;
			}
			@media(max-width: 850px) {
				padding-top: 0px;
			}
			@media(max-width: 767px) {
				padding-right: 0;
				padding-top: 20px;
			}
			h2 {
				@include standard-font-heading-light;
				color: $text-color;
				@media(max-width: 767px) {
					font-size: 16px;
					margin: 0;
				}
			}
			.sub-content {
				@include standard-font-light;
				padding-top: 40px;
				@media(max-width: 767px) {
					padding-top: 20px;
					padding-left: 32px;
    				padding-right: 47px;
				}
			}
			.full-description {
				@media(max-width: 767px) {
				    max-height: 0;
				    //transition: max-height 0.15s ease-out;
				    overflow: hidden;
				    color: #000000;
				    font-size: 16px;
				    font-weight: 100;
				    text-transform: none;
				    font-family: "Lato", sans-serif;
				    padding: 0px 47px 0 32px;

				    	&.active {
				    		max-height: 500px;
				    		padding-top: 0px;
						    padding-left: 32px;
						    padding-right: 47px;
						    padding-bottom: 20px;
				    	}
				}
			}
		}
		
		.container {
			&.corporate-gifting-logos {
				padding: 40px 0 40px 0;
				@media(max-width: 767px) {
					padding: 16px 0 16px 0;
				}
				.corporate-gifting-content-logos {
					.image-ourstory {
						background-size: cover;
					    background-repeat: no-repeat;
					    min-height: 627px;
					    background-position: center center;
					    position: relative;
					    margin-left: 16%;
					    @media(max-width: 767px) {
							margin: 0 29px 0 29px;
							min-height: 252px; 
						}
					}
				}

			}
		}

    }
}

/* Meet our designers block */
.container.our-brands-designers {
    text-align: center;
    padding: 38px 0;

    @media(max-width: 767px) {
		padding: 20px 30px 0;

		.no-padding {
			padding: 15px;
		}
	}


    p {
    	@include standard-font-heading-light;
    	color: $text-color;
    	padding-bottom: 40px;
    	text-transform: uppercase;
    	@media(max-width: 767px) {
				padding-bottom: 0;
				font-size: 16px;
			}
		}

	.our-brands-designers-button {
	    border: 1px solid black;
	    padding-left: 30px;
	    padding-right: 30px;
	    padding-top: 5px;
	    padding-bottom: 5px;
	    font-size: 24px;
	    align-content: center;
	    display: inline-block;
	}
	.meet-our-designers-text{
		@include standard-font-light;
		text-transform: none;
		padding-top: 40px;
		padding-bottom: 0;
    	@media(max-width: 767px) {
			padding-top: 20px;
		}

	}
}
.our-brands-designers-content {
	background-size: cover;
    background-repeat: no-repeat;
    min-height: 413px;
    top: 145px;
    background-position: center center;
    position: relative;
	a {
		color: $heading-color;
		font-weight: 700;
		text-transform: uppercase;
		}
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 21px;
			&.meet-our-designers-text{
				@include standard-font-light;
				text-transform: none;
				padding-bottom: 21px;
			}
	} 
}	

.our-brands-designers-content-middle {
	background-size: cover;
    background-repeat: no-repeat;
    min-height: 700px;
    background-position: center center;
    position: relative;
    @media(max-width: 767px) {
		min-height: 356px;
	}

	a {
		color: $heading-color;
		font-weight: 700;
		text-transform: uppercase;
		}
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 21px;
			&.meet-our-designers-text{
				@include standard-font-light;
				text-transform: none;
				padding-bottom: 21px;
			}
	} 
}	

@media(max-width: 425px) {
.container.our-brands-designers {
	.our-brands-designers-button {
	    font-size: 19px;
		}
	}
	
}
/* END Meet our designers block */

/* Our brands block */
.container.our-brands {
    text-align: center;
    background-color: $brand-primary;
    padding: 38px 0;
    margin-bottom: 60px;
		@media(max-width: 767px) {
			padding: 20px 0;
			margin-bottom: 40px;
		}
	

	.our-brands-button {
	    border: 1px solid black;
	    padding-left: 30px;
	    padding-right: 30px;
	    padding-top: 5px;
	    padding-bottom: 5px;
	    font-size: 20px;
	    align-content: center;
	    display: inline-block;
	    font-weight: 400;
	    position: relative;
	    @media(max-width: 767px) {
			font-size: 16px;
		}
		span {
			z-index: 2;
			position: relative;
		}
		&:after {
		    width: 0%;
		    height: 100%;
		    top: 0;
		    z-index: 1;
		    left: 0;
		    background: #232323;
		    content: '';
		    position: absolute;
		    transition: all 0.3s;
	    	}
		&:hover {
			color: #fff;
			&:after {
				width: 100%;
			}
		}
	}

}
.our-brands-content {
	a {
		color: $heading-color;
		font-weight: 700;
		text-transform: uppercase;
		}
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 21px;
			@media(max-width: 767px) {
				font-size: 16px;
				padding-bottom: 10px;
			}
		
		strong {
			font-weight: 400;
		}
	}
}	

@media(max-width: 425px) {
.container.our-brands {
	.our-brands-button {
	    font-size: 19px;
		}
	}
	
}
/* END Our brands block */