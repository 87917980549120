address {
    line-height: 2;
    padding-left: 15px;
    padding-right: 15px;
    margin-bottom: 20px;
    font-style: normal;
    @media(max-width: $screen-md) {
    	padding-left: 0;
    	padding-right: 0;
    }
}

.address-template-wrapper {
	 .name {
	    background-repeat: no-repeat;
	    background-image: url(../images/icon-set/name-icon.png);
	    // background-size: 27px 25px;
	}
	.address-field {
		padding: 0 0 0 50px;
	    background-repeat: no-repeat;
	    background-position: center left;
	    margin: 0 0 35px 0;
	    text-transform: uppercase;
	    color: $grey-font;
	    @media(max-width: $screen-sm) {
	    	padding: 7px 0 0 50px;
	    }
	}
	
	.vat_id {
		@include icon-company;
	}

	.street {
		@include icon-street;			
	    // background-size: 28px 28px;
	}
	
	.firstname {
		@include icon-name;
	}

	.lastname {
		@include icon-name;
	}

	

	.city {
		background-repeat: no-repeat;
	    background-image: url(../images/icon-set/city-icon.png);
	    // background-size: 28px 28px;
	}
	.region {
		background-repeat: no-repeat;
		@include icon-state;
		background-size: 21px auto;
	}
	.postcode {
		background-repeat: no-repeat;
	    background-image: url(../images/icon-set/post-code.png);
	    //background-size: 28px 28px;
	}
	.country {
		background-repeat: no-repeat;
	    background-image: url(../images/icon-set/city-icon.png);
	    //background-size: 28px 28px;
	}
	.telephone {
		background-repeat: no-repeat;
	    background-image: url(../images/icon-set/phone-icon.png);
	    //background-size: 28px 28px;
	}
}
