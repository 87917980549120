
	.category-container.container {		
		@media(max-width: $screen-lg) {
			padding: 0 15px 0 15px;
		}
	}

	#category-list {
		.breadcrumbs {
		    padding: 0;
		}
		.sub-cat-col {
			padding-bottom: 38px;
		}
		
		.cat-col-inner {
			position: relative;
			display: block;
			img {
				width: 100%;
				max-width: 100%;
			}
		}

		.sub-category-name {
			@include font-weight(light);
			background: #fff;
			position: absolute;
		    top: 0;
		    left: 0;
		    margin: 0;
		    width: 300px;
		    max-width: 100%;
			padding: 20px 50px 20px 20px;
		    color: #232323;
		    font-size: 16px;
		    text-transform: uppercase;
		    line-height: 1;
		    &:after {
			    position: absolute;
			    content: '';
			    height: 1px;
			    width: 0px;
			    bottom: 12px;
			    transition: width .25s ease-in;
			    left: 20px;
			    background-color: #232323;		    
			 }
		}
	}
	.sub-cat-col:hover {
		.sub-category-name:after {
			width: calc(100% - 50px) !important;
		}
	}
