div#home-page-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
}
	
.homepage-banner-wrapper {
	max-width: 1900px;
	margin: 0 auto;
}

.container-fluid.homepage-banner {
    width: 100%;
    // padding-bottom: 19px;

    .homepage-banner-height{
    	height: 800px;
    	background-repeat: no-repeat;
	    background-size: cover;
	    background-position: center center;
    	@media(max-width: 768px) {
    		height: 276px;
	    }

	    .homepage-text-box{
	    	position: absolute;
		    left: 0;
		    top: 78%;
		    background: #fff;
		    padding: 27px 60px 27px 290px;
		    text-transform: uppercase;
		    	@media(max-width: 768px) {
		    		top: 5%;
		    		padding: 12px 44px 12px 90px; 
			    }
			    @media(max-width: 640px) {
		    		padding: 12px 44px 12px 80px; 
			    }
			    @media(max-width: 530px) {
		    		padding: 12px 44px 12px 72px; 
			    }
			    @media(max-width: 425px) {
		    		padding: 12px 44px 12px 60px; 
			    }

		    p {
		    	@include standard-font-heading-large;
		    	font-size: 30px;
		    	font-weight: 300;
		    	line-height: 1.2;
		    	@media(max-width: 768px) {
		    		font-size: 20px;
		    		font-weight: 300;
			    }
		    }
	    }
    }
}

.mobile-banner {

	@media(max-width: $screen-md) {
		padding: 0;
	}

    .homepage-text-box{
    	position: absolute;
	    left: 0;
	    top: 78%;
	    background: #fff;
	    padding: 27px 60px 27px 290px;
	    text-transform: uppercase;
	    	@media(max-width: 768px) {
	    		top: 5%;
	    		padding: 12px 44px 12px 90px; 
		    }
		    @media(max-width: 640px) {
	    		padding: 12px 44px 12px 80px; 
		    }
		    @media(max-width: 530px) {
	    		padding: 12px 44px 12px 72px; 
		    }
		    @media(max-width: 425px) {
	    		padding: 12px 44px 12px 60px; 
		    }

	    p {
	    	@include standard-font-heading-large;
	    	font-size: 30px;
	    	font-weight: 300;
	    	line-height: 1.2;
	    	@media(max-width: 768px) {
	    		font-size: 20px;
	    		font-weight: 300;
		    }
	    }
    }
   }


#banner {
	height: 722px;
    max-width: 2000px;
    margin: 0 auto 58px auto;
    overflow: hidden;
    opacity: 0;
    transition: 250ms all;

    &.fade-in-active {
    	opacity: 1;
    }

    @media(max-width: $screen-sm) {
		height: 450px;
		margin-bottom: 25px;
    }

    li {
        height: 722px;
        @media(max-width: $screen-sm) {
        	height: 100%;
        }
        a {
        	text-decoration: none;
        }
    }
}

#banner .textual {
	font-family: 'Lato', sans-serif;
	position: absolute;
    bottom: 18px;
    top: 50%;
    transform: translateY(-25%);
    right: 118px;
    width: auto;
}

@media(max-width: 991px) {
	 #banner .textual {
	    font-family: 'Lato', sans-serif;
	    position: absolute;
	    top: 50%;
	    transform: translateY(-50%);
	    width: auto;
	    text-align: center;
	    width: 100%;
	    text-align: center;
	    left: 0;
	}
}

 #banner .textual {
	text-align: center;
	text-transform: uppercase;
}

 #banner .textual .top {
	font-size: 40px;
	color: #232323;
	padding-bottom: 0px;
	@media(max-width: $screen-sm) {
		font-size: 30px;
	}
}

 #banner .textual .middle {
	padding: 0 0 25px 0;
	@media(max-width: $screen-sm) {
		font-size: 20px;
	}
}

 #banner .textual .middle * {
	font-style: normal;
	font-size: 28px;
	font-weight: 300;
	font-family: 'Lato', sans-serif;
	text-transform: uppercase;
	color: #232323;
	@media(max-width: $screen-sm) {
		font-size: 20px;
	}
}

 #banner .textual .bottom {
    max-width: 267px;
    display: inline-block;
    padding-top: 8px;
    padding-bottom: 8px;
    transition: all 250ms;
    width: 100%;
    display: block;
    margin: 0px auto;
    background: none;
    border: 1px solid #232323;
    color: #232323;
    font-size: 22px;
    font-weight: 600;
     -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    position: relative;
    text-transform: uppercase;
    text-align: center;
	text-decoration: none;
 }


 #banner .bottom:hover,  #homepage-static-banner a:hover {
	color: #fff;
}
 #banner .textual .bottom:after, #homepage-static-banner a:after {
	width: 0%;
	height: 100%;
	top: 0;
	left: 0;
	background: #232323;
	content: '';
	position: absolute;
	z-index: -1;
	-webkit-transition: all 0.3s;
	-moz-transition: all 0.3s;
	transition: all 0.3s;
}

 #banner .bottom:hover:after,  #homepage-static-banner a:hover:after {
	width: 100%;
}

 #banner .container {
	height: 100%;
}

 #banner .sy-box {
	height: 100%;
	width: 100%;
}

#prime-banner li {
	width: 100%;
	height: 100%;
	position: absolute;
}
#prime-banner .pane {
	position: absolute;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center center ;
}


#banner {
	.sy-controls {
		a:after {
			border-radius: 0;
			background-color: transparent;
			background-size: 25px 53px;
		    background-position: center center;
		    height: 53px;
		    width: 25px;
		}
	}

	// .sy-next a:after {
	// 	@include icon-arrow-right;
	// }
	// .sy-prev a:after {
	// 	@include icon-arrow-left;
	// }
}