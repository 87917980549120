/** ---------------------------------------------------------------------
 * = Home Items
 * The elements that make up the home page section on the website.
 ------------------------------------------------------------------------ */

@import "home/contact-section";
@import "home/place-order";
@import "home/columns";
@import "home/banner";
@import "home/navigation";
@import "home/search";
@import "home/slick-slider";

/** ---------------------------------------------------------------------
 * = Contact Items
 * The elements that make up the Contact page section on the website.
 ------------------------------------------------------------------------ */

@import "contact/contact";

/** ---------------------------------------------------------------------
 * = Catalog Items
 * The elements that make up the catalog sections on the website
 ------------------------------------------------------------------------ */

@import "catalog/product-category";
@import "catalog/grid-item-selector";
@import "catalog/product";
@import "catalog/product-breadcrumb";
@import "catalog/toolbar";
@import "catalog/product-listing-item";
@import "catalog/product-contents";
@import "catalog/product-attributes";
@import "catalog/product-add-to-cart";
@import "catalog/product-wholesale-discount";
@import "catalog/product-description";
@import "catalog/product-price";
@import "catalog/filters";
@import "catalog/product-tooltip";
@import "catalog/related-products";
@import "catalog/gallery";
@import "catalog/product-options";
@import "catalog/product-showcase";

/** ---------------------------------------------------------------------
 * = Account Items
 * The elements that make up the Account/Customer sections on the website
 ------------------------------------------------------------------------ */

@import "account/my-account";
@import "account/my-account-logged-in";
@import "account/boxes";
@import "account/menu";
@import "account/edit-address";
@import "account/address";
@import "account/orders-history";
@import "account/order-view";
@import "account/edit-account";
@import "account/logout";
@import "account/login";
@import "account/forgot-password";
@import "account/create-password";
@import "account/print-order";
@import "account/account-summary";

/** ---------------------------------------------------------------------
 * = Wishlist Items
 * The elements that make up the Wishlist section on the website
 ------------------------------------------------------------------------ */

@import "wishlist/wishlist";

/** ---------------------------------------------------------------------
 * = Checkout Items
 * The elements that make up the Checkout section on the website
 ------------------------------------------------------------------------ */

@import "checkout/order-confirmation";
@import "checkout/my-bag";
@import "checkout/express";
@import "checkout/global";
@import "checkout/discount";
@import "checkout/summary";
@import "checkout/payment";
@import "checkout/shipping";
@import "checkout/method";
@import "checkout/order-confirmation";
@import "checkout/paypal-review";


/** ---------------------------------------------------------------------
 * = Basket Items
 * The elements that make up the Basket section on the website
 ------------------------------------------------------------------------ */

@import "basket/mini";
@import "basket/item";
@import "basket/empty";
@import "basket/global";
@import "basket/impulse";

/** ---------------------------------------------------------------------
 * = Area Managers
 * The elements that make up the Area Managers section on the website
 ------------------------------------------------------------------------ */

@import "area-managers/area-managers";

/** ---------------------------------------------------------------------
 * = Catalogue
 * The elements that make up the Catalogues section on the website
 ------------------------------------------------------------------------ */

@import "catalogues/catalogues";

/** ---------------------------------------------------------------------
 * = Corporate Gifting
 * The elements that make up the Corporate Gifting section on the website
 ------------------------------------------------------------------------ */

@import "corporate-gifting/corporate-gifting";

/** ---------------------------------------------------------------------
 * = Brands Items
 * The elements that make up the Brands section on the website
 ------------------------------------------------------------------------ */

@import "category/brands";
@import "category/brands-subcategory";
@import "category/brands-sub-subcategory";

/** ---------------------------------------------------------------------
 * = Our Story Items
 * The elements that make up the Our Story section on the website
 ------------------------------------------------------------------------ */

@import "our-story/our-story";
@import "our-story/individual-designer-page";
@import "our-story/meet-the-designers";


/** ---------------------------------------------------------------------
 * = New In Items
 * The elements that make up the New In section on the website
 ------------------------------------------------------------------------ */

@import "new-in/new-in-landingpage";
@import "new-in/comin-soon-landingpage";
@import "new-in/coming-soon";
@import "new-in/new-in";
@import "new-in/featured-page";

/** ---------------------------------------------------------------------
 * = Wholesale Items
 * The elements that make up the Wholesale Appliction section on the website
 ------------------------------------------------------------------------ */

@import "wholesale-application/global";

/** ---------------------------------------------------------------------
 * = Brand Information Items
 * The elements that make up the Brand Information section on the website
 ------------------------------------------------------------------------ */

 @import "brands-information/landing";
 @import "brands-information/landing-single";

/** -------------------------------------------------------------------------
 * = Know What You Want Items
 * The elements that make up the "Know What You Want" section on the website
 --------------------------------------------------------------------------- */

@import "know/global";
@import "know/mobile-product-form";

/** ---------------------------------------------------------------------
 * = Landing Page
 * The elements that make up the Landing Page section on the website
 ------------------------------------------------------------------------ */

@import "landing-page/landing-page";

/** ---------------------------------------------------------------------
 * = 404 Template
 * The 404 page (when a page is not found / does not exist)
 ------------------------------------------------------------------------ */

@import "404/404";
