// Fonts
$font-family-lato: 'Lato', sans-serif;

// Font Sizes
$small-standard-font-size: 12px;
$standard-font-size: 16px;
$heading-font-size: 24px;
$large-heading-font-size: 40px;
$input-font-size: 16px;


