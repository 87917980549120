.customer-information.account-information {
	
	opacity: 1;

	span.customer-name.customer-discount {
		@include icon-discount;
		background-size: 20px auto;
		padding-bottom: 5px;
	}

	span.customer-name.customer-currency {
		@include icon-currency;
		background-size: 20px auto;
		padding-bottom: 5px;
	}

}