/**
 * = Impusle Products SCSS File
 * 
 * This SCSS file is for the impulse products section on LC Designs
 * This section appears typically on the cart/baskset below the cart items
 * 
================================================== */

#impulse-products {
	padding: 60px 0 50px 0;
	max-width: 1080px;
	margin: 0px auto;
	position: relative;
	opacity: 0;
	transition: 250ms all;
	&.fade-in-active {
		opacity: 1;
	}
	@media(max-width: $screen-sm)  {
		padding: 0 0 0px 0;		
	}


	.impulse-product-slider {
		position: relative;
	}

	.impulse-sku {
		font-size: 12px;
		text-align: center;
		font-weight: map-get($weight, light);
		color: #232323;
		padding: 0 0 25px 0;
		@media(max-width: $screen-md) {
			min-height: 65px;
		}
	}

	.impulse-product-item {
		padding-left: 15px;
		padding-right: 15px;
		@media(max-width: $screen-sm) {
			padding-left: 5px;
			padding-right: 5px;
		}
	}

	.slick-prev {
		display: none !important;
	}

	.slick-arrow {
	    background-color: rgba(247, 247, 249, 0.81);
		position: absolute;
		height: 100%;
		width: 50px;
		top: 0;
		background-repeat: no-repeat;
		border: none;
		color: transparent;
		max-height: 330px;
		text-indent: -99999px;
		background-size: 14px 30px;
		background-positon: center center;
		border-radius: 0;
		transform: none;
		box-shadow: none;
		@media(max-width: $screen-sm) {
			display: none !important; 
		}
	}

	.slick-next {
		right: 13px;
   		background-image: url('../images/instagram-right-arrow.png');
   		background-position: center center;
	}



		h2 {
			font-size: $standard-font-size;
			font-weight: 300;
			color: $text-primary;
			display: block;
			text-align: center;
			text-transform: uppercase;
			margin:  0 0 60px 0;
			@media(max-width: $screen-sm) {
				margin: 0 0 40px 0;
			}
		}

		.impulse-price {
			text-align: center;
			color: $text-primary;
			margin: 0 0 35px 0;
		}

		img {
			padding: 0 0 25px 0px;
		}
		h3 {
			font-weight: normal;
			font-size: 16px;
			color: $text-primary;
			margin: 0;
			padding: 0 0 10px 0px;
			display: block;
			text-align: center;
			line-height: 1.3;
		}

		button {
			@extend .standard-button;
		}
		input {
			border: 1px solid $text-primary;
		    position: absolute;
		    width: 70px;
		    height: 50px;	
		    color: $text-primary;
		    background-color: #fff;
		    border-radius: 0;
		    text-align: center;
		    border-right: none;
		    z-index: 2;
		    /* Change The Input Box On Medium Screen Size */
			@media(max-width: $screen-md) {
				width: 35px;
				height: 45px;
			}
		}

		.list-add-to-cart-button {
			background-image: none;
		    background: #eae4e1;
		    cursor: pointer;
		    border: none;
		    border-radius: 0;
		    display: inline-block;
		    @include standard-font-regular;
		    letter-spacing: 1px;
			text-transform: uppercase;
		    padding: 7px 0px 7px 70px;
		    box-sizing: border-box;
		    vertical-align: middle;
		    width: 100%;
		    height: 50px;
		    @media(max-width: $screen-md) {
		   		height: 45px;
		   		font-size: 16px;
		   		padding: 7px 0 7px 30px;
		    }
		    @media(max-width: $screen-sm) {
		    	font-size: 13px;
		    }
		    @media(max-width: $screen-xs) {
		    	font-size: 11px;
		    }
		}
	}