	#co-shipping-form {
	}

	#opc-shipping_method {
	.checkout-shipping-method {
		.delivery-note {
			font-size: 12px;
			font-weight: 600;
			text-align: center;
			color: $text-primary;
			a {
				color: $text-primary;
				&:visited {
					color: $text-primary;
				}
			}
			}
		}
		.col.col-method {
			width: 100%;
			padding: 0;
			border: none;
			input {
				display: none;
			}
			label {
				position: relative;
				background-color: #f4f4f4;
				color: #000000;
				cursor: pointer;
				font-weight: 300;
				padding: 20px 20px 20px 50px;
				margin: 0 0 25px 0;
				background-position: left 10px center;
				@include icon-delivery;
				background-size: 30px;
				width: 100%;
			}

			label[data-tooltip='dpd. saturday delivery'] {
				padding: 20px 50px 20px 50px;
			}

			input:checked + label {
				background-color: #232323;
				color: #fff;
				cursor: default;
				@include icon-delivery-white;
				background-size: 30px;
				.field-tooltip {
					.field-tooltip-action:before {
						color: $brand-secondary;
					}
				}
			}
		}
	}
