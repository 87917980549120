.cms-content {
	max-width: 1250px;
	margin: 0 auto;
	color: #232323;
	a {
		color: #59939f;
	}
}

body {
		#content-container-full-width {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		.breadcrumbs {
			display: none;
		}

		@media(max-width: 767px) {
			#maincontent{
				// padding: 0;

					.column {
						&.main {
							padding-bottom: 0;
						}
					}
			}
		}
		
    
}


// Designers Feature text
.container.featured-designer-text {
    text-align: center;
    padding: 65px 0 65px 0;
    background-color: $brand-primary;
    max-width: 100%;
    @media(max-width: 800px) {
		padding: 48px 26px 0;
	}
	@media(max-width: 500px) {
		padding: 20px 26px 0;
	}
}
.featured-designer-content {
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 54px;
		margin: 0;


		&.sub-content {
			@include standard-font-light;
			text-transform: none;
			padding-bottom: 40px;
			padding-top: 40px;
			margin: 0;
			@media(max-width: 767px) {
				padding-bottom: 20px;
				padding-top: 20px;
			}
		}
	}
	h2 {
		@include standard-font-heading-light;
		color: $text-color;
		margin: 0;
		text-transform: uppercase;
		@media(max-width: 767px) {
			font-size: 20px;
		}
	}

}	
// END Designers Feature text





/* Featured page bottom block */
.container.new-in-featured-middle {
    text-align: center;
    padding: 0 0 0 21px;
    max-width: 100%;
    margin-top: -20px;

    @media(max-width: 767px) {
		padding: 0 30px 20px;

		.no-padding {
			padding: 15px;
		}
	}

	.new-in-featured-bottom-middle {
	    border: 1px solid #232323;
	    padding-left: 30px;
	    padding-right: 30px;
	    padding-top: 5px;
	    padding-bottom: 5px;
	    font-size: 24px;
	    align-content: center;
	    display: inline-block;
	}
}
.new-in-featured-middle-content {
	padding-right: 0;
	margin-left: 84px;
	width: 45%;
	@media(max-width: 1670px) {
		margin-left: 88px;
		width: 44%;
	}
	@media(max-width: 1457px) {
		margin-left: 73px;
	}
	@media(max-width: 1207px) {
		margin-left: 57px;
	}
	@media(max-width: 940px) {
		margin-left: 42px;
	}
	@media(max-width: 767px) {
		margin-left: 0;
		width: 100%;
	}
}	

.new-in-featured-photo-middle {
	background-size: cover;
    background-repeat: no-repeat;
    min-height: 1413px;
    background-position: center center;
    position: relative;
    @media(max-width: 767px) {
		min-height: 562px;
	}
}	

.new-in-featured-photo-left-middle {
	background-size: cover;
    background-repeat: no-repeat;
    min-height: 860px;
    background-position: center center;
    position: relative;
    top: 230px;
    @media(max-width: 767px) {
		top: 230px;
	}
}	
/* END Featured page bottom block */





/* Featured page bottom block */
.container.new-in-featured-bottom {
    text-align: center;
    padding: 0 0 0 0;
    margin-top: -210px;

    @media(max-width: 767px) {
		padding: 40px 30px 0;

		.no-padding {
			padding: 15px;
		}
		.no-left-padding {
			padding-right: 0;
			float: right;
			bottom: 850px;
		}
	}

	.new-in-featured-bottom-button {
	    border: 1px solid #232323;
	    z-index: 10;
	    position: relative;
	    padding-left: 30px;
	    padding-right: 30px;
	    padding-top: 5px;
	    padding-bottom: 5px;
	    font-size: 24px;
	    align-content: center;
	    display: inline-block;
	    	&:after {
	    		width: 0%;
			    height: 100%;
			    top: 0;
			    left: 0;
			    background: #232323;
			    content: '';
			    position: absolute;
			    z-index: -1;
			    transition: all 0.3s;
	    	}
	    	&:hover {
	    		color: #fff;
	    		text-decoration: none;
	    			&:after {
	    				width: 100%;
	    			}
	    	}
	}
	.new-in-featured-bottom-text{
		@include standard-font-light;
		text-transform: none;
		padding-top: 40px;
		padding-bottom: 40px;
		@media(max-width: 767px) {
			padding-top: 20px;
			padding-bottom: 20px;
		}
	}
}
.new-in-featured-bottom-content {
	background-size: cover;
    background-repeat: no-repeat;
    min-height: 413px;
    top: 230px;
    background-position: center center;
    position: relative;
    @media(max-width: 767px) {
		top: 90px;
		min-height: 352px;
	}
	@media(max-width: 385px) {
		top: 70px;
	}
	a {
		color: $heading-color;
		font-weight: 700;
		text-transform: uppercase;
		}
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 21px;
		@media(max-width: 767px) {
			font-size: 20px;
			padding-bottom: 0;
		}

	} 
}	

.new-in-featured-photo-bottom {
	background-size: cover;
    background-repeat: no-repeat;
    min-height: 770px;
    background-position: center center;
    position: relative;
    @media(max-width: 767px) {
		min-height: 325px;
	}
}	

@media(max-width: 767px) {
.container.new-in-featured-bottom {
	.new-in-featured-bottom-button {
	    font-size: 19px;
		}
	}
	
}
/* END Featured page bottom block */