@media(max-width: 767px) {
	.meet-designers-wrapper {
		margin-top: 275px;
	}
}

@media(max-width: 425px) {
	.meet-designers-wrapper {
		margin-top: 385px;
	}
}

.meet-designers-wrapper-top {
	padding-bottom: 16px;

}

// Top Section - Meet the designers text
.container.meet-designers-top {
    text-align: center;
    padding: 855px 0 0 0;
    background-color: $brand-primary;
    max-width: 110px;
    margin-left: 5.5%;
    margin-top: -870px;
    @media(max-width: 800px) {
		padding: 40px 26px 48px;
	}
	@media(max-width: 767px) {
		margin-left: 0;
		margin-top: -915px;
		padding: 40px 26px 100px;
	}
	@media(max-width: 425px) {
		margin-top: -450px;
	}
	// @media(max-width: 500px) {
	// 	padding: 40px 26px 48px;
	// }

}

// END Top Section - Meet the designers text


// Top Section - Meet the designers image left
.container.meet-designers-image-top-left {
    text-align: center;
    padding: 0 0 55px 0;
    max-width: 100%;

    @media(max-width: 800px) {
		padding: 48px 26px 0;
	}
	@media(max-width: 767px) {
		padding: 0 26px 0;
	}
	@media(max-width: 500px) {
		padding: 0px 26px 0;
	}
	@media(max-width: 767px) {
		.no-right-padding {
			padding-right: 45px;
			padding-left: 40px;
		}
	}

    	.text {
    		float: right;
    	}
    	
    		.inner {
    			background-size: cover;
				background-repeat: no-repeat;
				min-height: 916px;
				background-position: center center;
				position: relative;
				@media(max-width: 767px) {
					min-height: 395px;
				}
				@media(max-width: 425px) {
					min-height: 450px;
				}
    		}
    		.inner-smaller {
    			background-size: cover;
				background-repeat: no-repeat;
				min-height: 742px;
				background-position: center center;
				position: relative;
				@media(max-width: 767px) {
					min-height: 282px;
				}
				@media(max-width: 425px) {
					min-height: 400px;
				}
    		}
    	
    	a {
    		position: absolute;
		    left: 0;
		    top: 0;
		    display: block;
		    width: 100%;
		    height: 100%;
		    transition: all 0.3s;
    	}
    	h2 {
    		position: absolute;
		    display: block;
		    top: 0;
		    margin: 0;
		    width: 275px;
    		max-width: 275px;
		    text-align: left;
		    padding-top: 20px;
		    padding-left: 20px;
		    padding-bottom: 20px;
		    @include standard-font-grey;
    		background-color: $brand-secondary;
		    text-transform: uppercase;
		    @media(max-width: $screen-md) {
		    	top: -1px;
		    	left: -1px;
		    }
		     span {
		     	@include standard-font-heading;
    			font-size: 16px;
		     }
    	}
}
.meet-designers-image-top-content {
	position: absolute;
    right: 0;
    top: 85px;
    margin-top: 54px;
    width: calc(50% - 160px);
    height: 1000px;
    @media(max-width: 767px) {
		margin-top: 450px;
    	width: calc(100% - 75px);
	}
	@media(max-width: 425px) {
		margin-top: 525px;
	}
	.image-meet-designers {
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 920px;
		background-position: center center;
		margin-top: -205px;
		z-index: 1;
		position: relative;
		@media(max-width: 767px) {
			margin-top: 0;
		}
		@media(max-width: 425px) {
			min-height: 400px;
		}
	}	

}	
// END Top Section - Meet the designers image left







// Middle Section - Meet the designers text
.container.meet-designers {
    text-align: center;
    padding: 51px 0 55px 0;
    background-color: $brand-primary;
    //max-width: 100%;
    max-width: 1740px;
    margin-left: 0;
    @media(max-width: 800px) {
		padding: 40px 26px 48px;
	}
	@media(max-width: 767px) {
		padding: 20px 26px 20px;
	}
	// @media(max-width: 500px) {
	// 	padding: 40px 26px 48px;
	// }
}
.meet-designers-content {
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 54px;
		margin: 0;

		&.sub-content {
			@include standard-font-light;
			text-transform: none;
			padding-top: 23px;
			padding-right: 29px;
    		padding-left: 28px;
			margin: 0;
			@media(max-width: 767px) {
				padding-bottom: 0;
				padding-top: 0;
			}
			
		}
	}
}	
// END Middle Section - Meet the designers text


// Middle Section - Meet the designers image left
.container.meet-designers-image-middle-left {
    text-align: center;
    padding: 40px 0 55px 0;
    max-width: 100%;

    @media(max-width: 800px) {
		padding: 48px 26px 0;
	}
	@media(max-width: 767px) {
		padding: 0;
		z-index: 2;
	}

    	.text {
    		float: right;
    	}
    	
    		.inner {
    			background-size: cover;
				background-repeat: no-repeat;
				min-height: 838px;
				background-position: center center;
				position: relative;
				margin-top: -426px;
				@media(max-width: 767px) {
					margin-top: 0;
					min-height: 391px;
					z-index: 2;
				}
				@media(max-width: 425px) {
					min-height: 490px;
				}
    		}
    		@media(max-width: 767px) {
				.no-left-padding {
					padding-top: 20px;
					padding-right: 80px;
					padding-left: 80px;
				}
			}
			@media(max-width: 425px) {
				.no-left-padding {
					padding-right: 50px;
					padding-left: 50px;
				}
			}
    	
    	a {
    		position: absolute;
		    left: 0;
		    top: 0;
		    display: block;
		    width: 100%;
		    height: 100%;
		    transition: all 0.3s;
    	}
    	h2 {
    		position: absolute;
		    display: block;
		    top: 0;
		    margin: 0;
		    width: 275px;
    		max-width: 275px;
		    text-align: left;
		    padding-top: 20px;
		    padding-left: 20px;
		    padding-bottom: 20px;
		    @include standard-font-grey;
    		background-color: $brand-secondary;
		    text-transform: uppercase;
		    @media(max-width: $screen-md) {
		    	top: -1px;
		    	left: -1px;
		    }

		     span {
		     	@include standard-font-heading;
    			font-size: 16px;
		     }
    	}
}
.meet-designers-image-middle-left-content {
	.image-meet-designers {
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 920px;
		background-position: center center;
		margin-top: -105px;
		z-index: 1;
		position: relative;
		@media(max-width: 767px) {
			margin-top: 0;
			min-height: 343px;
		}
		@media(max-width: 425px) {
			min-height: 400px;
		}
	}	

}	
// END Middle Section - Meet the designers image left



// Bottom Section - Meet the designers text
.container.meet-designers-bottom {
    text-align: center;
    padding: 209px 0 0 0;
    background-color: $brand-primary;
    //max-width: 100%;
    max-width: 1009px;
    margin-right: 8.5%;
    margin-top: -220px;
    @media(max-width: 800px) {
		padding: 40px 26px 48px;
		background-color: #fff;
	}
	// @media(max-width: 500px) {
	// 	padding: 40px 26px 48px;
	// }

}

// END Bottom Section - Meet the designers text

// Hover effect on profiles
.container.meet-designers-image-middle-left, .container.meet-designers-image-bottom-left, .container.meet-designers-image-top-left, .meet-designers-image-top-content {
	.inner .sub-category-name, .inner-smaller .sub-category-name {
    		position: absolute;
		    display: block;
		    top: 0;
		    margin: 0;
		    width: 325px;
		    max-width: 325px;
		    padding-left: 20px;
		    padding-right: 50px;
		    padding-top: 30px;
		    color: #232323;
		    font-size: 16px;
		    font-weight: 100;
		    font-family: "Lato", sans-serif;
		    background-color: #ffffff;
		    padding-bottom: 20px;
		    text-transform: uppercase;
		    text-align: left;
		    	span span {
		    		font-weight: 500px
		    	}
		    	&:after {
					position: absolute;
					content: '';
					height: 1px;
			    	width: 0px;
			    	bottom: 12px;
			    	transition: width .25s ease-in;
			    	left: 20px;
			    	background-color: #232323;		    
					}

					&:hover {
						&:after {
							width: calc(100% - 40px);
						}
					}

					span {
						font-weight: map-get($weight, medium);
					}
    	}
    	.inner:hover {
			.sub-category-name:after {
				width: calc(100% - 50px) !important;
			}
		}
}
// END Hover effect on profiles


// Bottom Section - Meet the designers image left
.container.meet-designers-image-bottom-left {
    text-align: center;
    padding: 0 0 55px 0;
    max-width: 100%;

    @media(max-width: 800px) {
		padding: 48px 26px 0;
	}
	@media(max-width: 767px) {
		padding: 0 26px 0;
	}
	@media(max-width: 500px) {
		padding: 20px 26px 0;
	}

	@media(max-width: 767px) {
		.no-left-padding {
			padding-right: 45px;
		}
	}

    	.text {
    		float: right;
    	}
    	
    		.inner {
    			background-size: cover;
				background-repeat: no-repeat;
				min-height: 755px;
				background-position: center center;
				position: relative;
				@media(max-width: 767px) {
					margin-top: 173px;
					z-index: 2;
					min-height: 341px;
				}
				@media(max-width: 425px) {
					min-height: 400px;
					margin-top: 150px;
				}
    		}
    	
    	a {
    		position: absolute;
		    left: 0;
		    top: 0;
		    display: block;
		    width: 100%;
		    height: 100%;
		    transition: all 0.3s;
    	}
    	h2 {
    		position: absolute;
		    display: block;
		    top: 0;
		    margin: 0;
		    width: 275px;
    		max-width: 275px;
		    text-align: left;
		    padding-top: 20px;
		    padding-left: 20px;
		    padding-bottom: 20px;
		    @include standard-font-grey;
    		background-color: $brand-secondary;
		    text-transform: uppercase;
		    @media(max-width: $screen-md) {
		    	top: -1px;
		    	left: -1px;
		    }
		     span {
		     	@include standard-font-heading;
    			font-size: 16px;
		     }
    	}
}
.meet-designers-image-bottom-left-content {
	position: absolute;
    right: 0;
    width: calc(50% - 50px);
    height: 1000px;
	    @media(max-width: 767px) {
			width: calc(100% - 150px);
		}
		.image-meet-designers {
			background-size: cover;
			background-repeat: no-repeat;
			min-height: 920px;
			background-position: center center;
			margin-top: -205px;
			z-index: 1;
			position: relative;
			@media(max-width: 767px) {
				margin-top: -79px;
				min-height: 390px;
			}
			@media(max-width: 425px) {
				min-height: 490px;
			}
		}	

}	
// END Bottom Section - Meet the designers image left