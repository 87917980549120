.paypal-express-review {
  h1 {
    text-align: center;
    @extend .standard-title;
    display: block !important;
    padding: 0px 0 0px 0;
  }
}


.paypal-review {
  #order-review-form button {
    @extend .standard-button;
  }

#shipping-method-form .actions-toolbar {
  display: none !important;
}

  .paypal-review-action {
    text-transform: uppercase;
    font-size: 16px;
    font-family: 'Lato';
    text-transform: uppercase;
    color: #758fb6;
    width: 100%;
    font-weight: 300;
    text-align: right;
    padding-bottom: 25px;
    @media(max-width: $screen-sm) {
      text-align: center;
      font-size: 14px;
    }
    a {
        font-weight: 300;
        color: #758fb6;
  }
  }

      .paypal-review-title {
          display: none;
      }
       #details-table  {
        .paypal-review-title {
            display: none;
        }
        thead {
          tr {
            th:first-child {
              padding-left: 0;
            }
          }
        }

        tbody {
          tr {
            td:first-child {
              padding-left: 0;
              text-align: left;
            }
          }
        }


        tfoot {
          border: none;
          background: #fff !important;;

          tr {
            border: none !important;
            th {
              padding-top: 30px;
                background-color: #fff;
                vertical-align: middle;
                @media(max-width: 768px) {
                  padding-top: 15px !important;
                  display: block !important;
                }
            }
            td {
              padding-top: 30px;
              border: none;
              background-color: #fff;
              @media(max-width: 768px) {
                padding-top: 15px !important;
              }
            }

          }
        }
        
        @media(max-width: 768px) {
            .col.item {
              width: 50% !important;
              float: none !important;
              padding: 0 !important;
              text-align: left !important;
              margin: 0 auto;

              .product-item-photo {
                float: none !important;
                width: 100% !important;
                max-width: 100% !important;
              }

              img {
                width: 100% !important;
                max-width: 100% !important;
                height: auto !important;
              }


            .product-item-details {
              display: block !important;
            }

            .photo.image {
              width: 100%;
              display: block;
            }

            .col.price {
              display: none;
            }


            .product-item-name {
              position: static;
              width: 100%;
              max-width: 100%;
              clear: both;
              width: 100%;
              text-align: center;
            }
          .col.qty, .col {
            width: 100%;
            max-width: 100%;
          }
      }
         .col.qty, .col.subtotal {
          max-width: 100%;
          padding-bottom: 15px !important;
          margin: 0;
          width: 100%;
          padding: 15px 0 15px 0;
          text-align: center;
          span {
            padding: 0;
          }
        }


          



        }
     }


  .paypal-payment-information {
    display: inline-block;
    margin-top: 0;
    background-repeat: no-repeat;
    @include icon-paypal-white;
    background-size: 30px;
    min-height: 50px;
    background-position: left 15px center;
    background-color: $brand-primary;
    color: #fff;
    width: 100%;
    max-width: 100%;
    padding: 12px 0 0 60px;
    margin: 0 0 30px 0;
  }
  .select-field-wrapper {
    position: relative;
      .icon {
        width: 28px;
        height: 28px;
        background-repeat: no-repeat;
        position: absolute;
        left: 15px;
        top: 50%;
        z-index: 10;
        transform: translateY(-50%);
        @include icon-delivery;
        @media(max-width: $screen-sm) {
          background-size: 22px 22px;
          display: none;
        }

     }
}


  .field.shipping select.select {
    @extend .standard-select-with-icon;
    position: relative;
    @media(max-width: 768px) {
      padding-left: 15px;
      padding-right: 60px;
    }
    &:after {
      content: '';
      position: absolute;
      left: 15px;
      transform: translateY(-50%);
      height: 100%;
      width: 30px;
      top: 0;
      @include icon-delivery;
    }
  }
}
