#know {
	@media(max-width: $screen-md) {
		display: none;
	}
	max-width: 1600px;
	border: 1px solid $text-primary;	
	margin: 0 auto;
	color: $text-primary;
	margin-top: 10px;
	padding: 30px;
		@media(max-width: 767px) {
			padding: 0 0px;
			border: none;
		}
	a {
		color: $text-primary;
		font-size: 25px;
		font-weight: 100;
	}

	.col-md-2 {
		text-align: center;
	}

	.table-headers {
		color: $text-primary;
		font-size: 16px;
		text-transform: uppercase;
		padding: 0 0 10px 0;
		border-bottom: 1px solid #000;
			@media(max-width: 767px) {
				display: none;
			}
	}

	.table-contents {
		padding-bottom: 30px;
		margin: 0 0 30px 0;
		// border-bottom: 1px solid $text-primary;	
		@media(max-width: $screen-md) {
			display: none;
		}
	}

	.template {
		display: none;
	}


	.find-product-form {
    	display: flex;
    	align-items: center;	
    	padding-top: 30px;
    	top: calc(100% + 15px);
    	padding-bottom: 30px;
    	flex-wrap: no-wrap;
    	border-bottom: 1px solid $text-primary;
	}

	.product-title {
		text-align: center;
	}

	.product-sku {
		small {
			display: none;
			position: absolute;
			@media(max-width: 1049px) {
				font-size: 12px;
			}
			&.visible {
				display: block;
			}
		}
	}

	
	.search-input {
		height: 50px;
		border: 1px solid $text-primary;
		color: $text-primary;
		border-radius: 0;
	}

	.create-row {	
		@extend .standard-button;
		max-width: 375px;
		margin: 0 auto;
		display: block;
	}

	.remove-button {
		display: inline-block;
		width: 25px;
		height: 25px;
		@include icon-close;
	}

	.know-form-action {
		@media(max-width: $screen-md) {
			display: none;
		}
		padding: 0 0 30px 0;
		margin: 0 0 30px 0;
		border-bottom: 1px solid $text-primary;
	}
		
	.total-pricing {
		font-size: 20px;
		margin: 0 0 35px 0;
		text-transform: uppercase;
	}	

	.fancy-button {
		@extend .standard-button;
		max-width: 375px;

	}

	.product-quantity {
		font-size: 0;
		letter-spacing: 0;
		&.disabled {
			pointer-events: none;
			opacity: 0.8;
		}
		input {
			pointer-events: none;
		}
	}

	.product-remove {
		text-align: center;
	}

	.bottom-contents {
		text-align: right;
		@media(max-width: $screen-md) {
			display: none;
		}
	}

	.product-quantity-box {	
		height: 49px;
		width: 70px;
		text-align: center;
		border: 1px solid $text-primary;
		color: $text-primary;
		display: inline-block;
		vertical-align: middle;
		font-size: 16px;
		margin: 0;
		border-radius: 0;
			@media(max-width: 1250px) {
				width: 55px;
			}
			@media(max-width: 1111px) {
				width: 45px;
			}
			@media(max-width: $screen-md) {
				width: 68px;
			}
	}

	.btn-quantity {
		background-color: #ebe3e0;
		width: 49px;
		height: 49px;
		display: inline-block;
		font-size: 16px;
		vertical-align: middle;
		margin: 0;
		border: none;
		border-radius: 0;
		appearance: none;
		color: $text-primary;
		box-shadow: none;
		@media(max-width: $screen-md) {
			display: none;
		}
			@media(max-width: 1250px) {
				width: 45px;
			}
			@media(max-width: 1052px) {
				width: 40px;
			}
			@media(max-width: $screen-md) {
				width: 68px;
			}
	}
}

#know-mobile {
	.product-remove {
		text-align: center;
		float: right;
		padding: 20px 10px;
		@media(max-width: 425px) {
			position: absolute;
    		padding: 7px 10px;
		}
		.remove-button {
			display: inline-block;
			width: 25px;
			height: 25px;
			@include icon-close;
			@media(max-width: 425px) {
				width: 17px;
				height: 17px;
			}
		}
	}
}