body.cms-area-managers {
	.breadcrumbs {
		display: none;
	}
}

// Area Managers Columns
#managers-columns, #area-managers-columns {

	@media(max-width: 767px) {
		padding: 0;
		overflow: hidden;
	}

	a {
		color: $text-primary;
	}
	
	 h1 {
		font-size: 40px;
		font-weight: 400;
		margin: 0px;
		line-height: 0.7;
		@media(max-width: $screen-sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	@media(max-width: 767px) {
		 h1 {
			line-height: 1;
			font-size: 20px;
		}
	}

 .area-manager-column .inner {
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 720px;
	background-position: center center;
	position: relative;
}

@media(max-width: 767px) {
	 .area-manger-column {
		margin-bottom: 40px;
	}
	 .area-manager-column .inner {
		min-height: 320px;
	}
}

 h2 {
 	@include standard-heading;
    padding: 40px 0 0px 0;
    margin: 0;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    font-weight: map-get($weight, medium);
    	span {
			font-weight: map-get($weight, light);    		
    	}
	}

	.managers-details {
	    font-weight: map-get($weight, light);
		font-size: 16px;
	    margin: 0;
	    text-align: center;
	    width: 100%;
	    padding: 40px 0 0 0;
	    line-height: 1.7;
    	@media(max-width: 767px) {
    		padding: 20px 0 40px 0;
		}
	}
}
