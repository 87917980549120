body.catalog-product-view .main-content-container {
	padding-top: 15px;

	.breadcrumbs {
		display: block;
		padding: 0px 0 15px 15px;
		margin: 0;
		font-size: 16px;
		@media(max-width: $screen-sm) {
			display: none;
		}
	}
}
