.page-title-wrapper {
	text-align: center;
	margin: 0;

	// Shame
	padding: 0 0 35px 0 !important;
	position: relative !important;
	width: 100% !important;
	height: auto !important;
	
	h1 {
		margin: 0 0 0 0;
		padding: 0;
		font-size: 24px;
		font-weight: map-get($weight, light);
		position: relative;
		width: 100%;
		auto: 100%;
		@media(max-width: $screen-md) {
			font-size: 20px;
		}
	}
}

body {
	&.cms-new-arrivals {
		.page-title-wrapper {
			padding: 0 0 90px 0 !important;
			@media(max-width: $screen-md) {
				padding: 0 0 40px 0 !important;
			}
		}
	}
	&.account {
		.page-title-wrapper {
			h1 {
				width: auto;
				display: inline-block;
				margin-right: 15px !important;
				@media(max-width: $screen-md) {
					margin-right: 0 !important;
				}
			}
		}
	}
	&.account {
		&.customer-address-index {
			.page-title-wrapper {
				padding: 0 0 63px 0 !important;
				@media(max-width: $screen-sm) {
					padding: 0 0 35px 0 !important;
				}
				h1 {
					width: auto;
					display: inline-block;
					margin-right: 15px !important;
				}
			}
		}
		&.sales-order-history {
			.page-title-wrapper {
				padding: 0 0 63px 0 !important;
				@media(max-width: $screen-sm) {
					padding: 0 0 35px 0 !important;
				}
				h1 {
					width: auto;
					display: inline-block;
					margin-right: 15px !important;
				}
			}
		}
		&.sales-order-view {
			.order-details-items .order-title {
				border-bottom: none;
				padding: 0;
			}
			.page-title-wrapper {
				padding: 0 0 56px 0 !important;
				@media(max-width: $screen-sm) {
					padding: 0 0 21px 0 !important;
				}
				h1 {
					width: auto;
					display: inline-block;
					margin-right: 15px !important;
				}
			}
		}
		&.wishlist-index-index {
			.page-title-wrapper {
				padding: 0 0 63px 0 !important;
				@media(max-width: $screen-sm) {
					padding: 0 0 35px 0 !important;
				}
				h1 {
					width: auto;
					display: inline-block;
					margin-right: 15px !important;
				}
			}
		}
		&.customer-account-edit {
			.page-title-wrapper {
				padding: 0 0 63px 0 !important;
				@media(max-width: $screen-sm) {
					padding: 0 0 35px 0 !important;
				}
				h1 {
					width: auto;
					display: inline-block;
					margin-right: 15px !important;
				}
			}
		}
	}

	&.checkout-index-index {
		@media(max-width: $screen-sm) {
			.page-title-wrapper {
				border-bottom: 1px solid $text-primary;
			}
		}
	}
}