.container.coming-soon {
    text-align: center;
    padding: 0;
    max-width: 100%;
    margin-top: -470px;
    display: inline-block;

    @media(max-width: 767px) {
		margin-top: 0;
	}

    @media(max-width: 767px) {
		padding: 40px 0 0 0;
	}

	@media(max-width: 500px) {
		padding: 0;
	}

    	img {
    		&.logo {
    			margin: 0;
    			float: none;
    			padding: 0 10px 0 10px;
    		}
    	}

    	.text {
    		float: right;
    	}
}
.coming-soon-content {
	a {
		@include standard-font-light;
		text-transform: uppercase;
		border: 1px solid $text-primary;
    	padding: 15px 36px;
    	display: block;
    	max-width: 160px;
    	display: inline-block;
	}
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 54px;
		margin: 0;
		
		strong {
			font-weight: 400;
		}

		&.sub-content {
			@include standard-font-light;
			text-transform: none;
			padding-top: 40px;
			margin: 0;
			@media(max-width: 500px) {
				padding-bottom: 20px;
			}
		}
	}
	h2 {
		@include standard-font-heading-light;
		color: $text-primary;
		margin: 0;
		text-transform: uppercase;
	}

}	

.container.coming-soon-image-bottom {
    @media(max-width: 800px) {
		padding: 0 0 40px 0;
	}
	@media(max-width: 500px) {
		padding: 0 0 40px 0;
	}

    	img {
    		&.logo {
    			margin: 0;
    			float: none;
    			padding: 0 10px 0 10px;
    		}
    	}

    	.text {
    		float: right;
    	}
    	.coming-soon-row {
		    margin: 0;
		}
		.coming-soon-box {
			background-color: $brand-primary;
			text-align: center;
			padding: 40px 0;
			margin-top: -545px;
			@media(max-width: 767px) {
				margin-top: 0;
    			padding: 20px 20px 40px 20px;
			}

				h2 {
					@include standard-font-heading-light;
					color: $text-primary;
					margin: 0;
					text-transform: uppercase;
				}
				a {
					@include standard-font-light;
					text-transform: uppercase;
					border: 1px solid $text-primary;
					display: inline-block;
					max-width: 165px;
					width: 100%;
					padding: 15px 0;
					-webkit-transform: translateY(-50%);
   					transform: translateY(-50%);
					&:after {
					    width: 0%;
					    height: 100%;
					    top: 0;
					    left: 0;
					    background: #232323;
					    content: '';
					    position: absolute;
					    transition: all 0.3s;
					    z-index: -1;
			    	}

			    	&:hover {
						color: #fff;
						text-decoration: none;
						&:after {
							width: 100%;
						}
					}
				}
				p {
					@include standard-font-heading-light;
					text-transform: uppercase;
					padding: 0 0 40px 0;
					margin: 0;
					@media(max-width: 767px) {
						padding-bottom: 40px;
					}
					
					strong {
						font-weight: 400;
					}

					&.sub-content {
						@include standard-font-light;
						text-transform: none;
						padding-top: 40px;
						margin: 0;
						@media(max-width: 767px) {
							padding-top: 20px;
						}
					}
				}
		}
}
.coming-soon-bottom-content {
	a {
		@include standard-font-light;
		text-transform: uppercase;
		border: 1px solid $text-primary;
		padding: 15px 36px;
		}
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 54px;
		margin: 0;
		
		strong {
			font-weight: 400;
		}

		&.sub-content {
			@include standard-font-light;
			text-transform: none;
			padding-top: 40px;
			margin: 0;
			@media(max-width: 500px) {
				padding-bottom: 20px;
			}
		}
	}
	h2 {
		@include standard-font-heading-light;
		color: $text-primary;
		margin: 0;
		text-transform: uppercase;
	}

	.image-ourstory {
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 1100px;
		background-position: center center;
		position: relative;
			@media(max-width: 767px) {
				min-height: 380px;
			}

	}	

}	
// END Section with logos