body {
	&.cms-catalogues {
		.breadcrumbs {
			display: none;
		}
    }
}

.catalogues {

	.container {
		@media(max-width: $screen-md) {
			padding: 0 30px;
		}
	}
		
	&__item {
		height: 650px;
		margin: 0 0 30px 0;
		@media(max-width: $screen-lg) {
			height: 500px;
		}
		@media(max-width: $screen-md) {
			height: 450px;
			margin: 0 0 20px 0px;
		}
		
		a {
			// position: absolute;
			// top: 0;
			// left: 0;
			// background-color: #fff;
			// display: block;
			// max-width: 275px;
			// width: 100%;
			// &:hover {
			// 	text-decoration: none;
			// }


			
			@media(max-width: $screen-md) {
				padding: 12px;
			}
			h2 {
				font-size: 16px;
				font-weight: map-get($weight, light);
				position: relative;
				padding: 20px;
				color: $text-primary;
				margin: 0;
				text-transform: uppercase;
				position: absolute;
				top: 0;
				left: 0;
				background-color: #fff;
				display: block;
				max-width: 275px;
				width: 100%;
				&:hover {
					text-decoration: none;
				}

				&:after {
					position: absolute;
					content: '';
					height: 1px;
			    	width: 0px;
			    	bottom: 12px;
			    	transition: width .25s ease-in;
			    	left: 20px;
			    	background-color: #232323;		    
				}

				&:hover {
					&:after {
						width: calc(100% - 40px);
					}
				}

				span {
					font-weight: map-get($weight, medium);
				}
			}
		}
	}
	&__inner {
		position: relative;
		height: 100%;
		width: 100%;
		background-size: cover;

			.hover {
				position: absolute;
			    left: 0;
			    top: 0;
			    display: block;
			    width: 100%;
			    height: 100%;
			    transition: all 0.3s;
			    	&:hover {
			    		background-color: rgba(0, 0, 0, 0.4);
			    	}
			}
	}
}

