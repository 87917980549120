    
    #google_translate_element {
        display: none;
    }

        #additional-section p {

                margin: 0;
                padding: 6px 0 0 0;
                letter-spacing: 1px;
                position: absolute;
                top: 0;
                width: 100%;
                text-align: center;
                 top: 1px;
        }


        @media(max-width: 991px) {
                #switch-country {
                        display: none;
                }
        }

        @media(max-width: 425px) {
                #additional-section {
                        height: 23px !important;
                }
                #additional-section p {
                        padding: 0 0 0 0;
                }
        }

        @media(max-width: 991px) {
                /*#additional-section p {
                        display: none;
                }*/
                #additional-container-wrapper {
                        display: block !important;
                }
        }

                #additional-container-wrapper {
                        z-index: 900;
                        padding-bottom: 0;
                }

                #additional-section {
                        height: 32px;
                        padding-bottom: 0;
                }


                #switch-country .switch-contents {
                    padding-right: 20px;
                    background-image: url(/media/dropdown-arrow-right.png);
                    background-repeat: no-repeat;
                    background-position: right top 11px;
                    height: 100%;
                    cursor: pointer;
                }

                #switch-country .drop-down {
                        display: none;
                        /*width: 190px;*/
                        width: 52px;
                        left: 0;
                        text-align: left;
                        border: 1px solid #f4f4f4;
                        padding: 10px 10px 0px 10px;
                        position: absolute;
                        top: 29px;
                        background-color: #fff;
                }
                @media(max-width: 991px) {
                        #switch-country .drop-down {
                                width: 100%;
                                border-left: none;
                                border-right: none;
                                left: 0;
                                text-align: center;
                                top: 31px;
                        }
                }

                #switch-country .drop-down a {
                        display: block;
                        padding: 0 0 10px 0;
                        margin: 0 0 10px 0;
                        border-bottom: 1px solid #5d5d5d;
                }

                #switch-country .drop-down a:last-child {
                        border-bottom: none;
                        margin-top: 10px;
                }

                #switch-country .switch-contents:hover .drop-down {
                        display: block;
                }



                #switch-country {
                        height: 100%;
                }
                #switch-country .container {
                        height: 100%;
                }

                #switch-country img {
                        display: inline-block;
                        vertical-align: middle;
                }

                #switch-country span {
                        vertical-align: middle;
                        display: inline-block;
                }

                #switch-country #activate-country-switch {
                        position: absolute;
                        left: 0;
                        top: 50%;
                        height: 100%;
                        z-index: 100;
                        transform: translateY(-50%);
                        -ms-transform: translateY(-50%);
                        -webkit-transform: translateY(-50%);
                        -moz-transform: translateY(-50%);
                        -o-transform: translateY(-50%);
                        background-repeat: no-repeat;
                        margin: 3px 0 0 0;
                }
                @media(max-width: 991px) {
                        #switch-country #activate-country-switch {
                                position: static;
                                transform: none;
                                -ms-transform: none;
                                -webkit-transform: none;
                                -moz-transform: none;
                                -o-transform: none;
                                display: inline-block;
                        }
                        /*#switch-country .drop-down a:last-child {
                                margin-left: -13px;
                        }*/
                }

                /*#switch-country #activate-country-switch img {
                        margin-right: 10px;
                }*/

                #switch-country .switch-country-contents {
                        position: relative;
                        height: 100%;
                }
