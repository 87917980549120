body.checkout-cart-index {
	.cart-container .form-cart {
		width: 100%;
		max-width: 100%;
		padding: 0;
	}

	.table-wrapper {
		overflow-x: visible !important;
    	overflow-y: visible !important;
	}

	@media(max-width: $screen-sm) {
		.cart-footer-block {
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;
			
			.cart-summary-block {
				order: 2;
			}

			.cart-shipping-block {
				order: 1;
			}

		}


	}

}

.cart-container {

	.checkout.methods {
		.item:first-child {
			text-align: center;
		}
		@media(max-width: $screen-md) {
			margin: 0 0 35px 0;
		}

		@media(max-width: $screen-sm) {
			margin: 0 0 40px 0;
			padding: 0 0 40px 0;
			border-bottom: 1px solid $text-primary;
			.item:last-child {
				margin-bottom: 0;
			}
		}

		.item {
			text-align: right;
			@media(max-width: $screen-md) {
				text-align: center;
			}
		}

	}

	.alternative-payment-options {
	    position: relative;
	    height: 1px;
	    background-color: $text-primary;
	    width: 100%;
	    margin-top: 10px;
    	margin-bottom: 25px;
    	@media(max-width: $screen-md) {
    		margin-bottom: 35px;
    	}
    	h2 {
		    font-size: 16px;
		    text-transform: uppercase;
		    font-weight: 300;
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    -webkit-transform: translateX(-50%);
		    transform: translateX(-50%);
		    background-color: #fff;
		    margin: 0;
		    top: -11px;
		    padding-left: 25px;
		    padding-right: 25px;
		    @media(max-width: 350px) {
		    	padding-left: 15px;
		    	padding-right: 15px;
		    }
		}
	
	}

	.paypal.checkout {
	    display: inline-block;
	    margin-top: 0;
	    background-repeat: no-repeat;
		@include icon-paypal-payments;
	    background-size: contain;
	    height: 50px;
	    background-position: center center;
	    background-color: #f2f2f2;
	    width: 100%;
	    max-width: 100%;
	    @media(max-width: $screen-md) {
	    	width: 100%;
	    	max-width: 166px;
	    	height: 65px;
	    }
	    input {
	    	width: 100%;
	    	height: 100%;
	    	opacity: 0;
	    }
	}
	.continue-shopping-button {
		display: none;
	}
	.action.update {
		@extend .alternate-button;
		text-transform: uppercase;
		font-weight: 300;
		max-width: 300px;
		border: none;
		@media(max-width: $screen-sm) {
			max-width: 100%;
		}
	}
	.summary.title {
		display: none;
	}

	#cart-totals {
		border: 1px solid $text-primary;
		padding: 25px 25px 10px 25px;
		margin-bottom: 25px;
		color: $text-primary;
		font-size: 16px;
		text-transform: uppercase;
		text-align: right;

		@media (max-width: $screen-sm) {
			border: none;
			border-top: 1px solid $text-primary;
			padding-left: 0 !important;
			padding-right: 0 !important;
			border-left: none !important;
			border-right: none !important;
			border-bottom: 1px solid $text-primary;
			h2 {
				display: none !important;
			}
		}

		@media (max-width: $screen-sm-max) {
			text-align: center;
		}

		.data.table.totals {
			margin: 0;
		}

		.table-wrapper {
		    max-width: 100%;
		    width: 100%;
		    display: inline-block;
		}

		.label {
			font-size: 16px;
			font-weight: 300;
			color: $text-primary;
			padding: 0;
		}

		.totals.shipping.excl {
			.value {
				display: none;
			}
		}

		.mark {
			background: none;
			border: none !important;
			@media (max-width: $screen-sm) {
				text-align: center;
			}
		}

		.amount {
			border: none !important;
			@media(max-width: $screen-md) {
				text-align: right;
			}
		}
	}

	#block-shipping {
		border: 1px solid $text-primary;
		padding: 25px;
		@media(max-width: $screen-sm) {
			border: none;
			padding: 25px 0px 25px 0px;
			border-top: 1px solid $text-primary;
		}

		#block-shipping-heading {
			text-align: center;
			text-transform: uppercase;
			display: block;
			font-weight: 300;
			margin: 0;
			font-size: 16px;
			letter-spacing: 1px;
			padding: 0 0 35px 0;
			@media(max-width: $screen-sm) {
				font-weight: normal;
			}
		}

		.radio {
			margin-bottom: 25px;
			display: none;
		}

		.fieldset.rate {
			padding: 25px 0px 0px 0px;
			@media(max-width: $screen-sm) {
				padding-top: 0;
			}
		}

		dt.item-title {
			text-align: center;
   			padding-bottom: 20px;
			span {
    			text-align: center;
    			margin: 0;
    			text-transform: uppercase;
    			font-weight: 300;
    			font-size: 16px;
    		}
		}

		input {
			@extend .standard-input-with-icon;
			padding-left: 50px;
		}

		select {
			@extend .standard-select-with-icon;
		}

		.shipping-note {
			font-size: 12px;
			color: $text-primary;
			text-align: center;
			padding: 10px 0px 0px 0px;
		}

		input[type='radio']:checked + label {
	    	background-color: #5d5d5d;
	    	color: #fff;
		   	@include icon-delivery-white;
		}

		.item-options label {
			@include icon-delivery;
		    appearance: none;
		    border-radius: 0;
		    background-color: #f4f4f4;
		    font-weight: normal;
		    border: none;
		    min-height: 52px;
		    display: block;
		    text-align: left;
		    padding-top: 17px;
		    text-transform: uppercase;
		    letter-spacing: 1px;
		    color: $text-primary;
		    margin-bottom: 25px;
		    font-size: 16px;
		    background-repeat: no-repeat;
		    background-position: left 15px center;
		    background-position-x: left 15px;
		    background-position-y: center;
		    padding-left: 58px;
		    cursor: pointer;
		    white-space: normal;
		    &:hover {
		    	background-color: #5d5d5d;
		    	color: #fff;
		    	@include icon-delivery-white;
			}
			@media(max-width: $screen-md) {
				padding-bottom: 15px;
    			font-size: 13px;
    			min-height: 45px;
			}
		}


		legend {
			display: none;
		}
		#shipping-zip-form {
			.field {
				position: relative;
			}
			label.label {
			    position: absolute;
			    text-indent: -999px;
    			height: 29px;
    			width: 29px;
    			top: 50%;
    			transform: translateY(-50%);
    			left: 10px;
			}
		}

		.field[name='shippingAddress.country_id'] {
				label {
					@include icon-country;
				}
		}

		.field[name='shippingAddress.region'] {
			label {
				@include icon-state;
			}
		}

		.field[name='shippingAddress.postcode'] {
			label {
				@include icon-postcode;
			}
		}



		.field.note {
			display: none;
		}
	}
}


.cart.table-wrapper{
	@media(max-width: $screen-sm) {
		border: none;
	}
}

#shopping-cart-table, #details-table  {
	th {
		font-size: 16px;
		font-weight: 300;
		text-transform: uppercase;
		padding-left: 30px;
		padding-top: 0;
		border: none !important;
		@media(max-width: $screen-sm) {
			display: none !important;
		}
	}


	tr {
		border-bottom: 1px solid $text-primary;
		position: relative;
		@media(max-width: $screen-sm) {
			border: none;
		}
	}

	td {
		font-weight: 300;
		font-size: 16px;
		padding-left: 30px;
		vertical-align: middle;
		padding-top: 15px;
		padding-bottom: 15px;

		.qty {
			input {
				height: 35px;
			}
		}

		&:before {
			display: none;
		}

		@media(max-width: $screen-sm) {
			display: block;
		}

		strong {
			font-weight: 300;
		}
		span {
			font-weight: 300;

			@media (max-width: $screen-ipad-portrait) {
				&.price-excluding-tax {
					padding-right: 0px;
					font-weight: 600;
					font-size: 20px;
				}
			}
		}
	}

	.product-item-details {
		display: inline-block;
		vertical-align: middle;
		width: auto;
		font-size: 16px;
		color: $text-primary;
		padding: 0;
		font-weight: normal;
	}

	.product-item-name {
		color: $text-primary;
		a {
			color: $text-primary;
		}
	}

	.product-mobile-name {
		display: none;
		@media(max-width: $screen-sm) {
			display: block;
			text-align: right;
		}
	}

	.product-item-photo {
		display: block;
		width: 210px;
		display: inline-block;
		vertical-align: middle;
		outline: none;
		@media(max-width: $screen-sm) {
			position: static;
			float: left;
			width: 160px;
			height: 160px;
			max-width: none;
			margin-bottom: 25px;
		}
	}

	.photo.image {
		width: 210px;
		display: block;
		@media(max-width: $screen-md) {
			padding-bottom: 25px;
		}
		@media(max-width: $screen-sm) {
			width: auto;
			height: auto;
			max-width: none;
			padding-bottom: 0;
		}
	}

	.basket-action-remove {
		position: absolute;
		top: 50%;
		left: auto;
		right: 0;
		transform: translateY(-50%);
		font-size: 16px;
		margin: 0px;
		display: block;
		line-height: 1;
		z-index: 20;
		overflow: hidden;

		@media(max-width: $screen-sm) {
    		right: 0;
    		font-size: 13px;
    		bottom: -8px;
    		height: 25px;
 		   top: auto;
		}

		@media(max-width: $screen-xs) {
		}

		span {
			text-indent: -999px;
			overflow: hidden;
			height: 20px;
			width: 20px;
			@include icon-close;
			color: transparent;
			display: block;
		}

		.action-delete {
			display: block;
		}

		.cart-price {
			color: $text-primary;
			@media(max-width: $screen-sm) {
				font-size: 18px;
			}
			.price {
				color: $text-primary;
				@media(max-width: $screen-sm) {
					font-size: 18px;
				}
			}
		}

		.col.subtotal {
			@media(max-width: $screen-md) {
				display: none;
			}
			.cart-price {
				font-weight: 400;
				color: $text-primary;
			}
		}


		a {
			color: $text-primary;
			font-size: 20px;
			font-weight: normal;
			text-decoration: none;
		}
	}


	.col {
		height: 100%;
	}
	.col.qty {
		text-align: center;
		@media(max-width: $screen-md) {
			text-align: right;
		}
	}

	.col.subtotal {
		position: relative;
		display: table-cell;
		height: 100%;
	}

	.col.remove {
		position: relative;
	}

	@media (max-width: $screen-sm) {

		td {
			padding-right: 0;
		}
		.col.item {
			width: 50%;
			float: left;
			padding: 0;
			text-align: left;
			.product-item-details {
				display: none;
			}
		}

		.col.qty {
			white-space: normal;
			font-weight: normal;
			input {
				max-width: 80px;
			}
			.input-text {
				height: 35px;
			}
		}

		.col.price {
			display: none;
		}

		input {
			width: 100%;
			max-width: 100%;
			border-color: $text-primary;
		}

		.col {
			float :right;
			width: 50%;
			text-align: right;
		}

	}

	.action-towishlist, .action-edit {
		display: none;
	}

}

.cart-totals .totals-tax-summary .amount .price, .opc-block-summary .table-totals .totals-tax-summary .amount .price  {
	padding-right: 0;

	&:after {
		display: none;
	}

}

