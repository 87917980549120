.products {
	margin: 0;
}

.grid-item-selector-wrapper {
    text-align: left;
    position: absolute;
    left: 15px;
    top: 5px;
    @media(max-width: $screen-md) {
        display: none;
    }
}

.grid-item-selector-3 {
    background: url('../images/grid-item-3-selector.png') no-repeat center center;
    width: 73px;
    margin-right: 35px;
    &.grid-active {
        background: url('../images/grid-item-3-selector-active.png') no-repeat center center;
    }
}

.grid-item-selector-5 {
    background: url('../images/grid-item-5-selector.png') no-repeat center center;
    &.grid-active {
        background: url('../images/grid-item-5-selector-active.png') no-repeat center center;
    }
    width: 131px;
}

.grid-item-selector {
    display: inline-block;
    height: 15px;
    cursor: pointer;
}

.page-products.page-layout-1column .products-grid .product-item {
	width: 33.3% !important;
    transition: width 250ms;
    padding-left: 15px !important;
    padding-right: 15px !important;
    margin: 0;
    margin-bottom: 35px;
    // display: flex;
    // flex-direction: column;
}
.page-products.page-layout-1column .products-grid .product-item.five-column {
    width: 20% !important;
    text-align: center;
    padding: 0 15px 0 15px !important;
}

@media(max-width: 991px) {
	.page-products.page-layout-1column .products-grid .product-item  {

        // Added Weird Value For Safari
		width: 48.98% !important;
        padding: 0 7.5px 0 7.5px !important;
	}
	.page-products.page-layout-1column .products-grid .product-item.five-column {

        // Added weird value for Safari
	    width: 49.98% !important;
        padding: 0 7.5px 0 7.5px !important;
	}

}
