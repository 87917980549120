.page-layout-1column {
    .filter-current {   
        .action.remove {
            left: -15px;
            @media(max-width: $screen-md) {
                left: 0;
            }
            &:before {
                @include icon-close
                content: '';
                height: 12px;
                width: 12px;
            }
        }
    }
    .filter.block {
        margin: 0 !important;
    }
}

.filter-actions {
    display: none;
}

.filter-clear {
    display: none;
}


.filter {

    .filter-subtitle {
        border: 1px solid $text-primary;
        font-size: 16px;
        font-weight: 300;
        color: $text-primary;
        padding: 11px 10px;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        background-position: top 11px right 15px;
        background-image: url(../images/icon-set/desktop/EXPAND-PLUS-DESKTOP.svg);
        display: block;

        &.active {
            background-image: url(../images/icon-set/desktop/CLOSE-MINUS-DESKTOP.svg);
        }

        &:hover {
            cursor: pointer;
        }
    }
}


.filter-content {
    position: relative;
    margin: 0 !important;
    text-align: center;
}


.filter-options {
    position: absolute;
    width: 100%;
    z-index: 10;
    background: #fff;
    display: none;

    .filter-options-item {
        border: 1px solid black;
        position: relative;
        width: 100%;
        display: block;
        margin: 0;
        background-repeat: no-repeat;
        background-size: 15px 15px;
        background-position: top 15px right 15px;
        background-image: url(../images/icon-set/desktop/EXPAND-PLUS-DESKTOP.svg);
        border-top: none;

        &.active {
            padding-bottom: 0;
            background-image: url(../images/icon-set/desktop/CLOSE-MINUS-DESKTOP.svg);

            &:after {
                display: none;
            }

            .filter-options-title {
                border-bottom: 1px solid $text-primary;
                padding: 10px 40px 10px 10px;
                background-color: $text-primary;
                color: #fff;
            }
        }

        .filter-options-title {
            font-size: 16px;
            font-weight: 300;
            color: black;
            padding: 10px 14px 0 11px;

            &:after {
                display: none;
                top: 13px;
            }
        }

        .filter-options-content {
            z-index: 5;
            width: 100%;
            padding: 0;
            box-shadow: none;
            display: none;

            .item {
                position: relative;
                border-bottom: 1px solid #ccc;
                padding: 0;
                margin: 0;

                &:last-of-type {
                    border-bottom: none;
                }

                &:hover {
                    background: rgba(#ccc, .5);
                }

                a {
                    display: block;
                    padding: 8px;
                    margin: 0;

                    &:hover {
                        background: transparent;
                    }

                    &,
                    &:visited,
                    .alink:visited,
                    p & {
                        color: #494949;
                    }
                }
            }
        }
    }
}


.filter-content .block-actions {
    display: none !important;
}

.block-title.filter-title {
    display: none !important;
}

.filter-current {
    width: 100%;
    max-width: 100%;
    text-align: center;

    @media(max-width: $screen-sm) {
        margin-left: 0 !important;
        &.active {
            margin: 0 !important;
            padding-bottom: 15px;
            padding-top: 15px;
        }
    }

    .filter-current-subtitle {
        display: inline-block;
        padding: 0;
        font-size: 16px;
        font-weight: 300;
        text-transform: uppercase;
        padding-right: 30px;
        color: $text-primary;

        @media(max-width: $screen-sm) {
            padding-top: 15px;
            padding-bottom: 15px;
            padding-right: 0;
            &:before {
                display: none !important;
            } 
        }
    }

    .filter-label {
        font-weight: 300;
        text-transform: uppercase;
        font-size: 16px;
        color: $text-primary;
        padding-right: 15px;
    }

    .filter-value {
        color: $text-primary;
        font-size: 16px;
        font-weight: 400;
        text-transform: uppercase;
    }

    ol.items {
        padding: 0;
        display: inline-block;
    }
}



/* SORT BY FILTER */
.faux-select {
    width: 100%;
    float: none;
    text-align: right;
    position: absolute;
    right: 0;
    top: -8px;
    max-width: 160px;
    @media (max-width: 992px) {
        max-width: calc(50% - 2px);
        width: 100%;
        height: 40px;
        float: right;
        background-position: center right 11px;
        position: relative;
        bottom: 45px;
        top: auto;
    }

        .faux-select-wrapper {
            border: 1px solid black;
            background-color: #fff;
            text-transform: uppercase;
            font-size: 15px;
            color: #000000;
            font-weight: 300;
            margin: 0;
            max-width: 160px;
            height: 40px;
            line-height: 1;
            background-size: auto;
            background-position: center right 8px;
            -webkit-appearance: none;
            padding: 12px 8px;
            cursor: pointer;
            background-repeat: no-repeat;
            background-size: 15px 15px;
            background-position: top 11px right 15px;
            background-image: url(../images/icon-set/desktop/EXPAND-PLUS-DESKTOP.svg);
            @media (max-width: 992px) {
                max-width: 100%;
                height: 40px;
            }

                &.active {
                    background-image: url(../images/icon-set/desktop/CLOSE-MINUS-DESKTOP.svg);
                    ul.dropdown {
                        display: block;
                        @media (max-width: 992px) {
                            border-top: 1px solid black;
                            top: 39px;
                        }
                    }
                }

                .faux-select-current {
                    font-size: 15px;
                    font-weight: 300;
                    color: #000000;
                    height: 35px;
                    margin: 0;
                    text-align: left;
                    background-repeat: no-repeat;
                    background-size: auto;
                    background-position: top 2px right 1px;
                    background-image: url(../images/icon-set/desktop/refine-icon-down.png);
                    display: block;
                    @media (max-width: 992px) {
                        background-position: top 5px right 1px;
                        padding: 0;
                    }
                }

                ul.dropdown {
                    position: absolute;
                    width: 100%;
                    height: auto;
                    z-index: 10;
                    background: #fff;
                    display: none;
                    top: 39px;
                    left: 0;
                    border: 1px solid black;
                    //border-top: none;
                    padding: 0;

                        li {
                            font-size: 15px;
                            font-weight: 300;
                            color: #000000;
                            text-align: left;
                            padding: 12px 15px 8px 8px;
                            display: -webkit-box;
                            display: flex;
                            margin: 0;
                            background-color: white;
                        }
                }
        }
}
/* END SORT BY FILTER */