#maincontent {
	.order-status {
	    border: none;
	    display: inline-block;
	    vertical-align: top;
	    padding: 0;
	    margin: 0;
	    text-transform: uppercase;
	   	@include standard-font-heading-light;
	   	@media(max-width: $screen-sm) {
			font-size: 20px;
		}
	}
	.order-date {
		@include standard-font-heading-light;
		@media(max-width: $screen-sm) {
			font-size: 20px;
		}
	}
	.items {
		&.order-links {
			display: none;
		}
	}

	.order-summary-actions {
		a {
		    background-color: #f4f4f4;
		    color: #5d5d5d;
		    width: 100%;
		    display: block;
		    font-size: 16px;
		    padding: 13px 0 0 60px;
		    margin-bottom: 25px;
		    clear: both;
		    text-transform: uppercase;
		    text-decoration: none;
		    min-height: 50px;
		    background-position: center left 15px;
		    text-align: left;
		    background-repeat: no-repeat;
		    background-image: url(../images/icon-set/reorder-icon.png);
			&.print {
				background-repeat: no-repeat;
			    background-image: url(../images/icon-set/print-icon.png);
			}
		}
	}

	.order-details-items {
		&.ordered {
			border: none;
			margin: 0;
			padding: 0;
				@media(max-width: $screen-sm) {
			    	padding: 10px;
			    }
		}
	}
	.table-wrapper {
		color: $text-primary;
		&.order-items {
			padding: 0;
			border: none;

			.block-content {
				padding: 5px 25px;
					@media(max-width: $screen-md) {
						padding: 24px 0 0 0;	
					}
			}
			thead {
				@media (max-width: 768px) {
						display: none;
				}
			}
			.col {
				@media (max-width: 768px) {
					text-align: right;
					margin-left: 50%;
				}
				
					&.name {
						@media (max-width: 768px) {
							margin: 0;
							float: left;
							width: 50%;
							text-align: left;
							padding: 0;
						}
						&:before {
							@media (max-width: 639px) {
								display: none;
							}
						}
					}
					&.price {
						@media (max-width: 768px) {
							font-weight: 300;
							display: none;
						}
					}
					&.qty {
						@media (max-width: 768px) {
							padding-bottom: 30px;
						}
						&:before {
							@media (max-width: 639px) {
								display: none;
							}
						}
					}
					&.subtotal {
						
						&:before {
							@media (max-width: 639px) {
								display: none;
							}
						}
					}
			}



			tr {
			    text-align: center;
			    text-transform: uppercase;
			    @include standard-font;
			    border-bottom: 1px solid #5d5d5d;
			    @media (max-width: 768px) {
						border-bottom: none;
					}
			    
			    th {
			    	font-weight: 400;
			    	border: none;
			    	padding-left: 0;
			    	padding-top: 20px;
			    	padding-bottom: 20px;
			    }

			    td {
			    	vertical-align: middle;
			    	font-weight: 400;
			    	@media (max-width: 768px) {
						display: block;
					}
			    	&.name {
			    		text-align: left;
			    		padding: 29px 0px 29px 0px;
			    		img {
			    			float: none;
			    			display: inline-block;
			    			vertical-align: middle;
			    		}

			    		.product-item-name {
			    			text-transform: none;
			    			vertical-align: middle;
			    			display: inline-block;
			    			padding-left: 40px;
			    			@include standard-font-light;
			    				@media (max-width: 992px) {
									display: none;
								}
			    		}
			    	}

			    	.price {
			    		font-weight: 100;
			    		font-size: 20px;
				    		@media (max-width: 768px) {
								font-weight: 700;
							}
			    	}
			    	ul {
			    		&.items-qty {
				    		span {
				    			font-weight: 400;
				    		}
			    		}
			    	}
			    	.mobile-product-name {
			    		font-weight: 100;
			    		font-size: 16px;
			    		padding: 0 0 31px 0;
			    	}
			    }
			}
		}
	}

	.order-total-details {
		text-transform: uppercase;
		padding: 25px 0 0 0;
		font-size: 16px;
		font-weight: 300;
		
		.order-total-block {
			.price {
				float: right;
			}
		}


		@media (max-width: 992px) {
			border-top: 1px solid #5d5d5d;
		    border-bottom: 1px solid #5d5d5d;
		    padding: 40px 0px 0px 0px;		}

			.total-amount {
				text-align: right;
			}
	}

	.row {
		&.total-row {
			padding: 0 0 20px 0;
		}
	}
	
	.grand_total {
		font-size: 20px;
		padding-top: 40px;
			.total-label {
				strong {
					color: #5d5d5d;
				}
			}
			.total-amount {
				span {
					&.price {
						color: #5d5d5d;
					}
				}
			}
	}

	.order-information {
		padding: 40px 0 0px 10px;
	    text-transform: uppercase;
	    font-weight: 300;
		color: $text-primary;
	    	.payment-information {
	    		padding: 0 0 55px 0;

	    			span {
	    				background-repeat: no-repeat;
					    background-image: url(../images/icon-set/credit-card-icon.svg);
					    background-size: 26px 23px;
					    background-repeat: no-repeat;
	    			}
	    	}

	    	span {
	    		padding: 0 0 0 40px;
	    	}

	    	.shipping-information {
	    		padding: 0 0 53px 0;

	    			span {
	    				background-repeat: no-repeat;
					    background-image: url(../images/icon-set/delivery-icon.svg);
					    background-size: 23px 23px;
					    background-repeat: no-repeat;
					    color: $text-primary;
	    			}
	    	}
	}

	.block-order-details-view {
		margin-bottom: 0px;
			@media (max-width: 768px) {
				padding: 0 0 0 15px;
			}
	}

	.order-items {
		margin-bottom: 0;
	}
	.mobile {
		@media (max-width: 768px) {
			padding: 40px 0 0 0;
		}
	}
}

.account .page-main .page-title-wrapper .order-date .label {
	display: none;
}

dl {
	&.payment-method {
		&.checkmemo {
			display: none;
		}
	}
}