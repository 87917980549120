#account-sidebar {
    text-align: center;
    border-right: 1px solid #232323;
    margin: 90px 0 0 0;
    @media(max-width: $screen-md) {
        margin: 82px 0 0 0;
    }
    	.block-collapsible-nav {
            @media(max-width: $screen-sm) {
                top: 0;
            }

    		.content {
    			background: none;
    			padding-top: 0;
                    @media(max-width: $screen-sm) {
                        background-color: #fff !important;
                        text-align: left;
                        border: none;
                        padding-bottom: 0;
                        display: none;
                            &.active {
                                display: block;
                            }
                    }
    		}
            @media(max-width: $screen-sm) {
                .title {
                    background-color: $brand-primary;
                    @include standard-font-regular;
                    text-transform: uppercase;
                    padding-left: 0;
                    border-top: none;
                    border-bottom: none;
                    strong {
                        top: 1px;
                        position: relative;
                    }
                   &:after {
                        @include icon-dropdown;
                        content: '';
                        line-height: inherit;
                        color: inherit;
                        height: 20px;
                        width: 20px;
                        vertical-align: middle;
                        display: inline-block;
                        font-weight: normal;
                        overflow: hidden;
                        speak: none;
                        text-align: center;
                        position: absolute;
                        right: 20px;
                        margin: 0;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                    &.active:after {
                        top: 50%;
                        content: '';
                    }
                }
        	}
    	.nav {
    		&.items {
    			padding-right: 25px;
                @media(max-width: $screen-sm) {
                    padding-right: 0px !important;
                    padding-top: 17px;
                }
    		}
    		&.item {
    			a {
    				color: $text-color;
                    padding: 13px;
                    background: none !important;
                    @media(max-width: $screen-md) {
                        padding: 13px 0px 13px 0px;
                        border-left: none;
                        margin: 0 10px 0 20px;
                    }
    			}
    		}
    	}
    	li {
    		font-size: 16px;
		    color: $text-color;
		    text-transform: uppercase;
		    margin-bottom: 31px;
            font-weight: 100;
                @media(max-width: $screen-sm) {
                    padding: 10px;
                    // border-bottom: 1px solid #5d5d5d;
                    margin: 0px;
                    @include standard-font-light;
                    
                    &:last-child {
                            a {
                                border-top: 1px solid #5d5d5d;
                                padding-top: 25px;
                                padding-bottom: 25px;
                                @media(max-width: $screen-md) {
                                    padding: 30px 0px 30px 0px !important;
                                }
                            }

                            &:after {
                                    display: none;
                                
                                }
                    }



                                &:after {
                                    -webkit-font-smoothing: antialiased;
                                    font-size: 19px;
                                    line-height: inherit;
                                    color: #232323;
                                    content: "\276F";
                                    font-family: 'luma-icons';
                                    // margin: 3px 0 0 0;
                                    vertical-align: middle;
                                    display: inline-block;
                                    font-weight: normal;
                                    overflow: hidden;
                                    speak: none;
                                    text-align: center;

                                        position: absolute;
                                        right: 20px;
                                        top: 15px;
                                
                                }


                }
		    	&.current {
                    a {
                        border: none !important;
                    }
                    @media(max-width: $screen-sm) {
                        margin-bottom: 0;
                        padding-top: 13px;
                        padding-bottom: 13px;

                        &:after {
                                    -webkit-font-smoothing: antialiased;
                                    font-size: 19px;
                                    line-height: inherit;
                                    color: #232323;
                                    content: "\276F";
                                    font-family: 'luma-icons';
                                    // margin: 3px 0 0 0;
                                    vertical-align: middle;
                                    display: inline-block;
                                    font-weight: normal;
                                    overflow: hidden;
                                    speak: none;
                                    text-align: center;

                                        position: absolute;
                                        right: 20px;
                                        top: 10px;
                                
                                }
                    }
		    		strong {
		    			display: block;
					    border: none;
					    color: $text-color;
					    border-bottom: 1px solid #5d5d5d;
					    font-weight: normal;
					    padding-bottom: 17px;
                        font-weight: 100;



                        @media(max-width: $screen-sm) {
                            display: inline;
                            padding: 13px 20px;
                            margin: 0;
                            border: none;
                            font-weight: 100;
                        }
		    		}
		    	} 
		    	&:last-child {
		    		a {
		    			font-weight: 600;
		    			color: #5d5d5d !important;
		    		}
		    	}
    	}
}
}

.title.block-collapsible-nav-title {
    display: none;
    @media(max-width: $screen-sm) {
        display: block;
        border-top: 1px solid #ccc;
        cursor: pointer;
        margin-bottom: 0;
        position: relative;
        border-bottom: 1px solid #ccc;
        padding: 10px 0px 13px 15px;
        display: block;
        text-decoration: none;
    }
}


.sidebar {
    &.sidebar-additional {
        display: none;
    }
}

@media only screen and (max-width: $screen-sm) {
.block-collapsible-nav {
    left: 0;
    position: absolute;
    width: 100%;
    z-index: 5;
}
}