.modal-popup.quickview-modal-popup  {
	.modal-header {
		padding-top: 30px;
		
		.action-close {
			@include icon-close;
			height: 40px;
			padding: 0;
			width: 40px;
			right: 35px;
			top: 35px;
			background-size: 40px 40px;
			z-index: 100;
			span {
				display: none;
			}
			&:before {
				display: none;
			}
		}


	}
	.modal-content {
		padding-bottom: 0px;
	}
}

.quickview-desktop {
	.quickview-read-more {
		display: block;
	}

	.product-description-read-more {
		display: none !important;
	}

	.wholesale-discount {
		margin-bottom: 20px;
	}

	.product-info-wrapper {
		.quickview-read-more {
			display: block;
			@extend .standard-button;
			padding: 13px 0 0 0;
			margin-top: 50px;
		}
	}

	#quickview-contents {
		.product-social-links {
			display: none;
		}
		#quickview-column-main {
			.fotorama__nav--dots {
				padding-bottom: 30px;
				margin-top: -80px;
			}
		.fotorama__stage__shaft {
			.fotorama__img {
				padding-left: 0px;
			}
		}
			.personalisation-wrapper {
				display: none;
			}
			.quickview-product-info-main {
				width: 100%;
				float: none;
				.stock.available {
					display: none;
				}
				.product.attribute.sku {
					display: none;
				}
				.product-info-price {
					.price-box {
						display: block;
						text-align: center;
						font-size: 20px;
						padding-bottom: 30px;
						span {
							font-size: 20px !important;
							font-weight: 400 !important;
						}
					}
				}
				.page-title-wrapper.product {
					padding-top: 150px !important;
					width: 100%;
					float: none;
					h1 {
						text-transform: none;
						font-size: 26px;
						font-weight: 300;
						margin-bottom: 30px;
					}
				}
			}
		}
	}
	.block.related, .block.crosssell {
		display: none;
	}
}

.quickview-button.action.primary {
	  width: 36px !important;
	  height: 36px !important;
	  border: none !important;
	  text-indent: -999px;
	  overflow: hidden;
	  top: 25px !important;
	  transform: none !important;
	  left: auto !important;
	  right: 25px !important;
	  border: none;
	  background: url($icon-hover-quickview) $brand-primary no-repeat center center;
	  background-size: 60%;
	  @media(max-width: $screen-ipad-landscape) {
	  	display: none !important;
	  }
}

