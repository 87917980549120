$hamburger-layer-height: 1px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-width: 25px;
$hamburger-padding-x: 0px;
$hamburger-padding-y: 0px;

.hamburger {
	background: none !important;
	box-shadow: none !important;
	border: none !important;
	button {
		border: none !important;
		background: none !important;
		box-shadow: none !important;
	}
}


#mobile-menu {


	&.mobile-menu--is-sticky {
		.prime-nav-append {
			position: fixed !important;
		}
	}


	display: none;
	vertical-align: middle;
	width: 100%;
	@media(max-width: $screen-md) {
		display: inline-block;
	}
	.prime-nav-append {
		z-index: 100;
		transition: right 250ms linear, opacity 250ms linear;
		width: 80%;
		top: 100%;
		position: absolute;
		background-color: #fff;
		right: -100%;
		opacity: 0;
		// border-right: 1px solid #7f7f7f;
		// padding: 35px 35px;

		ul {
			padding: 0;
			margin: 0;
			li {
				margin: 0;
			}
		}
		
		.account-mobile-option.first-child {
			position: relative;
		}

		.account-mobile-option.first-child:before {
		    content: '';
		    height: 1px;
		    width: 90%;
		    display: block;
			margin: 15px 0 15px 38px;
			width: calc(100% - 55px);
		    background: #232323;
	    }

	.account-mobile-option {
		a {
			color: #000;
			text-transform: uppercase;
			//padding: 20px 0 20px 25px;
			padding: 13px 37px;
			display: block;
			margin-left: 37px;
			background-image: url('/media/sign-in.png');
			background-size: 12px 12px;
			background-repeat: no-repeat;
			font-size: 14px;
			background-position: left center;
		}
		&.choose-language-option {
			a {
				background-image: url('/media/lcdesigns/uk-flag.png');
				background-size: 18px 12px;
				margin-left: 35px;
				padding-left: 28px;
				position: relative;
				&:hover, &:active {
					text-decoration: none;
				}
				&:after {
				    position: absolute;
				    right: 20px;
				    top: 50%;
				    background: url(/media/lcdesigns/next.svg);
				    -webkit-transform: translateY(-50%);
				    transform: translateY(-50%) rotate(90deg);
				    background-repeat: no-repeat;
				    content: '';
				    height: 9px;
				    width: 11px;
				    visibility: visible !important;
				}
				&.active {
					&:after {
						transform: rotate(270deg);
					}
				}
			}


			
			.language-hidden {
				padding: 0 0 0 50px;
				max-height: 0;
				transition: 250ms all;
				overflow: hidden;
				list-style-type: none;

				li {
					font-size: 14px;
				}
				span {
					color: #000;
    				text-transform: uppercase;
    				padding: 20px 0 20px 13px;
    				display: block;
				}
				img, span {
					display: inline-block;
					vertical-align: middle;
				}
				img {
					max-width: 18px;
				}
				&.active {
					max-height: 500px;
				}
			}

		}
		&.basket-option {
			a {
				background-image: url('/media/SHOPPING-BAG.svg');
			}
		}
		&.place-order-option {
			a {
				background-image: url('/media/lcdesigns/place-order-icon.png');
			}
		}

	 }

	.level-0 {
		text-align: left;
		padding: 0 0 0 0px;
	}
	&.active {
		display: block;
	}
	.toggle-mobile {
		display: none;
	}
	nav {

	}

	.sub-menu {
    	background: #fff;
    	flex: 1 0 auto;
    	right: 100%;
    	position: absolute;
    	display: none;
    	top: 0;
    	transition: 250ms;
    	width: 100%;
    	z-index: 1;
    	padding: 20px 0 20px 0;

    	a {
    		font-size: 14px;
    	}

    	&.visible {
    	}
	}

	.sub-menu {
		opacity: 0;
		transition: 250ms all;
	}

    .sub-menu.active,
    .sub-menu.visible {
        display: block;
        left: 0;
        opacity: 1;
        max-height: 100%;
        min-height: 100%;
        overflow-y: scroll;
    }

	.sub-menu-link {
    	display: block;
    	width: 100%;
    	padding: 15px 37px;
    	background: white;
    	position: relative;
    	transition: all 200ms;
    	text-transform: uppercase;
    	color: #000;
	}
	
	.sub-menu-link:hover, .sub-menu-link:active {
    	font-weight: normal;
    	text-decoration: none;
    	color: #000;
    	background: none;
	}

	.sub-menu-link.overview {
  		font-weight: 600;
	}


	.sub-menu-link.has-children::after,
	.sub-menu-link.back::after {
	    position: absolute;
	    right: 20px;
	    top: 50%;
	    background: url('/media/lcdesigns/next.svg');
	    transform: translateY(-50%);
	    background-repeat: no-repeat;
	    content: '';
	    height: 9px;
	    width: 11px;
	    visibility: visible !important;
	}

	.sub-menu-link.back::after {
	    left: 10px;
	    transform: translateY(-50%) rotate(-180deg);
	  	right: auto;
	  }
	 .sub-menu-link.back::before {
	 	content: '';
	 	width: 90%;
	 	position: absolute;
	 	left: 50%;
	 	transform: translateX(-50%);
	 	bottom: 0;
	 	height: 1px;
	 	background-color: $brand-secondary;
	 }
}
	&.toggled {
		.prime-nav-append {
			right: 0;
			top: 50px;
			position: absolute;
			opacity: 1;
			transition: right 250ms linear, opacity 250ms linear;
			text-align: left;
		}
	}



	.global-menu-items {
		position: absolute;
		top: 100%;
		background-color: #fff;	
		left: 0;
		width: 100%;
		display: block;
		padding: 15px 0;
		border-bottom: 1px solid $text-primary;
		border-left: 1px solid $text-primary;

		ul {
			&:before {
			    content: '';
			    position: absolute;
			    top: 0;
			    height: 1px;
			    width: calc(100% - 65px);
			    background-color: $brand-secondary;
			    z-index: 10;
			    left: 37px;
			    display: block;
    			}	
		}
		li {
			display: block;
			padding: 15px 37px;
		}
		a {
			color: $text-primary;
			text-transform: uppercase;
			padding: 0 0 0 25px;
			background-image: url('/media/NAME-ICON.svg');
			background-size: 12px 12px;
			background-repeat: no-repeat;
			font-size: 14px;
			background-position: left center;
		}
	}
}


#mobile-menu .main-nav-sub-menus-container {
    opacity: 0;
    padding: 30px;
    position: relative;
    height: calc(100vh - 73px);
	overflow-y: scroll;
    -webkit-transition: opacity 0.5s ease-in-out;
    transition: opacity 0.5s ease-in-out;
    width: 100%;
    z-index: -10;
	border-left: 1px solid #232323;
	border-bottom: 1px solid #232323;

}

 #mobile-menu.toggled .main-nav-sub-menus-container {
  opacity: 1;
  z-index: 40;
  display: block;
}

.header-section--is-sticky {
	.prime-nav-append {
		top: calc(100% + 1px);
	}
}