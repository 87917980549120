/* #################################################
 THE PRODUCT LISTING ITEMS
#################################################### */

.product-grid-item {
	.actions-primary {
		// Make the out of stock message like a button
		.stock.unavailable {
			@extend .standard-button-link;
			opacity: 0.6;
		    @media(max-width: $screen-sm) {
		    	font-size: 13px;
		    }
		    @media(max-width: $screen-xs) {
		    	font-size: 11px;
		    }
		    @media(max-width: 400px) {
		    	font-size: 10px;
		    	padding-top: 17px;
		    	.stock-due-seperator {
		    		display: none;
		    	}
		    	.stock-due-date {
		    		display: block;
		    		padding-top: 3px;
		    	}
		    }
		    &.stock-due {
		    	@media(max-width: 400px) {
		    		padding-top: 11px;
		    	}
		    }
		}
	}

	// Product SKU 
	.SKU {
		@include small-standard-font-heading;
		text-align: center;
		font-weight: 100;
		padding: 0 0 28px 0;
		min-height: 45px;
	}

	.product-item-inner-wrapper {
		margin-top: auto;
		.product-item-inner {
			&:before {
				display: none;
			}
		}
	}


	// Set The Product Title CSS
	.product-item-name {
		@include standard-font-light;
		color: #232323;
		margin: 0 0 0px 0;
		min-height: 60px;
		@media(max-width: $screen-sm) {
			min-height: 81px;
		}
	}
	
	// CSS For Price Box Section (Old Price / Sales ETC)
	.price-box {
		@include standard-font-regular;
		@include text-center;
		padding: 0 0 30px 0;
		margin: 0;
		min-height: 52px;

		
		// The Price That Appears With A Line Through (The Regular Price)
		.old-price {
	   		@include standard-font-regular;
	   		color: #5d5d5d;
			margin: 0 15px 0 0;

			// The Old Price Span That Actually Contains The Price
			span.price {
		   		@include text-line-through;
		   		font-size: 16px;
			}
	    }

	    .special-net-price {
		    display: none;
		}
	}


	.product-item-actions {
		display: block;
		margin: 0;
		.actions-primary {
			width: 100%;
			display: block;
			&:hover {
				opacity: 1 !important;
				button {
					background-color: rgba(234, 228, 225, 0.8) !important;
				}
			}

				input {
		    		&#qty {
		    			border: 1px solid $text-primary;
					    position: absolute;
					    width: 70px;
					    height: 50px;
					    color: $text-primary;
					    background-color: #fff;
					    border-radius: 0;
					    text-align: center;
					    border-right: none;
					    z-index: 2;
					    /* Change The Input Box On Medium Screen Size */
					    @media(max-width: $screen-md) {
					    	width: 35px;
					    	height: 45px;
					    }
					}
		    	}

			.action {
				&.primary{
					background-image: none;
				    background: #eae4e1;
				    cursor: pointer;
				    border: none;
				    border-radius: 0;
				    display: inline-block;
				    @include standard-font-regular;
				    letter-spacing: 1px;
	    			text-transform: uppercase;
				    padding: 7px 0px 7px 70px;
				    box-sizing: border-box;
				    vertical-align: middle;
				    width: 100%;
				    height: 50px;
				    @media(max-width: $screen-md) {
				   		font-size: 16px;
				   		padding: 7px 0 7px 30px;
				   		height: 45px;
				    }
				    @media(max-width: $screen-sm) {
				    	font-size: 13px;
				    }
				    @media(max-width: $screen-xs) {
				    	font-size: 11px;
				    }
				}
			}
		}
	}
}