.address-block { 
	input {
		margin-bottom: 25px;
		padding-left: 25px;
		text-transform: uppercase;
		background-image: none !important;

				color: #5d5d5d;
			    border: 1px solid #5d5d5d;
			    font-size: 16px;
			    font-weight: 300;
			    height: 50px;
			    max-width: 100%;
			    width: 100%;
			    display: block;
			    background-position: center left 20px;
			    font-size: 16px;
			    background-repeat: no-repeat;
			    font-family: "Lato", sans-serif;
			    border-radius: 2px;
			&:focus {
				border-width: 2px;
				outline: none;
				box-shadow: none;
			}    
			&#firstname {
			    background-repeat: no-repeat;
			    background-image: url(../images/icon-set/name-icon.png);
			    background-size: 20px 21px;
			}
			&#company {
				@include icon-company;
			}
			&#lastname {
			    background-repeat: no-repeat;
			    background-image: url(../images/icon-set/name-icon.png);
			    background-size: 20px 21px;
			}
			&#vat_id {
				background-repeat: no-repaet;
				@include icon-vat;
			}
			&#telephone {
			    background-repeat: no-repeat;
			    background-image: url(../images/icon-set/phone-icon.png);
			    background-size: 16px 27px;
			}
			&#street_1, &#street_2 {
				background-repeat: no-repeat;
			    background-image: url(../images/icon-set/street.png);
			}
			&#city {
				background-repeat: no-repeat;
			    background-image: url(../images/icon-set/city-icon.png);
			}
			&#zip {
				background-repeat: no-repeat;
			    background-image: url(../images/icon-set/post-code.png);
			}
			&#region {
				display: inline-block !important;
				background-repeat: no-repeat;
			    background-image: url(../images/icon-set/state-province-icon.png);
			    background-size: 21px auto;
			}
	} 
	select {
		margin-bottom: 25px;
		padding-left: 25px;
			    background-repeat: no-repeat;
			    background-image: url(../images/icon-set/dropdown-arrow-icon.svg);
			    background-size: 20px 20px;
			    background-position: right 20px center;

			    color: #5d5d5d;
			    border: 1px solid #5d5d5d;
			    font-size: 16px;
			    font-weight: 100;
			    text-transform: uppercase;
			    height: 50px;
			    max-width: 100%;
			    width: 100%;
			    display: block;
			    font-family: "Lato", sans-serif;
			    border-radius: 2px;
	}
	.field {
		&.choice {
			margin-bottom: 25px;

			label {
				margin: 0;
			    padding: 0;
			    font-size: 16px;
			    text-transform: uppercase;
			    display: inline-block;
			    font-weight: 300;
			    vertical-align: middle;
			    color: #5d5d5d !important;
			}
		}
	}
	input[type='checkbox'] {
		    height: 26px;
		    width: 26px;
		    border: 1px solid #5d5d5d;
		    -webkit-appearance: none;
		    -moz-appearance: none;
		    appearance: none;
		    padding: 0;
		    z-index: 10;
		    float: none;
		    margin: 0;
		    display: inline-block;
		    vertical-align: middle;
		    margin-right: 15px !important;

		    	&:checked {
		    		background-color: #5d5d5d;
		    	}
	}
}

.select-field-wrapper {
	position: relative;
	.icon {
		display: none !important;
		width: 28px;
	    height: 28px;
	    background-repeat: no-repeat;
	    position: absolute;
	    left: 15px;
	    top: 50%;
	    -webkit-transform: translateY(-50%);
	    transform: translateY(-50%);
	    background-repeat: no-repeat;
	    background-image: url(../images/icon-set/city-icon.png);
	}
}

.add-new-address-action {
    max-width: 500px;
    margin: 0 auto 30px auto;
	    @media (max-width: 768px) {
			max-width: 100%;
		    padding: 30px 10px 0 10px;
		    border-top: 1px solid #5d5d5d;
		}
    	button {
    		width: 100%;
		    max-width: 100%;
		    border: none;
		    @include standard-font-regular;
		    height: 50px;
		    background-color: $brand-primary;
		    text-transform: uppercase;
		    text-decoration: none;
		    box-shadow: none;
		    letter-spacing: 1px;
		    border-radius: 2px;
		   		&:hover {
		    		background-color: #eae4e1;
				    opacity: 0.8;
				    color: black;
		    	}
    	}
}

.form-address-edit {
	 #region_id {
	    display: none !important;
	}
}