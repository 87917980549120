body {
	&.cms-new-arrivals {
		#content-container-full-width {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
	}
}

@media(max-width: 767px) {
	.coming-soon-wrapper {
		display: block;
		position: relative;

		.container.new-in {
			position: absolute;
			bottom: 0;
			z-index: 10;
		}

	}

}

.container.new-in {
    text-align: center;
	padding: 40px 0;
    background-color: $brand-primary;
    max-width: 1740px;
    margin-left: 0;

    @media(max-width: 767px) {
		padding: 20px 26px 40px;
	}

	.text {
		float: right;
	}

}
.new-in-content {
	a {
		@include standard-font-light;
		text-transform: uppercase;
		border: 1px solid $text-primary;
    	padding: 15px 0px;
    	display: inline-block;
    	max-width: 165px;
    	width: 100%;
    	-webkit-transform: translateY(-50%);
   		transform: translateY(-50%);
	   		&:after {
			    width: 0%;
			    height: 100%;
			    top: 0;
			    left: 0;
			    background: #232323;
			    content: '';
			    position: absolute;
			    transition: all 0.3s;
			    z-index: -1;
			}

			&:hover {
				color: #fff;
				text-decoration: none;
				&:after {
					width: 100%;
				}
			}
    	@media(max-width: 767px) {
			font-weight: 700;
		}
	}
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		margin: 0;
		
		strong {
			font-weight: 400;
		}

		&.sub-content {
			@include standard-font-light;
			text-transform: none;
			padding: 40px 0;
			margin: 0;
			@media(max-width: 767px) {
				padding-bottom: 40px;
				padding-top: 20px;
			}
		}
	}
	h2 {
		@include standard-font-heading-light;
		color: $text-primary;
		margin: 0;
		text-transform: uppercase;
		line-height: 1;
		@media(max-width: 767px) {
			font-size: 16px;
		}
	}
}

.new-in-image-top {
	.no-right-padding {
		@media(max-width: $screen-md) {
			padding: 0;
		}
	}
}

.new-in-image-top-content {
	.image-ourstory {
		background-size: cover;
		background-repeat: no-repeat;
		min-height: 872px;
		background-position: center center;
		margin-top: -317px;
		z-index: 1;
		position: relative;
		@media(max-width: 767px) {
			margin-top: 0;
			min-height: 705px;
		}
	}	
}	
