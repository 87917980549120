/* ! =========================================================================
 * = SCSS Controller
 *
 * This SCSS file is to be as the overview of all SCSS files
 * New SCSS Files need to be called from within this file. 
 * This section handles all styling on the website
 *
 * @author: Jonathan Taylor - Mobius Media
 * @website: LC Designs (lcdesigns.co.uk)
 *  
============================================================================*/

/** --------------------------------------------------------------------------
 * = External Items
 * Items that are loaded via an external resource (NPM/Yarn etc)
 ---------------------------------------------------------------------------*/

$lato-font-path: '../fonts/';
@import "../node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../bower_components/lato-webfont/scss/_lato-medium";

/** --------------------------------------------------------------------------
 * = Base
 * The Base Settings / Variables of the website
 * E.G fonts, colours, default element styling
 ---------------------------------------------------------------------------*/

 @import "base/index";

/** --------------------------------------------------------------------------
 * = Utilities
 * Contains global mixins, functions, helper selectors, etc
 ---------------------------------------------------------------------------*/

 @import "util/index";

/** --------------------------------------------------------------------------
 * = Componenets
 *  Contains each self-contained components used on websites
 *  E.G newsletter, video componenets
 ---------------------------------------------------------------------------*/

 @import "components/index";



/** --------------------------------------------------------------------------
 * = Layout
 *  Contains styling for larger layout components; 
 *  e.g. nav, header, footer, etc.
 ---------------------------------------------------------------------------*/

 @import "layout/index";

/** --------------------------------------------------------------------------
 * = Templates
 *  Contains page-specific styling 
 *  E.G product, category, account, etc
 ---------------------------------------------------------------------------*/

 @import "templates/index";