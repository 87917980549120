.page-wrapper {
	overflow: hidden;
}

.no-padding {
	padding: 0;
}
.no-left-padding {
    padding-left: 0;
}
.no-right-padding {
    padding-right: 0;
}
// .row-15px {
// 	margin-left: 15px;
// 	margin-right: 15px;
// }
.container {
	max-width: 1600px;
	width: 100%;
	margin: 0px auto;
}

.category-special-offers {
		#maincontent {
	    width: 100%;
	    max-width: 1600px;
	}
}
div#maincontent {
    max-width: 100%;
}


