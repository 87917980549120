.welcome-message {
    display: inline-block;
    margin-bottom: 30px;
    line-height: 1;
    @media(max-width: $screen-sm) {
    	font-size: 20px;
    	margin: 0;
    }
    @include standard-font-heading;
		    letter-spacing: 2px;
		    text-align: center;
		    margin: 0 0 30px 0;
		    text-transform: uppercase;
}

.customer-information {
    padding-left: 15px;
    padding-right: 15px;
    	span {
    		display: block;
		    background-repeat: no-repeat;
		    padding: 0 0 0 52px;
		    @include standard-font-light;
		    text-transform: uppercase;
		    	&:first-child {
		     		margin: 0 0 50px 0;
		     	}
    	}

    	.customer-name {
    		background-repeat: no-repeat;
    		@include icon-name;
    	}

		.customer-price {
			@include icon-credit;
			background-size: 25px 25px;
		}

    	.customer-order {
    		@include icon-order;
    	}
    	.customer-email {
    		background-repeat: no-repeat;
		    @include icon-email;
		    @media(max-width: $screen-sm) {
		    	word-break: break-word;
		    }
    	}
     	
}

.password-information {
	padding-left: 15px;
	padding-right: 15px;

	.password {
	    background-repeat: no-repeat;
		@include icon-password;
	    background-repeat: no-repeat;
	    background-position: center left;
	    padding: 10px 0 10px 52px;
	    text-transform: uppercase;
	    font-size: 16px;
	    color: #5d5d5d;
	}
}