.product-related-contents {
	color: $text-primary;
	margin: 0 -15px 0 -15px;
	position: relative;
	a {
		color: $text-primary;
	}

	.slick-list {
		padding: 0 65px 0 0px;
		img {
			width: 100%;
			padding: 0 0 30px 0;
			@media(max-width: $screen-md) {
				padding: 0 0 15px 0px;
			}
		}
	}

		.slick-arrow {
			opacity: 0;
			transition: 250ms all;
		}

		&:hover {
			.slick-arrow {
				opacity: 1;
			}
		}

		.slick-arrow {
		    background-color: rgba(255, 255, 255, 0.45);
		    position: absolute;
			height: 201px;
			width: 50px;
			top: 0;
			background-repeat: no-repeat;
			border: none;
			color: transparent;
			text-indent: -99999px;
    		background-size: 14px 30px;
    		transform: none;
    		background-position: center center;
    		border-radius: 0;
    		box-shadow: none;
		}

		.slick-prev {
    		background-position: center center;
    		@include icon-related-arrow-left;
    		background-size: 17px 32px;
    		z-index: 10;
    		left: 15px;
    		@media(max-width: $screen-sm) {
    			left: 0px;
    		}
    	}

		.slick-next {
			right: 15px;
			@include icon-related-arrow-right;
	   		background-position: center center;
    		background-size: 17px 32px;
	   		@media(max-width: $screen-sm) {
	   			right: 0px;
	   		}
		}

	.related-product-item {
		padding: 0 15px;
	}

	.related-product-item-name {
		font-size: 16px;
		color: $text-primary;
		margin: 0 0 15px 0;
		text-align: center;
		font-weight: 400;
		min-height: 35px;
	}
	.related-product-item-sku {
		font-size: 12px;
		margin: 0 0 20px 0;
		text-align: center;
		font-weight: 300;
	}

	.related-product-item-price {
		font-size: 16px;
		color: $text-primary;
		text-align: center;
		font-weight: 300;
	}
}
