body {
	&.customer-account-logoutsuccess {
		.page-title-wrapper {
			text-align: center;
			text-transform: uppercase;
				@media(max-width: $screen-sm) {
					padding-top: 17px;
				}
				h1 {
					@include standard-font-heading-light;
				}
			
		}

		p {
			&.logout-text {
				margin-top: 20px;
				text-align: center;
				@include standard-font-grey;
			}
		}
	}
}