.our-brands-information-page {
	position: relative;
	.brand-information-item {
		position: relative;
		margin: 0 0 60px 0;
			@media(max-width: $screen-md) {
				margin: 0 0 20px 0;
			}
			@media(max-width: 767px) {
				margin: 0 15px 20px 15px;
			}
		img {
			width: 100%;
			@media(max-width: 767px) {
				height: 450px;	
			}
		}
		a {
			color: $heading-color;
			font-size: 16px;
		}
		&.london-brand-inforamtion-item {
			position: absolute;
			top: 55px;
			right: 0;
			width: 50%;
			@media(max-width: $screen-md) {
				position: relative;
				top: 0;
				right: 0;
				width: 100%;
				padding: 0 15px;	
			}
			@media(max-width: 767px) {
				padding: 0 45px 0 15px;	
			}
			.title {
				@media(max-width: $screen-md) {
					padding: 12px 0px 12px 30px;	
				}
				
			}
		}
		&.londonco-brand-information-item {
			margin-top: 280px;
			height: 730px;
			position: absolute;
			z-index: 2;
				@media(max-width: 1440px) {
					margin-top: 120px;
				}
				@media(max-width: $screen-md) {
					margin-top: 0;
					height: 100%;
					position: relative;
				}
		}
		&.jacob-jones-brand-information-item {
			margin-top: 140px;
			@media(max-width: $screen-md) {
				margin-top: 0;
			}
			img {
				&.jacob-jones-image {
					width: 100%;
					height: 550px;
						@media(max-width: 1199px) {
							height: 400px;
						}
						@media(max-width: $screen-md) {
							height: 100%;
						}
						@media(max-width: 767px) {
							height: 450px;	
						}
				}
			}
		}
		&.bottom-divider {
			@media(max-width: $screen-md) {
				display: none;
			}
		}

		// &.dulwich-brand-information-item {
		// 	img {
		// 		width: calc(100% - 50px)
		// 	}
		// }
	}

	.title {
		position: absolute;
		top: 0;
		left: 0;
		background-color: #fff;
		display: block;
		max-width: 275px;
		width: 100%;
		padding: 15px;
		line-height: 1;
		text-transform: uppercase;
		font-weight: 400;
		@media(max-width: $screen-md) {
			padding: 12px;
		}
	}

	.container {
		&.divider-bottom {
		    background-color: #eae4e1;
		    max-width: 100%;
		    height: 346px;
		    margin-top: -190px;
		    	@media(max-width: $screen-md) {
					background-color: transparent;
				    margin-top: 0;
				    height: 100%;
				}
		}
	}


}
// .brands-information-middle {
// 	    background-image: url(/media/divider.png);
// 	    background-repeat: no-repeat;
// 	    bottom: 169px;
// 	    background-position: bottom 112px center;
// 	}