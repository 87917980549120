.modal-popup {
	.modal-inner-wrap {
		position: absolute;
		top: 50%;
		-webkit-transform: translateY(-50%) !important;
		transform: translateY(-50%) !important;
		box-shadow: none;
		text-align: center;
		padding: 0px;
		margin: 0 auto;
		color: $text-primary;
		.modal-header {
			padding-top: 60px;
			border-bottom: none !important;
		}
		.modal-content {
			border-bottom: none;
			box-shadow: none !important;
			border: none !important;
		}
		.modal-footer {
			border-top: none;
			text-align: center;
			padding: 60px 0px 60px 0;

			button {
				max-width: 400px;
			    display: inline-block;
			    margin: 0 15px 0 15px;
	    		width: 100%;
				border: none;
			    color: $text-primary;
			    height: 50px;
			    background-color: $brand-primary;
			    text-transform: uppercase;
			    font-size: 16px;
			    font-weight: 300;
			    text-decoration: none;
			    box-shadow: none;
			    letter-spacing: 1px;
				border-radius: 2px;
					    
				&.action-dismiss {
					display: inline-block;
					max-width: 400px;
						width: 100%;
					    border: none;
					    color: #5d5d5d;
					    height: 50px;
					    background-color: #f4f4f4;
					    text-transform: uppercase;
					    font-size: 16px;
					    font-weight: 300;
					    text-decoration: none;
					    box-shadow: none;
					    letter-spacing: 1px;
					    border-radius: 2px;
				}
			}
		}
	}
}
.quickview-product-info-main .product-social-links {
  display: none; }
.quickview-product-info-main .increase-quantity {
  display: none; }
.quickview-product-info-main .decrease-quantity {
  display: none; }

.quickview-modal-popup .fotorama__thumb.fotorama_vertical_ratio img {
  margin-right: 15px; }
.quickview-modal-popup .modal-inner-wrap {
  max-width: 1170px; }
.modal-popup .modal-content {
  box-shadow: none !important;
  border: none !important; }

.modal-content .block-viewed-products-grid {
  display: none; }

.modal-popup .modal-header {
  border-bottom: none !important; }

.modal-popup.agreements-modal.modal-slide._inner-scroll {
	display: none;
}