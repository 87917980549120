.mobile-header {
	position: relative;
	display: flex;
	align-items: center;
	padding: 0 15px 0px 15px;

	.mobile-header-column {
		&:last-child {
			padding-right: 0;
		}
	}

	/* This is to make sure the mobile menu goes to the correct width */
	.mobile-icons-wrapper, .mobile-icons, .mobile-menu-wrapper {
		position: static;
	}

	.mobile-icons {
		display: flex;
		height: 50px;
		align-items: center;
		flex-direction: row;
		flex-wrap: no-wrap;
	}

	

	.mobile-logo {
		img {
			display: block;
			margin: 0 auto;
			width: 150px;
			padding-top: 5px;
			max-width: 100%;
		}
	}

	button.hamburger {
		height: 50px;
		width: 100%;
		padding: 0 15px 0 0;
		text-align: right;
	}

	.mobile-menu-wrapper {
		text-align: right;
		font-size: 0;
		margin: 3px 0 0 0;
	}

	.mobile-menu-button {
		text-align: right;
	}

	.mobile-menu-login {
		display: inline-block;
		vertical-align: middle;
		margin-right: 15px;
		display: none;
		img {
			max-width: 100%;
		}
		height: 30px;
		width: 30px;
		display: inline-block;
		@media(max-width: $screen-sm) {
			height: 20px;
			width: 20px;
			margin-right: 15px;
		}
		@media(max-width: 365px) {
			margin: 0 12px 0 0;
		}
		@media(max-width: 350px) {
			height: 15px;
			width: 15px;
		}
	}
	
	.initiate-search {
		height: 30px;
		width: 30px;
		display: inline-block;
		margin: 0 30px 0 0;
		@media(max-width: $screen-sm) {
			height: 20px;
			width: 20px;
			margin: 0 15px 0 0;
		}
		@media(max-width: 370px) {
			margin: 0 15px 0 0;
			height: 15px;
			width: 15px;
		}
	}	

	
	.mobile-minicart-content {
		display: inline-block;
		width: 30px;
		height: 30px;
		position: relative;
		@media(max-width: $screen-sm) {
			height: 20px;
			width: 20px;
		}
		@media(max-width: 370px) {
			height: 15px;
			width: 15px;
		}
		.cart-summary-count {
		    position: absolute;
		    color: $text-primary;
		   	top: 50%;
		    left: 50%;
		    -webkit-transform: translate(-50%, -50%);
		    transform: translate(-50%, -50%);
		    margin-top: 5px;
		    font-size: 15px;
		    @media(max-width: $screen-md) {
		    	margin-top: 3px;
		    }
		    @media(max-width: $screen-sm) {
		    	font-size: 10px;
		    	margin-top: 5px;
		    }
		    @media(max-width: 370px) {
		    	font-size: 8px;
		    	margin-top: 7px;
		    }
    	}
	}


}