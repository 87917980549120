.contact-index-index {
	#maincontent {
		padding-left: 0px;
		padding-right: 0px;
	}
	.column.main {
		padding-bottom: 0px;
	}
}

#contact {
	 .form-block {
	    padding: 40px;
	    border: 1px solid $text-primary;

	    @media(max-width: 991px) {
	    	padding: 0px 15px 30px 15px;
	    	border: none;
			border-top: 1px solid $text-primary;
			border-bottom: 1px solid $text-primary;
		}

	    .control {
	    	width: 100%;
	    }
	}
	.fieldset {
		margin: 0;
	}
	.legend {
		text-align: center;
	    text-transform: uppercase;
	    margin: 0 0 60px 0;
	    line-height: 1;
	    border: none;

	    	@media(max-width: 991px) {
		    	margin: 40px 0 40px 0;
			}

	    span {
	    	@include standard-font-grey;
		    letter-spacing: 1px;
		}
	}

	.field {
		margin: 0 0 0 0;
	}

	input:focus {
		outline: none;
		box-shadow: none;
	}

	input {
		@extend .standard-input-with-icon;
	    @include standard-font-grey;
	    text-transform: uppercase;
	    background-position: left 15px center;

		    &.input-name {
				background-repeat: no-repeat;
				@include icon-name;
			    	&::placeholder {
				     	color: $text-primary;
				     }
		    }

		    &.input-company {
			    @include icon-company;
		    }

		    &.input-email {
		    	@include icon-email;
		    }

		    &.input-phone {
				@include icon-phone;
		    }

		    &.mage-error {
		    	border: 1px solid $colour-error;
		    }

		}

		textarea:focus {
			outline: none;
			box-shadow: none;
		}

		textarea {
		    min-height: 200px;
		    @include standard-font-grey;
		    @extend .standard-textarea-with-icon;
		    &.mage-error {
		    	border: 1px solid $colour-error;
		    }
		}

		.primary {
			width: 100%;
		}
		button.submit.primary {
			width: 100%;
		    max-width: 100%;
		    border: none;
		    color: $text-color;
		    height: 50px;
		    background-color: $brand-primary;
		    text-transform: uppercase;
		    font-size: $standard-font-size;
		    font-weight: 400;
		    font-family: $font-family-lato;
		    text-decoration: none;
		    box-shadow: none;
		    letter-spacing: 1px;
		    border-radius: 2px;
		    margin: 0 !important;
		    	&:hover {
		    		background-color: #eae4e1;
				    opacity: 0.8;
				    color: black;
		    	}
		}

		.opening-times {
			text-align: center;
			color: $text-color;
			margin: 40px 0 0 0;

			h2 {
				@include standard-font-light;
				text-transform: uppercase;
				margin: 0 0 25px 0;
			}

			.content {
				margin-bottom: 60px;
				@include standard-font-light;
					@media(max-width: 991px) {
				    	margin-bottom: 40px;
					}

				a {
					color: $text-color;
				}
			}
			.content-address {
				margin-bottom: 40px;
				@include standard-font-light;
					@media(max-width: 991px) {
				    	margin-bottom: 50px;
					}

				a {
					color: $text-color;
				}
			}

			span {
				margin: 0 5px 0 5px;
			}
		}

	}

.actions-toolbar {
	margin-left: 0 !important;
}

.actions-toolbar .primary button, .actions-toolbar a.primary {
    color: $text-color;
    cursor: pointer;
    background: $brand-primary;
    width: auto;
    min-width: 131px;
    font-family: $font-family-lato;
    font-weight: 400;
    font-size: $standard-font-size;
    border-radius: 0px;
    height: 31px;
    padding: 0 20px;
    line-height: 31px;
    text-transform: uppercase;
    border: none !important;
    margin-bottom: 5px !important;
    transition: 200ms all;
    height: auto;
    padding-top: 3px;
    padding-bottom: 3px;
}

div#contact-page-container {
    width: 100%;
    max-width: 100%;
}
.map {
	&.container-fluid {
		&.map {
	    	padding: 0;
		}
	}
}