#shopping-cart-table {
	.item-info {
		.cart-price {
			.price {
				color: $text-primary;
				@media(max-width: $screen-sm) {
					font-size: 18px;
				}
			}
		}
		
		.qty {
			input {
				border: 1px solid $text-primary;
				color: $text-primary;
			}
		}
	}
}