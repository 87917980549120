body {
	&.cms-coming-soon {
		#content-container-full-width {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		.breadcrumbs {
			display: none;
		}
		@media(max-width: 767px) {
			#maincontent{
				padding: 40px 0;

					.column {
						&.main {
							padding-bottom: 0;
						}
					}
			}
		}
		
    }
}