@mixin reset-style {
	padding: 0;
	margin: 0;
	display: block;
	background: none;
	position: static;
	border: none;
	float: none;
}

@mixin text-line-through {
	text-decoration: line-through;
}

@mixin font-weight($name) {
	font-weight: map-get($weight, $name);
}

@mixin font-colour($name) {
	color: map-get($colours, $name);
}
