.slick-slider {
	margin-bottom: 40px;
		@media(max-width: 768px) {
    		margin-bottom: 20px;
	    }
}
.slick-dots {
    position: absolute;
    bottom: 30px;
    display: block;
    width: 100%;
    padding: 0;
    list-style: none;
    text-align: center;
    	@media(max-width: 768px) {
    		bottom: 25px;
	    }

    	li {
		    position: relative;
		    display: inline-block;
		    width: 20px;
		    height: 20px;
		    margin: 0 10px;
		    padding: 0;
		    cursor: pointer;
		    border-radius: 13px;
		    border: 2px solid white;
			    @media(max-width: 768px) {
		    		width: 16px;
		    		height: 16px;
		    		border: 1px solid white;
			    }

		    button {
			    font-size: 0;
			    line-height: 0;
			    box-shadow: none;
			    display: block;
			    width: 20px;
			    height: 20px;
			    padding: 5px;
			    cursor: pointer;
			    color: transparent;
			    border: 0;
			    outline: none;
			    background: transparent;

			    	&:before {
				    	font-family: slick;
					    font-size: 20px;
					    line-height: 20px;
					    position: absolute;
					    top: -1px;
					    left: -2px;
					    width: 20px;
					    height: 20px;
					    content: "•";
					    text-align: center;
					    color: transparent;
					    -webkit-font-smoothing: antialiased;
						    @media(max-width: 768px) {
					    		font-size: 17px;
					    		left: -3px;
					    		top: -2px;
						    }
				    }

			}

		    &.slick-active {
		    	button:before {
		    		opacity: 1;
				    color: $brand-secondary;
				    padding: 0px 0 3px 0px;
				    	@media(max-width: 768px) {
				    		padding: 0px 0 3px 0px;
					    }
		    	}
		    }

		}
}