/* Top section */
section {

	&.top-section-brands-information {

		&.container-fluid {
		    padding: 0;
		    // padding-top: 18px;
		    padding-bottom: 13px;

	    	.text-banner-brands-information {
			    text-align: center;
			    @include standard-font-light;
			    position: absolute;
			    top: 64px;
			    height: 472px;
			    padding: 107px 177px 0 83px;
			    z-index: 1;
			    background-color: $brand-primary;
				    @media(max-width: 1560px) {
						padding: 107px 100px 0 68px;	
					}
					@media(max-width: 1283px) {
						padding: 70px 100px 0 68px;	
					}
					@media(max-width: 1190px) {
						padding: 72px 30px 0 29px;	
					}
					@media(max-width: 1055px) {
						padding: 45px 30px 0 29px;	
					}
					@media(max-width: $screen-md) {
						top: 0;
						position: inherit;
						height: 100%;
						padding: 40px 30px 40px 43px;
					}

			    	h2 {
					    @include standard-font-grey;
					    font-size: 20px;
					    text-transform: uppercase;
					    padding-bottom: 44px;
					    margin: 0;
			    	}

			    	p {
			    		padding-bottom: 0;
			    		color: $heading-color;
			    			&.full-description {
								max-height: 0;
							    transition: max-height 0.15s ease-out;
							    overflow: hidden;
							    	&.active {
							    		max-height: 500px;
							    	}
							}
			    	}
			    	a {
			    		&.product-description-read-more {
						    font-size: 16px;
						    color: #232323;
						    text-decoration: none;
						    text-transform: uppercase;
						    font-weight: 400;
						}
			    	}
			}

			.image-top-section-right {
			    text-align: center;
			    top: 480px;
			    z-index: 1;
			    // background-size: cover;
			    background-repeat: no-repeat;
			    min-height: 552px;
			    background-position: center center;
			    position: relative;
			    	@media(max-width: $screen-md) {
						top: 0;
						background-position: right;
						float: right;
						min-height: 280px;
						background-size: cover;
					}
			}

			.image-top-section-left {
			    background-size: cover;
			    background-repeat: no-repeat;
			    min-height: 1070px;
			    background-position: center center;
			    position: relative;
			    	@media(max-width: $screen-md) {
						min-height: 438px;
					}
			}


		}

	}

}
/* END Top section */




/* Top section */
section {

	&.bottom-section-brands-information {

		&.container-fluid {
		    padding: 0;

	    	.text-banner-brands-information {
			    text-align: center;
			    @include standard-font-light;
			    position: absolute;
			    top: 215px;
			    min-height: 170px;
			    padding: 45px 28px 30px 139px;
			    z-index: 1;
			    background-color: $brand-primary;
			    	@media(max-width: 1190px) {
						padding: 30px 28px 0 127px;	
					}
					@media(max-width: 1055px) {
						padding: 19px 28px 0 118px;	
					}
					@media(max-width: $screen-md) {
						padding: 40px 30px 40px 15px;
						top: 20px;
						position: static;
						margin-top: 20px;
						height: 100%;

					}
					

			    	h2 {
					    @include standard-font-grey;
					    font-size: 20px;
					    text-transform: uppercase;
					    padding-bottom: 25px;
					    margin: 0;
			    	}

			    	p {
			    		padding-bottom: 0;
			    		color: $heading-color;
				    		@media(max-width: $screen-md) {
								padding: 0 15px;
							}
			    	}
			}

			.image-bottom-section-left {
			    text-align: center;
			    top: 383px;
			    left: 110px;
			    z-index: 1;
			    // background-size: cover;
			    background-repeat: no-repeat;
			    min-height: 835px;
			    background-position: center center;
			    position: relative;
			    	@media(max-width: $screen-md) {
						top: 0;
						left: 0;
						background-position: left;
						min-height: 365px;
					}
			}

			.image-bottom-section-right {
			    background-size: cover;
			    background-repeat: no-repeat;
			    min-height: 1155px;
			    background-position: center center;
			    position: relative;
			    z-index: 1;
			    	@media(max-width: $screen-md) {
						min-height: 445px;
						float: right;
						margin-top: 40px;
					}
			}


		}

	}

}
/* END Top section */

/* Middle section */
#homepage-static-banner {
    min-height: 520px;
    position: relative;
    font-family: 'Lato';
    margin-bottom: 36px;
    background: no-repeat;
    background-position: center center;
    max-width: 2000px;
    margin-left: auto;
	margin-right: auto;

	@media(max-width: $screen-md) {
		min-height: 320px;
		margin-bottom: 0;
		background-size: cover;
	}

    	.textual {
		    position: absolute;
		    max-width: 420px;
		    left: 50%;
		    top: 50%;
		    transform: translate(-50%, -50%);
		    -webkit-transform: translate(-50%, -50%);
		    -ms-transform: translate(-50%, -50%);
		    text-align: center;
		    font-family: 'Lato';
		    @media(max-width: $screen-md) {
				width: 100%;
			}
		}

		h1 {
		    @include standard-font-grey;
		    font-size: 20px;
		    margin-top: 0px;
		    margin-bottom: 34px;
		    text-transform: uppercase;
		    @media(max-width: $screen-md) {
				margin-bottom: 30px;
			}
		}
		p {
		    @include standard-font-grey;
		    font-size: 20px;
		    margin-bottom: 70px;
		    @media(max-width: $screen-md) {
				margin-bottom: 40px;
			}
		}

		a {
		    text-align: center;
		    display: inline-block;
		    width: 100%;
		    max-width: 325px;
		    margin: 0px auto;
		    font-size: 22px;
		    color: #232323;
		    border: 1px solid #232323;
		    padding-bottom: 18px;
		    text-transform: uppercase;
		    position: relative;
		    text-decoration: none;
		    padding: 5px 0px;

		    	&:after {
				    width: 0%;
				    height: 100%;
				    top: 0;
				    left: 0;
				    background: #232323;
				    content: '';
				    position: absolute;
				    z-index: -1;
				    transition: all 0.3s;
		    	}
		}
}
/* END Middle section */


/* Shop now section */ 
.container.interested {
    text-align: center;
    padding: 90px 0 0 0;
    	@media(max-width: $screen-md) {
			padding: 40px 0 0 0;
		}
		@media(max-width: $screen-sm) {
			padding: 20px 0 40px 0;
		}

    	p {
		    color: #232323;
		    font-size: 24px;
		    font-weight: 100;
		    font-family: "Lato", sans-serif;
		    text-transform: uppercase;
		    padding-bottom: 50px;
		    margin: 0;
		    @media(max-width: $screen-md) {
				padding-bottom: 20px;
			}

		    	&.sub-content {
				    color: #000000;
				    font-size: 16px;
				    font-weight: 100;
				    font-family: "Lato", sans-serif;
				    text-transform: none;
				    padding-bottom: 38px;
				    margin: 0;

				}
		}
		.interested-content {
			a {
			    color: #232323;
			    font-weight: 400;
			    text-transform: uppercase;
			    	&:visited {
			    		outline: none !important;
			    	}
			}
		} 
		.interested-button {
			z-index: 10;
		    text-align: center;
		    display: inline-block;
		    width: 100%;
		    max-width: 325px;
		    margin: 0px auto;
		    font-size: 22px;
		    color: #232323;
		    border: 1px solid #232323;
		    padding-bottom: 18px;
		    text-transform: uppercase;
		    position: relative;
		    text-decoration: none;
		    padding: 5px 0px;
	    	&:after {
			    width: 0%;
			    height: 100%;
			    top: 0;
			    left: 0;
			    background: #232323;
			    content: '';
			    position: absolute;
			    transition: all 0.3s;
			    z-index: -1;
	    	}
		&:hover {
			color: #fff;
			&:after {
				width: 100%;
			}
		}
		}
}

/* END Shop now section */ 
