@media(max-width: 1650px) {
	section {
		&.wrapper-homepage {
			padding-left: 70px;
			padding-right: 70px;
		}
	}
}
@media(max-width: 768px) {
	section {
		&.wrapper-homepage {
			padding-left: 30px;
			padding-right: 30px;
		}
	}
}

#shop-columns, #bottom-shop-columns {
	padding: 40px 15px 20px 15px;
	@media(max-width: 768px) {
		padding-bottom: 0px;
		padding-top: 0px;
		padding-left: 0;
		padding-right: 0;
		overflow: hidden;
	}

	 .textual {
		text-align: center;
		text-transform: uppercase;
		padding-bottom: 60px;
		@media(max-width: $screen-sm) {
			padding-bottom: 25px;
		}
	}

	 h1 {
		font-size: 40px;
		font-weight: 400;
		margin: 0px;
		line-height: 0.7;

		@media(max-width: $screen-sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	@media(max-width: 768px) {
		 h1 {
			line-height: 1;
			font-size: 20px;
		}
	}

 h1 .top {
	font-weight: 300;
}

 .col-sm-6 .inner {
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 720px;
	background-position: center center;
	position: relative;
}

@media(max-width: 768px) {
	 .col-sm-6 {
		margin-bottom: 20px;
	}
	 .col-sm-6 .inner {
		min-height: 320px;
	}
}

 a {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	 -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;}

 a:hover {
	background-color: rgba(0,0,0,0.4);
}

 h2 {
    @include standard-font-heading;
    position: absolute;
    display: inline-block;
    top: 0;
    margin: 0;
    width: 100%;
    max-width: 423px;
    padding: 60px 50px 20px 20px;
    font-size: 20px;
    background-color: $brand-secondary;
    text-transform: uppercase;

	@media(max-width: 768px) {
		font-size: 16px;
		font-weight: 100;
		max-width: 230px;
		width: 230px;
		padding-right: 0;
		padding-left: 20px;
		padding-top: 15px;
		padding-bottom: 15px;
	}

}

@media(max-width: 768px) {
	.no-left-padding {
		padding-left: 15px;
	}
}


 a:hover h2 {
	color: $heading-color;
}
}
// END Bottom shop columns on homepage
// Top shop columns on homepage
#shop-columns, #top-shop-columns {
	padding-top: 40px;

	@media(max-width: 768px) {
		padding-bottom: 0;
		padding-top: 20px;
		overflow: hidden;
	}

	 .textual {
		text-align: center;
		text-transform: uppercase;
		padding-bottom: 60px;
		@media(max-width: $screen-sm) {
			padding-bottom: 25px;
		}
	}

	 h1 {
		font-size: 20px;
		font-weight: 400;
		margin: 0px;
		line-height: 0.7;
		@media(max-width: $screen-sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	@media(max-width: 768px) {
		 h1 {
			line-height: 1;
			font-size: 20px;
		}
	}

 h1 .top {
	font-weight: 300;
}

 .col-xs-12 .inner {
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 720px;
	background-position: center center;
	position: relative;
}

@media(max-width: 768px) {
	 .col-xs-12 {
		// margin-bottom: 25px;
		margin-bottom: 0px;
	}
	 .col-xs-12 .inner {
		min-height: 320px;
	}
}

 a {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	-webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;}

 a:hover {
	background-color: rgba(0,0,0,0.4);
}

 h2 {
    @include standard-font-heading;
    position: absolute;
    display: inline-block;
    top: 0;
    margin: 0;
    width: 100%;
    max-width: 423px;
    padding: 60px 50px 20px 20px;
    font-size: 20px;
    background-color: $brand-secondary;
    text-transform: uppercase;
	@media(max-width: 768px) {
		font-size: 16px;
		font-weight: 100;
		max-width: 230px;
		width: 230px;
		padding-right: 0;
		padding-left: 20px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
}


 a:hover h2 {
	color: $heading-color;
}
}
