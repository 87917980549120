@mixin input-styling {
	color: $text-primary;
	border: 1px solid $text-primary;
	font-size: $input-font-size;
	font-weight: 300;
	margin: 0 0 35px 0;
	@media(max-width: $screen-md) {
		margin: 0 0 20px 0;
	}
}

@mixin input-placeholder {
	color: $text-primary;
	font-size: $input-font-size;
	text-transform: uppercase;
	font-weight: 300;
}

@mixin input-error {
	border: 1px solid $colour-error;
}