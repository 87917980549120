#additional-container-wrapper{
	position: relative;
	z-index: 201;
    @media(max-width: $screen-md) {
        display: none !important;
    }
}

#additional-section {
    @media(max-width: $screen-md) {
        display: none !important;
    }


    position: relative;
    z-index: 200;
    background: $brand-primary;
    text-align: left;
    @include standard-font;
    
    // padding: 5px 0 5px 0;

    .country-text {
	text-align: left;
	padding: 5px;
    }

    .free-delivery-top-section {
        text-align: center;
        padding: 5px;
    }

    .sign-in-options {
	text-align: right;
	padding: 5px;
    }

    p {
	line-height: 1;
	margin: 0;
    @media(max-width: $screen-sm) {
        padding-top: 2px !important;
    }
    }

	a {
		padding: 0;
        @include standard-font-size;
        @include font-weight(light);
        color: $text-primary;

        @media(max-width: $screen-md) {
            font-size: 10px;
        }

		transition: 150ms all;
	}
}

body.cms-home #additional-section {
	margin-bottom: 0px;
}

@media(max-width: 425px) {
        #additional-section {
            a {
                font-size: 14px;
            }
        }
}

.place-order-header {
    height: 100%;
    pointer-events: none;

    .container {
        height: 100%;
    }
    .order-textbox-header {
        position: relative;
        height: 100%;
    }
    .order-text-header {
        position: absolute;
        right: 0;
        top: -16px;
        width: 20%;
        height: 100%;
        z-index: 100;
        transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        background-repeat: no-repeat;
        pointer-events: all;
    }

    @media(max-width: 1537px) {
            .order-text-header {
                width: 21%;
            }
    }
    @media(max-width: 1465px) {
            .order-text-header {
                width: 22%;
            }
    }
    @media(max-width: 1438px) {
            .order-text-header {
                width: 24%;
            }
    }
    @media(max-width: 1286px) {
            .order-text-header {
                width: 25%;
            }
    }
    @media(max-width: 1236px) {
            .order-text-header {
                width: 26%;
            }
    }
    @media(max-width: 1189px) {
            .order-text-header {
                width: 27%;
            }
    }
    @media(max-width: 1146px) {
            .order-text-header {
                width: 28%;
            }
    }
    @media(max-width: 1106px) {
            .order-text-header {
                width: 29%;
            }
    }
    @media(max-width: 1070px) {
            .order-text-header {
                width: 30%;
            }
    }
    @media(max-width: 1035px) {
            .order-text-header {
                width: 31%;
            }
    }
    @media(max-width: 1002px) {
            .order-text-header {
                width: 32%;
            }
    }
    @media(max-width: 972px) {
            .order-text-header {
                width: 33%;
            }
    }
    @media(max-width: 943px) {
            .order-text-header {
                width: 34%;
            }
    }
    @media(max-width: 916px) {
            .order-text-header {
                display: none;
            }
    }
}