#checkout.guest-checkout {
	.field.addresses {
		display: none !important;
	}
}

#shipping, #opc-shipping_method {

	.note {
		margin-top: 0 !important;
		color: $text-primary;
	}

	.actions-toolbar {
		margin-bottom: 30px;
		color: $text-primary;
	}

	.control {
		width: 100% !important;
	}

	.field {
		width: 100%;
		margin: 0;
		clear: both;
	}

	.input-telephone, label[data-tooltip='dpd. saturday delivery'] {
		.field-tooltip {
			display: block !important;
			margin: 0;
			padding: 10px 15px;
			pointer-events: none;

			.field-tooltip-action:before {
				pointer-events: all;
				content: 'Why?';
				text-transform: uppercase;
				font-family: 'Lato', sans-serif;
				font-size: 16px;
				color: #758fb6;
			}

			.field-tooltip-content {
				min-width: 415px;
				left: auto;
				right: 0;
				top: auto;
				bottom: 60px;
				text-align: center;
				font-weight: 400;
				font-family: 'Lato', sans-serif;
				border: none;
				font-size: 16px;
				color: $text-primary;

				&.full-width-content {
					max-width: 100%;
					width: 100%;
				}

				&:before {
					display: none;
				}

				&:after {
					border-width: 25px 17.5px 0 17.5px;
					border-color: #f4f4f4 transparent transparent transparent;
					right: 20px;
					bottom: -25px;
					left: auto;
					top: auto;
				}
			}
		}
	}
	label[data-tooltip='dpd. saturday delivery'] .field-tooltip {
		    position: absolute;
		    right: 0;
		    top: 50%;
		    transform: translateY(-50%);
		    width: 100%;
		    height: 100%;
    		.field-tooltip-content {
			    max-width: 100%;
			    width: 100%;
		    	bottom: calc(100% + 10px) !important;
		    	min-width: 100%;
			}
		.field-tooltip-action {
		    display: inline-block;
		    text-decoration: none;
		    position: absolute;
		    right: 15px;
		    top: 50%;
		    transform: translateY(-50%);
			&:before {
			font-family: 'luma-icons';
			content: '\e623';
			color: $text-primary;
			}
		}
	}
}

#shipping {
	#checkout-step-shipping {
		padding-bottom: 0;


	}
	.shipping-address-items {
		padding-top: 0;
	}

	.field-select-billing .step-title {
		padding-bottom: 0;
		display: none;
	}

	.form-login {
		margin-top: 0;
	}

	.actions-toolbar {
		a {
			color: $text-color;
			margin-left: 25px;
			font-weight: 300;
		}
		button {
			@extend .alternate-button;
			color: $text-primary;
		}
	}
	padding-bottom: 0;
	.input-firstname {
		@include icon-name;
	}
	.input-lastname {
		@include icon-name;
	}
	#iosc-billing-container {
		margin: 0;
	}
	input#billing-save-in-address-book {
		display: none;
	}
	label[for='billing-save-in-address-book'] {
		@include icon-same-address;
		background-color: $brand-primary;;
		background-position: left 21px center;
		appearance: none;
		display: block;
		padding-left: 15px;
	    background-repeat: no-repeat;
	    color: $text-primary;
	    width: 100%;
	    border: none;
		min-height: 50px;
	    font-weight: 300;
	    padding-top: 17px;
	    text-transform: uppercase;
	    padding-left: 65px;
	    text-align: left;
	    margin: 0 0 30px 0 ;
		@media(max-width: $screen-sm) {
			min-height: 42px;
			padding-bottom: 25px;
		}
		span {
			color: $text-primary;
			font-weight: 300;
			font-size: 16px;
		}
	}

	input#billing-save-in-address-book:checked + label {
		background-color: $text-primary;
		color: #fff;
		span {
			color: #fff;
		}
	}
	input#billing-save-in-address-book{
		&:checked {
			background-color: $text-primary;
		}
	}

	input#billing-save-in-address-book {
		display: none;
	}
	label[for='billing-address-same-as-shipping-'], label[for='shipping-save-in-address-book'] {
		@include icon-same-address;
			background-color: #f4f4f4;
			background-position: left 21px center;
			appearance: none;
			display: block;
			padding-left: 15px;
		    background-repeat: no-repeat;
		    width: 100%;
		    border: none;
			min-height: 50px;
		    font-weight: 300;
		    padding-top: 14px;
		    text-transform: uppercase;
		    color: $text-primary;
		    padding-left: 65px;
		    margin-bottom: 35px;
			@media(max-width: $screen-sm) {
				min-height: 42px;
				padding-bottom: 15px;
				padding-top: 15px;
			}
	}

	input#billing-address-same-as-shipping-:checked + label, input#shipping-save-in-address-book + label {
		background-color: $text-primary;
		@include icon-shipping-white;
		color: #fff;
		margin-bottom: 35px;
	}
	input#billing-address-same-as-shipping-, input#shipping-save-in-address-book {
		&:checked {
			background-color: $text-primary;
		}
	}
}
input#billing-address-same-as-shipping-, input#shipping-save-in-address-book {
	display: none;
}

.shipping-address-items {
	padding: 0;
	.shipping-address-item {
		background-color: #f4f4f4;
		font-size: 16px;
		color: $text-primary;
		font-weight: 300;
		text-transform: uppercase;
		border: none;
		margin-bottom: 25px !important;
		text-align: left;
		padding: 15px !important;
		margin-top: 0px !important;
		span {
			color: $button-color;
			display: none;
		}
		&.selected-item {
			background-color: $text-primary;
			color: #fff;
		}
	}
}

.newaddress-button {
	display: none !important;
}


.newaddress-button-title .action-toggle {
	@extend .alternate-button;
	text-align: center;
	padding-top: 13px;
	color: $text-primary;
	&:after {
		display: none;
	}
}

.opc-wrapper .shipping-address-item.selected-item:after {
	display: none;
}
