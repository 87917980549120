#forgot-your-password {
	max-width: 480px;
	margin: 0 auto;
	text-align: center;
		@media(max-width: $screen-sm) {
			padding-bottom: 40px;
			margin: 0 15px;
		}

	.primary {
		width: 100%;
		max-width: 100%;
	}
	 h1 {
	 	font-size: 20px;
 		color: $text-primary;
 		margin: 40px 0 37px 0;
	 }

	 form {
	 	width: 100%;
	 	min-width: 0px;
	 	max-width: 100%;
	 }

	 .field.note {
	 	font-weight: 300;
	 	color: $text-primary;
	 }

	 button {
		@extend .standard-button;
	 }

	 input {
	 	@extend .standard-input-with-icon;
	 	max-width: 100%;
	 	width: 100%;
	 }
}
.field.email.required .control {
    width: 100%;
}
fieldset.fieldset {
    margin: 0;
}