#discount-form {
    font-size: 0;
	.payment-option-title.field.choice {
		display: none;
	}

	.field {
		margin-bottom: 0px;
	}

	input {
		border-radius:
		&::placeholder {
		color: #b9b9b9;
		}
	    border-radius: 2px 0 0 2px;
	}

	.actions-toolbar {
	    display: inline-block;
    	width: 100%;
    	max-width: 156px;
    	float: none;
    	vertical-align: middle;

    	@media (max-width: $screen-xs-max) {
    		max-width: none;
    	}
	}

	.primary {
	    float: none;
	    width: 100%;
	}

	.payment-option-inner {
		width: calc(100% - 157px);
		display: inline-block;
		float: none;
		vertical-align: middle;

		@media (max-width: $screen-xs-max) {
			width: 100%;
		}
	}

	button.action-apply {
		@extend .alternate-button;
		background-color: #f4f4f4;
	    border-radius: 0px 2px 2px 0px;
	}
	button.action-cancel {
		@extend .alternate-button;
	    border-radius: 0px 2px 2px 0px;
	}
}

.payment-option-title.field.choice {
	display: none;
}
.opc-payment-additional {
	padding: 0 !important;
}

#discount-code {
	@include icon-voucher;
}
