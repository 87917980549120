body.customer-account-createpassword {

	#reset-password {

		max-width: 450px;
		margin: 0px auto;

		.field {
			width: 100% !important;
			margin: 0 !important;
		}

		.fieldset {
			margin: 0 !important;
		}

		.control {
			margin: 0;
			width: 100%;
		}

		form {
			min-width: 0px;
			width: 100%;
		}
		.fieldset {
			margin: 0 0 25px 0;
		}
		input {
			@extend .standard-input;
		}
		button {
			@extend .standard-button;
		}
		
	}



		.password input {
			// @include icon-password
			// padding-left: 65px !important;
		}

		
		input#password-confirmation {
			// @include icon-password-confirm
			// padding-left: 65px !important;
		}
}