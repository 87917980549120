.copyright-footer {
	background-color: $brand-primary;
	padding: 30px;
	border-top: 1px solid $text-primary;
	text-align: center;
  @media(max-width: $screen-md) {
    border-top: none;
    padding: 20px 0 20px 0;
  }

	small.copyright {
    @include standard-font-size;
    color: map-get($colours, text-primary);
    font-weight: map-get($weight, medium);
    background-color: $brand-primary;
    letter-spacing: 0px;
    @media(max-width: $screen-md) {
      font-size: 10px;
      font-weight: 100;
     }
	}

  .copyright-footer-divider {
    display: inline-block;
    padding: 0 10px;
      @media(max-width: $screen-md) {
        padding: 0 5px;
      }
  }
  .copyright-footer-divider-first {
    display: inline-block;
    padding: 0 10px;
    @media(max-width: $screen-md) {
      display: none;
    }
  }
  .copyright-footer-text {
    display: inline;
    @media(max-width: $screen-md) {
      display: block;
    }
  }
}
