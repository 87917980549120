body.sales-order-print {
  #footer-section, #navigation-section, #search-section, #additional-container-wrapper {
    display: none;
  }

  a.logo {
  	display: block;
  	margin: 0 auto;
  	img {
  		margin: 0 auto;
  	}
  }
}