body {
	&.account {
		#maincontent {
			.actions-toolbar {
				a {
					&.action {
						&.back {
							display: none;
						}
					}
				}
			}
			.order-history-block {
			    text-transform: uppercase;
			    font-size: 16px;
			    letter-spacing: 1px;

			    	.block-content {
			    		padding: 0px 25px;
			    			&.no-order {
			    				border: none;
			    				padding: 0;
			    				text-transform: initial;
			    			}
			    	}
			    	.table-wrapper {
			    		border-bottom: none;
			    	}

			    	tr {
			    		border-bottom: 1px solid #5d5d5d;
			    		&:last-child {
			    			border-bottom: none;
			    		}
			    	}
			    	th {
			    		font-weight: 400;
					    border: none !important;
					    padding: 25px 0 25px 0 !important;
			    	}

			    	td {
			    		font-weight: 300;
					    border: none !important;
					    padding: 25px 0 25px 0 !important;

					    &.col {
					    	&.id {
					    		font-weight: 400;
					    		a {
					    			color: black;
					    		}
					    	}
					    	&.actions {
					    		font-weight: 400;
					    		a {
					    			color: black;
					    		}
					    	}
					    }
			    	}

			    	.order-products-toolbar {
			    		&.toolbar {
			    			&.bottom {
			    				display: none;
			    			}
			    		}
			    	}
			}

			.select-order-mobile {
				.select-field {
					position: relative;
				}
				.icon {
					background-repeat: no-repeat;
				    width: 25px;
				    height: 25px;
				    background-repeat: no-repeat;
				    background-image: url(../images/icon-set/return-icon.svg);
				    background-size: 23px 23px;
				    position: absolute;
				    left: 15px;
				    top: 50%;
				    -webkit-transform: translateY(-50%);
				    transform: translateY(-50%);
				}
				select {
					margin-bottom: 30px;
					text-transform: uppercase;
					padding-left: 65px;

						background-repeat: no-repeat;
					    background-image: url(../images/icon-set/dropdown-arrow-icon.svg);
					    background-size: 20px 20px;
					    background-position: right 20px center;

					    color: #5d5d5d;
					    border: 1px solid #5d5d5d;
					    font-weight: 300;
					    height: 50px;
					    max-width: 100%;
					    width: 100%;
					    display: block;
					    font-size: 16px;
					    background-repeat: no-repeat;
					    font-family: "Lato", sans-serif;
					    border-radius: 2px;

					    	&:focus {
					    		border-width: 2px;
					    		outline: none;
					    		box-shadow: none;
					    	}
				}

				button {
					border: none;
					box-shadow: none;

						width: 100%;
					    max-width: 100%;
					    border: none;
					    height: 50px;
					    @include standard-font-light;
					    background-color: $brand-primary;
					    text-transform: uppercase;
					    text-decoration: none;
					    box-shadow: none;
					    letter-spacing: 1px;
					    border-radius: 2px;
				}
			}
		} 
	} 
}


