.page-wrapper {
	background-color: $brand-secondary;
	height: auto !important;
}

.panel {
	display: none;
}

.desktop-header {
	display: block;
}

.mobile-header {
	display: none;
}

@media(max-width: $screen-md) {
	.desktop-header {
		display: none;
	}
	.mobile-header {
		display: block;
	}
	#navigation-section-sticky-wrapper {
		display: none;
	}
}

#header-section {
	
	padding-top: 16px;
	@media(max-width: $screen-md) {
		padding-top: 0;
	}
		
	&.header-section--is-sticky {
	    position: fixed;
	    top: 0;
	    width: 100%;
	    z-index: 100;
	    background-color: #fff;	
	}

	@media(max-width: $screen-md) {
		display: flex;
    	flex-wrap: nowrap;
    	-webkit-box-align: center;
    	align-items: center;
	}

	.header.content {
		display: none;
	}

	#navigation-section {
		position: relative;
	    z-index: 101 !important;
	    background-color: white;
	}
	.menu-icon {
		display: inline-block;
	}
	.menu-icon.initiate-search:first-child {
		margin-right: 80px;
	}
	.menu-icon img {
		display: block;
    	margin: 0px auto;
	}
	.desktop-header-icon {
	    width: 30px;
	}
	.menu-icon span {
	    display: block;
	    text-align: center;
	    text-transform: uppercase;
	    padding-top: 13px;
	    font-size: 12px;
	    color: #232323;
	    font-family: 'Lato';
	    @media(max-width: $screen-lg) {
	    	display: none;
	    }
	}
	.logo {
	    text-align: center;
	    display: inline-block;
	    padding-top: 26px !important;
	    float: none;
	    width: 100%;
	    max-width: 100%;
	    margin-bottom: 15px;
	}
	.logo img {
	    display: block;
	    margin: 0px auto;
	    max-width: 210px;
	}
	.right {
		.menu {
	    	margin-left: 0px;
	    	float: none;
		}
		.top {
			padding-bottom: 5px;
		    text-align: right;
		    line-height: 24px;
		}
		.basket {
			text-align: center;
			float: none;
		}
		.basket:focus {
			box-shadow: none;
			outline: 0;
		}
	}

	.menu-icon:first-child {
	    margin-right: 80px;
	}
	.menu-icon {
	    display: inline-block;
	}
}




.minicart-wrapper {
    position: absolute;
    top: 0;
    right: 15px;
    z-index: 700;
    display: inline-block;
    float: right;

    .action {
    	&.showcart {
    		.counter {
    			&.qty {
				    background: transparent;
				    color: $heading-color;
				    height: auto;
				    float: left;
				    font-size: 13px;
				    margin: 0 5px 0 0;
				    padding: 0;

				    position: absolute;
				    top: 6px;
				    left: 50%;
				    transform: translateX(-50%);
				}
    		}
    	} 
    }

    .action.showcart:before {
    	content: none;
    }
    .action.showcart.active:before {
    	content: none;
    }
}

.minicart-wrapper {
	.action.showcart {
		.text {
		    display: block;
		    position: relative;
		    width: auto;
		    height: auto;

		    text-align: center;
		    text-transform: uppercase;
		    padding-top: 13px;
		    font-size: 12px;
		    color: #232323;
		    font-family: 'Lato';
		    @media(max-width: $screen-lg) {
		    	display: none;
		    }
		}
	}

	.block-minicart {
	    border: 1px solid #cac9ce;
	    padding: 25px;
	    letter-spacing: 1px;
	    overflow-y: scroll;
	    overflow-x: hidden;
	    box-shadow: none;
	    right: 0 !important;
	    top: calc(100% + 34px) !important;
	    max-height: 600px;

	    .block-title {
	    	display: none;
	    }
	    .block-content {
	    	max-height: 800px;
	    }
	    .action{
	    	&.close {
			    background-repeat: no-repeat;
			    background-image: url(../images/icon-set/close-cross.png);
			    width: 32px;
			    height: 32px;
			    right: 25px;
			    top: 21px;
			}
			&.close:before {
			    display: none;
			}
	    }
	    .items-total {
		    float: none;
		    width: 100%;
		    display: block;
		    text-transform: uppercase;
		    font-size: 16px;
		    font-weight: 300;
		    color: #5d5d5d;
		    text-align: center;
		    padding: 0 0 25px 0;
		    border-bottom: 1px solid #5d5d5d;
		    margin: 0 0 25px 0;

		    	.count {
		    		font-weight: 300;
		    	}
		}
		.minicart-items-wrapper {
		    padding: 0;
		    margin: 0;
		    display: block;
		    background: none;
		    position: static;
		    border: none;
		    float: none;
		    height: auto !important;
		}
		.product-item {
		    border: none !important;
		    padding-top: 0;
		    a {
		    	outline: none !important;
		    }
		}
		.product-item img {
		    margin-bottom: 0;
		}
		.product-image-photo {
		    border-radius: 0px;
		    border: none;
		}
		.product-item-details {
			padding-left: 175px;
			.product-item-name {
				    text-align: right;
				    -webkit-hyphens: none;
				    -ms-hyphens: none;
				    hyphens: none;
				    line-height: 1.3;
				    font-size: 16px;
				    font-weight: 300;
				    margin: 0 0 20px 0;
				    a {
				    	color: #5d5d5d;
				    }
				}
			}
		.details-qty {
			&.qty {
				label {
				    display: none;
				}
			}
		}
		.product{
			&.actions {
				float: none;
				margin: 0;
				width: 100%;
				.primary {
					display: none !important;
				}
				.secondary {
					float: left;
					margin-top: 19px;
				}
			}
		}
		.primary {
			margin-left: 0 !important;
			margin-right: 0 !important;
		}
		.action {
			&.edit {
				display: none;
			}
			&.delete {
				background-repeat: no-repeat;
			    background-image: url(../images/icon-set/remove-cross.png);
			    width: 18px;
			    height: 18px;
			}
			&.delete:before {
				display: none;
			}
		}


	}

}

.label {
    display: inline;
    padding: .2em .6em .3em;
    font-size: 75%;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
}

.product-image-photo {
    margin-right: 0;
    margin-bottom: 25px;
    border: 1px solid #dbdbdb;
    position: static;
    margin-left: 0;
    width: 100% !important;
}

#mini-cart {
	.product-image-container {
	    height: auto;
	    width: 100% !important;
	}
}

#minicart-content-wrapper {
	.product-image-photo {
	    padding-left: 0 !important;
	    padding-right: 0 !important;
	}
	.product-item-details {
		text-align: left;
		.product-item-name {
			margin-bottom: 5px;
		}
	}
	.details-qty{
		&.qty {
		    text-align: left;
		    position: relative;
		    margin-top: 15px;

		    label {
		    	&label{
		    		border-radius: 3px;
				    color: inherit;
				    padding: 0 10px 0 0;
				    text-align: left;
				    width: auto;
		    	}
		    }

		    input {
		    	border: 1px solid #5d5d5d;
			    width: 50px;
			    font-size: 16px;
			    padding: 0;
			    display: inline-block;
			    height: 40px;
			    width: 50px;
			    outline: none;
			    margin: 0;
			    border-radius: 2px;
			    box-shadow: none;
			    left: 0;
			    top: 0;
			    background-color: #fff;
			    z-index: 10;
		    }
		    button {
	    	    background-repeat: no-repeat;
			    background-image: url(../images/icon-set/tick.png);
			    text-indent: -999px;
			    top: 0;
			    right: 0;
			    background-size: 25px;
			    background-position: center center;
			    overflow: hidden;
			    width: 80px;
			    position: absolute;
			    height: 40px;
			    border-radius: 0 2px 2px 0;

		        width: 100%;
			    max-width: 100%;
			    border: none;
			    color: #5d5d5d;
			    height: 50px;
			    background-color: #f4f4f4;
			    text-transform: uppercase;
			    font-size: 16px;
			    font-weight: 300;
			    text-decoration: none;
			    box-shadow: none;
			    letter-spacing: 1px;
			    border-radius: 2px;

		    }
		}
	}
}