.category-stackers {
	.category-container.container {
		@media(max-width: 767px) {
			padding: 0 15px 0 15px;
		}
	}

	#category-list {
		.breadcrumbs {
		    padding: 0;
		}
		.sub-cat-col {
			padding-bottom: 38px;
				.cat-col-inner {
					transition: all 0.3s;
					display: block;
					height: 100%;
					width: 100%;

					img.subcategories-image {
						&:hover {
						 	background-color: rgba(0, 0, 0, 0.4);
						 }
					}
					 
				}
		}
		.sub-category-name {
			position: absolute;
		    display: block;
		    top: 0;
		    margin: 0;
		    width: 300px;
		    max-width: 300px;
		    padding-left: 20px;
		    padding-right: 50px;
		    padding-top: 30px;
		    color: #232323;
		    font-size: 16px;
		    font-weight: 100;
		    font-family: "Lato", sans-serif;
		    background-color: #ffffff;
		    padding-bottom: 20px;
		    text-transform: uppercase;
		    @include line-growth-animation;
		}
	}
}