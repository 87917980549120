#payment {

	display: none !important;

	#checkout-step-payment {
		margin: 0;
	}

	.payment-group {
		.step-title {
			display: none;
		}
	}

		.payment-note {
			padding-bottom: 30px;
			.padlock-icon {
				width: 28px;
				height: 28px;
				margin: 0px auto;
				@include icon-ssl;
			}
			font-size: 10px;
			text-align: center;
			.payment-note-text {
				padding-top: 10px;
			}
		}

		.field {
			padding: 0;
			border: none;
		}
		.payment-method-content {
			display: none;
		}
		.payment-method-title {
			a {
				color: $button-color;
			}
		}
		label {
			white-space: normal;
			font-family: 'Lato';
			cursor: pointer;
			img {
				display: none;
			}
			background-color: #f4f4f4;
			color: $brand-primary;
			display: block;
			width: 100%;
			min-height: 50px;
			text-align: left;
			font-size: 16px;
			font-weight: 300;
			border-radius: 0;
			padding-top: 15px;
			padding-left: 50px;
			text-transform: uppercase;
			@media(max-with: $screen-sm) {
				min-height: 40px;
			}
		}
		label[for='sagepaysuiteform'] {
			@include icon-credit;
		    background-repeat: no-repeat;
    		background-position: center left 10px;
    	}
    	label[for='paypal_express'] {
    		@include icon-paypal;
		    background-repeat: no-repeat;
    		background-position: center left 10px;
    		a.action-helper {
    			float: right;
    			padding-right: 7px;
    		}
    	}
    	label[for='cashondelivery'] {
    		@include icon-phone;
		display: none !important;
    		background-repeat: no-repeat;
    		background-position: center left 10px;
    	}
		input {
			display: none;
		}
		input:checked + label {
			background-color: $brand-primary;
			color: #fff;
			a {
				color: #fff;
			}
		}

		input#paypal_express:checked + label[for='paypal_express'] {
			@include icon-paypal-white;
    	}

    	input#sagepaysuiteform:checked + label[for='sagepaysuiteform'] {
			@include icon-credit-white;
    	}

	}
