.additional-attributes-wrapper.table-wrapper {
	color: $text-primary;
	padding-top: 15px;
	border-bottom: 1px solid $text-primary;
	table {
		width: 100%;
	}
	th, td {
		font-size: 16px;
		color: $text-primary;
		text-transform: uppercase;
		font-weight: 300;
	}
}