.product-info-main, .quickview-product-info-main {
	.box-tocart {
		position: relative;
	}
	input.input-text.qty {
		width: 85PX;
		height: 100%;
		position: absolute;
		bottom: 0;
		left: 0;
		margin: 0;
		text-align: center;
		z-index: 10;
		@media(max-width: $screen-sm) {
			width: 55px;
		}
		border-radius: 0;
		display: block;
		margin: 0;
		border: 1px solid $text-primary;
		border-right: none;
		box-shadow: none;
	    color: $text-primary;
	}
	.control {
		margin-left: 0;
		height: 100%;
	}
	.action.primary.tocart {
		font-size: 16px;
		font-weight: 400;
		height: 50px;
		background-color: $button-color;
		color: $text-primary;
		padding: 0;
		border: none;
		border-radius: 0px;
		width: 100%;
		display: block;
		text-transform: uppercase;
		margin: 0 0 30px 0;
		padding: 0 0 0 85px;
		letter-spacing: 1px;
		&:hover {
			opacity: 0.8;
		}
		@media(max-width: $screen-sm) {
			padding: 0 0 0 55px;
		}
		@media(max-width: 767px) {
			height: 40px;
		}
		&.out-of-stock {
			padding-left: 0;
		}
	}
}