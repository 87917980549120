body.customer-account-login {
	.nav-sections {
		display: none;
	}
	@media(max-width: $screen-sm) {
		.container.main-content-container {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

.my-account-heading-container {
	h1 {
		@extend .standard-title;
		padding: 30px 0 42px 0;
		margin: 0;
		@media(max-width: $screen-sm) {
			border-bottom: none;
			padding: 25px 0 10px 0;
		}
	}
}

#login {
	max-width: 550px;
	width: 100%;
	margin: 0px auto;
	border: 1px solid #232323;
	@media(max-width: $screen-md) {
		border-left: none;
		border-right: none;
		border-bottom: none;
	}
	letter-spacing: 1px;
	padding-top: 35px;

	.pslogin-block {
		margin: 0;
		.pslogin-buttons ul {
			margin-bottom: 5px;
			li {
				margin-bottom: 0;
			}
		}
	}

	.pslogin-block.pslogin-register .pslogin-buttons {
		width: 100%;
	}

	.pslogin-block .pslogin-buttons{
		ul {
			li {
				width: 31.7%;
			    margin-right: 2.4%;
    			height: auto;
    				&:last-child {
					margin: 0;
				}
			}
		}
	}

	.pslogin {
		ul li {
			width: 31%;
			margin-right: 2.4%;
			&:last-child {
				margin-right: 0;
			}
		}
	}

	div.mage-error[generated] {
	    margin-top: 7px;
	    margin-bottom: 18px;
	}

	input {
		@extend .standard-input-with-icon;
	}

	input#firstname {
		@include icon-name;
	}

	input#lastname {
		@include icon-name;
	}

	.password input {
		@include icon-password
	}

	.confirmation input {
		@include icon-password-confirm
	}

	.email input {
		@include icon-email;
	}

	.control {
		margin: 0;
		width: 100%;
	}

	.remind:after {
		display: none;
	}

	label {
		display: none;
	}

	.login-container {
		padding: 0 30px 0 30px;
		@media(max-width: $screen-md) {
			padding: 0 0px 0 0px;
		}
		.fieldset {
			margin: 0;
		}
		.fieldset:after {
			display: none;
		}
	}

	.field {
		margin: 0;
	}

	.login-block {
		display: none;
		&.active {
			display: block;
		}
	}

	.form.create.account.form-create-account {
		min-width: 0;
	}

	.block-customer-login {
		float: none;
		clear: none;
		background: none;
		width: 100%;
	}

	.customer-login-new {
		clear: none;
		float: none;
		.form-create-account {
			width: 100%;
			input#email_address {
				// @include icon-email;
			}
		}
	}

	.social-login {
		padding: 0 0 35px 0;
		@media(max-width: $screen-md) {
			padding: 0 0 0px 0;
		}

		.pslogin-spacer.pslogin-clearfix {
			display: none;
		}
		h2 {
			font-size: 16px;
			margin: 0;
			padding: 0 0 60px 0;
			text-transform: uppercase;
			font-weight: 300;
			color: #232323;
			text-align: center;
			@media(max-width: $screen-md) {
				padding: 0 0 30px 0;
				font-weight: normal;
			}
		}
	}

	.seperator {
		height: 1px;
		background-color: $text-primary;
		width: 100%;
		position: relative;
		margin: 0 0 60px 0;
		text-transform: uppercase;

		@media(max-width: $screen-md) {
			margin: 0 0 45px 0;
		}

		.text {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			padding: 0 25px;
			background: #fff;
			font-size: 16px;
			color: #232323;
			margin-top: -3px;
			text-transform: uppercase;
		}
	}

	.actions-toolbar {
		width: 100%;
		@media(max-width: $screen-md) {
			margin: 20px 0 0 0;
		}

		.primary {
			width: 100%;
			margin: 0 0 10px 0;
		}

		.secondary {
			font-size: 16px;
			text-transform: uppercase;
			color: $button-color;
			width: 100%;
			text-align: right;
			font-weight: map-get($weight, medium);
			padding-bottom: 55px;
			a {
				color: $text-primary;
			}
		}

		button {
			@extend .standard-button;
		}
	}
	.login-stockist {
		text-align: center;
		&__title {
			font-size: 16px;
			margin: 0;
			padding: 0 0 60px 0;
			text-transform: uppercase;
			font-weight: 300;
			color: #232323;
			text-align: center;
			@media(max-width: $screen-md) {
				padding: 0 0 40px 0;
			}
		}
		&__link {
			a {
				@extend .standard-button-link;
			}
		}
	}
}