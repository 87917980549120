@mixin line-growth-animation {

	&:after {
		position: absolute;
		content: '';
		height: 1px;
    	width: 0px;
    	bottom: 12px;
    	transition: width .25s ease-in;
    	left: 20px;
    	background-color: #232323;		    
		}

		&:hover {
			&:after {
				width: calc(100% - 40px);
			}
		}

		span {
			font-weight: map-get($weight, medium);
		}
}
