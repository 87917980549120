html {
	touch-action: manipulation;
}

body {
	letter-spacing: 1px;
	font-size: $standard-font-size;
	font-family: $font-family-lato !important;
	color: map-get($colours, text-primary) !important;
	background-color: $brand-secondary;
	overflow-x: hidden;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	
	a {
		outline: none !important;
		&:active, &:visited {
			outline: none !important;
		}
		img {
			outline: none;
		}
	}
}
body, div {
    display: block;
    margin: 0px;
    padding: 0px;
}
