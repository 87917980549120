#footer-section {
letter-spacing: 1px;
#mailing-list-footer {
	text-align: center;
	background: $brand-primary; 
	@media(max-width: $screen-md) {
		padding: 0px 0px 0px;
	}

	#newsletter-error {
		display: none !important;
	}

	.margin-top-and-bottom {
		padding: 36px 15px 37px 15px;
		border-bottom: 1px solid $text-primary;
		@media(max-width: $screen-md) {
			margin-bottom: 0;
			margin-top: 0;
			padding: 20px 15px 28px 15px;
			border-bottom: none;
		}
	}	

	h1 {
		@include standard-font-heading;
		@include font-weight(medium);
		text-transform: uppercase;
		margin: 0 0 35px 0;
		@media(max-width: $screen-md) {
			font-size: 16px;
			margin: 0 27px 20px 27px;
		}
	}
	
	form {
		@include reset-style;
		@media(max-width: $screen-md) {
			margin-top:37px;
		}
	}

	.form-content-wrapper {
		max-width: 780px;
		margin: 0 auto;
		font-size: 0;
		position: relative;
	}

	input {
		@include standard-font-size;
		font-family: $font-family-lato;
		height: 50px;
		background-color: #fff;
		display: inline-block;
		text-align: center;
		margin: 0;
		outline: none;
		box-shadow: none;
		border: none;
		border-radius: 0;
		vertical-align: middle;
		font-weight: 300;
		border: 1px solid transparent;
		padding: 0 175px 0 15px;

		@media(max-width: $screen-md) {
			padding: 10px 10px 10px 10px;
			width: 100%;
		}
		@media(max-width: $screen-md) {
			padding: 10px 72px 10px 10px;
			width: 100%;
		}
		@media(max-width: 495px) {
			padding: 10px 113px 10px 10px;
			text-align: center;
		}

		&.mage-error {
			border-color: $colour-error;
		}

		&::placeholder {
			font-size: $standard-font-size;
			color: $heading-color;
			letter-spacing: 1px;
			text-transform: uppercase;
			font-weight: 300;
		}
	}

	button {
		height: 48px;
		display: inline-block;
		width: 100%;
		max-width: 150px;
		text-transform: uppercase;
		background-color: $brand-primary;
		@include standard-font-regular;
		box-shadow: none;
		border: 1px solid $text-primary;
		border-radius: 0;
		vertical-align: middle;
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		background-repeat: no-repeat;
		background-position: center center;

		&:after {
		    width: 0%;
		    height: 100%;
		    top: 0;
		    left: 0;
		    background: #232323;
		    content: '';
		    position: absolute;
		    transition: all 0.3s;
		    z-index: -1;
    	}

    	&:hover {
			color: #fff;
			&:after {
				width: 100%;
			}
		}

		@media(max-width: $screen-md) {
			// height: 40px;
			// max-width: 100px;
			height: 48px;
			max-width: 114px;
		}
		@media(max-width: 344px) {
			// height: 40px;
			// max-width: 100px;
			height: 48px;
			max-width: 96px;
		}
	}
}
}