
.minicart-wrapper .block-minicart {
	border: 1px solid #919191;
	padding: 25px;
	letter-spacing: 1px;
	overflow-y: auto;
	overflow-x: hidden;
    box-shadow: none;
    color: $text-primary;

	.product.options {
	text-align: right;
	font-size: 16px;
	font-weight: 300;
	.toggle {
		display: none;
	}
	.label, .values {
		display: block;
		font-size: 12px;
		text-align: right;
		float: none;
		font-weight: 300;
		margin: 0 0 5px 0;
		padding: 0;
		color: $text-primary;
	}
	.values {
		font-weight: normal;
	}
	.content {
		display: block !important;
	}

}


    .free-delivery {
    	display: none;
    	text-align: center;
    	font-size: $standard-font-size;
    	padding: 0 0 15px 0;
    }

	.block-content {
		max-height: 650px;
		@media(max-height: 900px) {
			max-height: 600px;
		}
		@media(max-height: 800px) {
			max-height: 450px;
		}
		@media(max-width: 700px) {
			max-height: 350px;
		}
	}

	&:before {
		display: none;
	}

	&:after {
		display: none;
	}

	.product-image-photo {
		border-radius: 0px;
		border: none;
	}

	.price-container .price{
		font-size: 16px;
		font-weight: 300;
		color: $text-primary;
	}

	.subtotal {
		text-align: center;
	    border-top: 1px solid $text-primary;
        padding: 30px 0 20px 0;
        line-height: 1;
        margin: 0 0 25px 0;

		&:after {
			clear: both;
			content: '';
			display: block;
		}


		.price-including-tax {
			&:before {
				content: 'ORDER TOTAL (' attr(data-label) ') ' ': ';
				display: block;
				font-size: 16px;
				font-weight: 600;
				float: left;
			}
			.price {
				float: right;
			}
		}

		.price-excluding-tax {
			clear: both;
			padding: 25px 0 0 0;
			&:before {
	    		content: 'ORDER TOTAL (' attr(data-label) '): ';
    			font-size: 16px;
    			font-weight: 300;
    			float: left;
    			color: $text-primary;
			}
			.price {
				float: right;
				font-size: 16px;
				font-weight: 300;
				color: $text-primary;
			}
		}

        .label {
        	font-weight: 600;
        	&:after {
        		display: none;
        	}
        }
	}

	.amount .price-wrapper:first-child .price {
	   font-size: 16px !important;
 	   font-weight: 300 !important;
	}

	.action.viewcart {
		color: #232323;
		font-size: 16px;
		text-transform: uppercase;
		text-align: center;
		display: block;
		font-weight: 500;
		text-decoration: none;
		float: none;
		margin: 0px 0 0 0;
		border: 1px solid #232323;
		padding: 15px 0 12px 0;
    	margin-bottom: 30px;
    	margin-top: 30px;
	}

	.primary {
		margin-left: 0 !important;
		margin-right: 0 !important;
		button {
			@extend .standard-button;
		}
	}

	.subtotal .label {
		text-align: left;
		color: $text-primary;
		display: none;
		font-size: 16px;
	    color: $text-primary;
	    padding: 0;
	    font-weight: 600;
	    text-transform: uppercase;
	    margin: 0 0 5px 0;
		&:after {
			display: none
		}
	}

	.action.close {
		// @include icon-close;
		width: 32px;
		height: 32px;
	    right: 25px;
	    top: 21px;
		&:before {
			display: none !important;
		}
	}
	.product.actions {
		float: none;
		margin: 0;
		width: 100%;
	}

	.action.delete {
		&:before {
			display: none;
		}
		// @include icon-close;
		width: 18px;
		height: 18px;
	}

	.price-container {
	    margin-top: 13px;
	    text-align: right;
	    &.amount {
	    	float: none;
	    	margin: 0;
	    	font-weight: 300;
	    	display: block;
	    	&:after {
	    		clear: both;
	    		content: '';
	    	}
	    }
	}


	.product.actions {
		.primary {
			display: none !important;
		}
		.secondary {
			float: left;
		    margin-top: -20px;
		}
	}

	.product-item-details .price {
		font-weight: 300 !important;
	}

	.product-item {
		border: none !important;
		padding-top: 0;
		padding-right: 0;
		img {
			margin-bottom: 0;
		}
		a {
			outline: none !important;
		}
	}

	#minicart-content-wrapper {
		.details-qty.qty {
			text-align: left;
			position: relative;
			input {
				color: $text-primary;
			    border: 1px solid $text-primary;
			 	width: 50px;
			    font-size: 16px;
			    padding: 0;
			    display: inline-block;
			    height: 40px;
			    width: 50px;
			    outline: none;
			    margin: 0;
			    border-radius: 0px;
			    box-shadow: none;
			    left: 0;
			    top: 0;
			    background-color: #fff;
			    z-index: 10;
	    	}
	    	button {
				// @extend .alternate-button;
				//@include icon-tick;
				text-indent: -999px;
				top: 0;
				right: 0;
				background-size: 25px;
				background-position: center center;
				overflow: hidden;
				width: 80px;
				position: absolute;
				height: 40px;
				border-radius: 0 2px 2px 0;
				&:hover {
					opacity: 0.8 !important;
				}
			}
		}

	}
	.details-qty.qty label {
		display: none;
	}
	.action.edit {
		display: none;
	}
	.items-total {
		.count {
			font-weight: 300;
		}
		float: none;
		width: 100%;
		display: block;
		text-transform: uppercase;
		font-size: 16px;
		font-weight: 300;
		color: $text-primary;
		text-align: center;
		padding: 0 0 25px 0;
		border-bottom: 1px solid $text-primary;
		margin: 0 0 25px 0;
	}
	.product-item-details {
		padding-left: 175px;
		.product-item-name {
		    text-align: right;
		    hyphens: none;
			line-height: 1.3;
		    font-size: 16px;
		    font-weight: 300;
		    margin: 0 0 20px 0;
		    a {
		    	color: $text-primary;
		    }
		}
	}
	.minicart-items-wrapper {
		@include reset-style;
		height: auto !important;
	}
	.paypal.checkout {
		display: inline-block;
		width: 100%;
		height: 50px;
		margin-top: 0;
		background-color: #f2f2f2;
		background-position: center center;
		//@include icon-paypal-payments;
		background-size: contain;
		input {
			opacity: 0;
			width: 100%;
			height: 100%;
		}
	}

	.seperator-heading {
		height: 1px;
		background-color: $text-primary;
		position: relative;
		margin: 35px 0 25px 0;
		span {
	    	display: block;
		    position: absolute;
		    left: 50%;
		    top: 0;
		    -webkit-transform: translate(-50%);
		    transform: translate(-50%, -50%);
		    background-color: #fff;
		    padding: 0px 25px;
		    text-transform: uppercase;
		    font-size: 16px;
		    font-weight: 300;
		}
	}
}
.minicart-wrapper.show-minicart-shipping .free-delivery {
	display: block;
}
