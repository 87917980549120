.message {
	&.success {
	    color: #5d5d5d;
	    background-color: $brand-primary;
	    font-size: 16px;
	    margin-bottom: 25px;
	}
}

body.account .page.messages {
	margin: 0;
}

.message.info {
	background-color: #f4f4f4;
	font-size: 16px;
	margin-bottom: 25px;
	color: #5d5d5d;
	> *:first-child:before {
		content: '';
		@include icon-warning;
	    background-size: 20px 20px;
	    height: 27px;
	    background-position: center left 10px;
	}
}

.message.error {
	color: #ff0000;
	background-color: #f4f4f4;
	font-size: 16px;
	margin-bottom: 25px;
	> *:first-child:before {
		content: '';
		@include icon-error;
	    background-size: 20px 20px;
	    height: 27px;
	    background-position: center left 10px;
	}
}

.message.notice {
	background-color: #f4f4f4;
	color: #5d5d5d;
	font-size: 16px;
	margin-bottom: 25px;
	> *:first-child:before {
		content: '';
		@include icon-warning;
	    background-size: 20px 20px;
	    height: 27px;
	    background-position: center left 10px;
	}
}

.message.success {
	color: #5d5d5d;
	font-size: 16px;
	margin-bottom: 25px;
	background-color: #f4f4f4;
	> *:first-child:before {
		content: '';
		@include icon-success;
	    background-size: 20px 20px;
	    height: 27px;
	    background-position: center left 10px;
	}
}
