#checkout-loader {
	.loader {
		img  {
			width: 32px;
			height: 32px;
		}
	}
}

body.checkout-index-index {
	#contact-footer {
		border-top: 1px solid $brand-primary;
		padding-top: 35px;
		padding-bottom: 20px;
	}
 }
#checkout {
	input {
		font-family: 'Lato';
		@extend .standard-input-with-icon;
		outline: none;
		letter-spacing: 1px;
	}
	select {
		@extend .standard-select;
	}

	// input[type='checkbox'] {
	// 	height: 25px;
	// 	width: 0px;
	// 	display: inline-block;
	// 	appearance: none;
	// 	padding: 0px;
	// 	border: 1px solid $brand-primary;
	// }

	.newaddress-save-button {
		@extend .standard-button;
	}

	#cc_0_search_input, #cc_1_search_input {
		@include icon-postcode;
	}

	.input-street-one input {
		@include icon-street;
	}

	input[class^="input-street"] {
		@include icon-street;
	}

	.input-city {
		@include icon-city;
	}

	.input-company {
		@include icon-company;
	}



	.input-region {
		@include icon-state;
	}

	.input-postcode {
		@include icon-postcode;
	}

	.input-telephone {
		@include icon-phone;
	}

	#customer-email-fieldset {
		input {
			@include icon-email;
		}
		#customer-password {
			@include icon-password;
			margin-top: 0px;
		}
	}

	.field {
		margin: 0 0 25px 0;
		width: 100%;
		@media(max-width: $screen-md) {
			width: 100% !important;
		}
		/* Have to do this because of the plugin ¬_¬ */
		margin-right: 0 !important;
		margin-right: 0;
		&.iosc-half {
			width: calc(50% - 12.5px);
			display: inline-block;
			@media(max-width: $screen-md) {
				width: 100% !important;
				margin-right: 0;
			}

			&.iosc-end {
				margin-left: 25px;
				@media(max-width: $screen-md) {
					margin-left: 0;
				}
			}
		}
		&.addresses {
			margin-bottom: 0;
		}
	}

	fieldset.field {
		margin: 0 0 0 0;
	}

	.field.iosc-half.street {
		width: 100%;
		margin-bottom: 0;
	}

	.table-checkout-shipping-method {
		.col-carrier {
			display: none;
		}

		input {
			padding: 0;
			appearance: none;
			height: 25px;
			width: 25px;
			background-color: #fff;
			color: $brand-primary;
			&:checked {
				background-color: $brand-primary;
			}
		}

		thead {
			display: none;
		}
		td {
			vertical-align: middle;
			padding: 20px;
			font-size: 16px;
			font-weight: 300;
		}
	}

	.select-field-wrapper {
		.icon {
			@include icon-country;
			height: 28px;
			width: 28px;
		}
		select {
			@extend .standard-select-with-icon;
		}
	}

	input[type='checkbox'] {
	}

	.title-number {
		display: none;
	}

	.checkout-shipping-address {
		.label {
			display: none;
		}

	}

	.step-title {
		border: none;
		font-size: 16px;
		text-transform: uppercase;
		padding: 0 0 50px 0;
		display: block;
		text-align: center;
		@media(max-width: $screen-sm) {
			font-size: 16px !important;
			padding-bottom: 40px;
		}
	}

	.field-select-billing {
		.step-title {
			padding-bottom: 25px;
		    letter-spacing: 1px;
		}
	}

	.opc-block-summary {
		background-color: #fff;
		padding: 0;
		color: $text-primary;
	}

	#opc-shipping_method {
		margin-bottom: 25px;
		@media(max-width: $screen-sm) {
			margin-bottom: 0;
		}
	}

	#iosc-comment {
		display: none;
	}

	#opc-shipping_method, #payment, #iosc-summary, #checkout-step-shipping, #express-checkout-options {
		padding: 25px;
		border: 1px solid $text-primary;
		border-radius: 2px;
		@media(max-width: $screen-sm) {
			border: none;
			border-bottom: 1px solid $text-primary;
			padding: 40px 0px;
		}
	}

	#payment {
		padding-bottom: 0;
	}

	#express-checkout-options {
		margin-bottom: 25px;
	}

	div.iosc-place-order-container .iosc-place-order-button {
		@extend .standard-button;
		font-weight: 600;
		background: $brand-primary;
		box-shadow: none;
		border: none;
	   	@media(max-width: $screen-md) {
			width: 50%;
			margin-left: auto;
			margin-right: auto;
		}
		@media(max-width: $screen-xs) {
			width: 100%;
		}
		@media (max-width: $screen-xs-max) {
			font-size: 20px;
			padding: 15px;
			height: auto;
		}
	}
}
