body {
    &.cms-new-arrivals {
        .breadcrumbs {
            display: none;
        }
    }
}






.breadcrumbs {
    padding: 40px 15px 40px 15px;
    max-width: 1600px;
    .items {
    	@include standard-font-grey;
    	font-weight: 300;

        .item.home {
            display: none;
        }

    	li {
    		&.item {
                font-weight: 300;
                &:last-child {
                    font-weight: 300;
                }

                &[class*='category'] {
                    font-weight: 300;
                     & ~ [class*="category"] {
                        font-weight: 300;
                    }
                }

    			&.home {
		    		font-weight: 300;
		    	}

		    	&.product {
		    		strong {
		    			font-weight: 300;
		    		}
		    	}
    		}
    	}
    }
    .item:not(:last-child):after {
    	content: '/';
	    margin: 3px 1px;
	    font-size: 16px;
    }

}

.cms-corporate-gifting {
    .breadcrumbs {
        display: none;
    }
}