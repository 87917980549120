.container.footer {
  max-width: 1600px;
}


#footer-section {
  footer {
    padding: 38px 0px;
    background: $brand-primary;
    border: none;

    @media(max-width: $screen-md) {
      padding: 0px 15px;
    }

    h2 {
      @include standard-font-size;
      font-weight: map-get($weight, medium);
      text-transform: uppercase;
      line-height: 1;
      margin: 0 0 40px 0;
      @media(max-width: $screen-md) {
        margin: 0 0 25px 0px;
      }
    }
    ul {
        margin: 0;
        padding: 0;
        font-weight: 300;
        text-transform: none;
        list-style: none;
        li {
            font-size: $standard-font-size;
            margin: 0 0 22px 0;
          a {
            font-size: $standard-font-size;
            color: $text-primary;
	          font-family: $font-family-lato;
          }
        }
    }
  }

    .footer-block {
      border-left: 1px solid $text-primary;
      .footer-inner {
        max-width: 200px;
        margin: 0 auto;
        text-align: left;
        height: 100%;
        @media(max-width: $screen-md) {
          margin-left: 0;
          max-width: 100%;
        }
      }
      &:first-child {
        border-left: none;
      }

    @media(max-width: $screen-md) {
      border-left: none;
      padding-left: 70px;

        &:first-child {
          border-left: none;
          padding-left: 70px;
        }
    } 

    @media(max-width: $screen-md) {
      border-left: none;
      padding-left: 0;

        &:first-child {
          border-left: none;
          padding-left: 0;
        }
    } 

  }

}

@media (max-width: $screen-md) {
  #footer-section {
    footer {
      .footer-block {
          height: 40px;
          overflow: hidden;
          padding-top: 10px;
          border-bottom: 1px solid #232323;
          padding-right: 0 !important;

          &:first-child {
            border-top: 1px solid #232323;
          }
        
        .title {
          position: relative;
          z-index: 2;
        }

          &:before {
            right: 15px;
           content: url(../images/icon-set/expand-plus.png);
            position: absolute;
            z-index: 1;
            }

            &.active {
              height: auto;
              &:before {
                content: url('../images/icon-set/desktop/CLOSE-MINUS-DESKTOP.svg');
                width: 15px;
              }
            }
        }
      } 
    } 
  }

.page-footer {
    display: none;
}
