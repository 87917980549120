#landing-page-container {
    width: 100%;
    max-width: 100%;
    padding: 0;
}

body {
	&.page-layout-landing-page {
		
		#header-section {
			position: relative !important;
		}	

		#header-section-sticky-wrapper {
			border-bottom: 1px solid #232323;
		}

		#mailing-list-footer {
			display: none;
		}
		
		@media(max-width: $screen-md) {
			.text-box-title {
				padding: 0 30px;
			}
		}

		#navigtion-section-sticky-wrapper {
			display: none;
		}

		#search-section-sticky-wrapper {
			display: none;
		}


		footer {
			display: none;
		}
		.copyright-footer {
			border-top: none;
		}
		#header-section {
			.menu-icon {
				&.initiate-search {
					display: none;
				}
			}
			.right {
				.top {
					display: none;
				}
			}
		}
		#navigation-section-sticky-wrapper {
			display: none;
		}
		.navigation-section {
			display: none;
		}
		.order-textbox-header {
			display: none;
		}

		
		.title-landing-page {
		    padding: 90px 0 90px;
		    background-color: #eae4e1;
		    border-left: none;
		    text-align: center;
		    font-weight: 600;
		    line-height: 1.3;
		    margin: 0px 0 40px 0;
		    	@media(max-width: $screen-sm) {
					padding: 40px 0 40px;
				}
		    	@media(max-width: 340px) {
					padding: 40px 30px 40px;
				}
		    	h2 {
				    color: #232323;
				    font-size: 24px;
				    font-weight: 100;
				    font-family: "Lato", sans-serif;
				    color: #000000;
				    margin: 0;
				    padding-bottom: 50px;
				    	@media(max-width: $screen-sm) {
							padding-bottom: 30px;
							font-size: 20px;
						}
				}
		}
		.enter-site-button {
		    text-align: center;
		    z-index: 10;
		    display: inline-block;
		    max-width: 100%;
		    width: 432px;
		    margin: 0px auto;
		    font-size: 24px;
		    color: #232323;
		    border: 1px solid #232323;
		    padding-bottom: 18px;
		    text-transform: uppercase;
		    position: relative;
		    text-decoration: none;
		    padding: 9px 0px;
		    font-weight: 400;
			    &:after {
				    width: 0%;
				    height: 100%;
				    top: 0;
				    left: 0;
				    background: #232323;
				    content: '';
				    position: absolute;
				    transition: all 0.3s;
				    z-index: -1;
		    	}
			&:hover {
				color: #fff;
				&:after {
					width: 100%;
				}
			}
			    @media(max-width: $screen-sm) {
					font-size: 20px;
					width: 432px;
				}
		}
		
		.our-brands-information-page {
			position: relative;
			.padding{
				@media(max-width: 992px) {
					padding-left: 0;
				}
				@media(max-width: $screen-sm) {
					padding-left: 0;
				}
			}
			.brand-information-item {
				position: relative;
				margin: 0 0 60px 0;
					@media(max-width: $screen-md) {
						margin: 0 0 20px 0;
					}
					@media(max-width: 767px) {
						margin: 0 15px 20px 15px;
					}
				img {
					width: 100%;
					@media(max-width: 767px) {
						height: 450px;	
					}
				}
				a {
					color: $heading-color;
					font-size: 20px;
					width: 100%;
					height: 100%;
					display: block;
						@media(max-width: $screen-sm) {
							font-size: 16px;
						}
				}
				&.london-brand-inforamtion-item {
					position: absolute;
					top: 55px;
					right: 0;
					width: 50%;
					z-index: 1;
					@media(max-width: $screen-md) {
						position: relative;
						top: 0;
						right: 0;
						width: 100%;
						padding: 0 0 0 15px;	
					}
					@media(max-width: 767px) {
						padding: 0 0 0 15px;	
					}
					.title {
						@media(max-width: $screen-md) {
							padding: 12px 0px 12px 30px;	
						}
						
					}
				}
				&.londonco-brand-information-item {
					margin-top: 280px;
					height: 730px;
					position: absolute;
					z-index: 2;
						@media(max-width: 1440px) {
							margin-top: 120px;
						}
						@media(max-width: $screen-md) {
							margin-top: 0;
							height: 100%;
							position: relative;
						}
				}
				&.dulwich-brand-information-item {
					margin-top: -355px;
					@media(max-width: 1600px) {
						margin-top: -250px;
					}
					@media(max-width: $screen-md) {
						margin-top: 0;
					}
					img {
						&.dulwich-image {
							width: 100%;
							height: 775px;
								@media(max-width: 1540px) {
									height: 690px;
								}
								@media(max-width: 1199px) {
									height: 440px;
								}
								@media(max-width: $screen-md) {
									height: 500px;
								}
								@media(max-width: $screen-sm) {
									height: 500px;
								}
								@media(max-width: 767px) {
									height: 450px;	
								}
								@media(max-width: 500px) {
									height: 428px;	
								}
						}
					}
				}
				&.jacob-jones-brand-information-item {
					margin-top: -115px;
					@media(max-width: 1600px) {
						margin-top: -125px;
					}
					@media(max-width: $screen-md) {
						margin-top: 0;
					}
					img {
						&.jacob-jones-image {
							width: 100%;
							height: 615px;
								@media(max-width: 1540px) {
									height: 510px;
								}
								@media(max-width: 1199px) {
									height: 510px;
								}
								@media(max-width: $screen-md) {
									height: 625px;

								}
								@media(max-width: 767px) {
									height: 450px;	
								}
								@media(max-width: 425px) {
									height: 360px;	
								}
						}
					}
				}
				&.bottom-divider {
					@media(max-width: $screen-md) {
						display: none;
					}
				}
			}

			.title {
				position: absolute;
				top: 0;
				left: 0;
				background-color: #fff;
				display: block;
				max-width: 305px;
				width: 100%;
				padding: 15px;
				line-height: 1;
				text-transform: uppercase;
				font-weight: 400;
				@media(max-width: $screen-md) {
					padding: 12px;
				}
			}

			.container {
				&.divider-bottom {
				    background-color: transparent;
				    max-width: 100%;
				    height: 100%;
				    margin-top: 0;
				    	@media(max-width: $screen-md) {
							background-color: transparent;
						    margin-top: -229px;
						    height: 100%;
						}

						.padding-right {
							@media(max-width: 992px) {
								padding-right: 0;
							}
							@media(max-width: $screen-sm) {
								padding-right: 0;
							}
						}
						.padding-left {
							@media(max-width: 992px) {
								padding-left: 0;
							}
							@media(max-width: $screen-sm) {
								padding-left: 0;
							}
						}
				}
			}


		}

		.container.landing-page-divider {
		    text-align: center;
		    height: 350px;
		    background-color: #eae4e1;
		    max-width: 1680px;
		    margin-left: 0;
		    	@media(max-width: 1600px) {
					height: 228px; 
				}
				@media(max-width: $screen-md) {
					position: relative;
					top: 345px;
				}
		}


	.columns {
		.column.main {
		    padding-bottom: 0;
		}
	} 

	.mobile-header {
		@media(max-width: $screen-md) {
			border-bottom: 1px solid #7f7f7f;
		}
		.initiate-search {
			@media(max-width: $screen-md) {
				display: none;
			}
		}
		.mobile-minicart-content {
			@media(max-width: $screen-md) {
				display: none;
			}
		}
		#mobile-menu {
			@media(max-width: $screen-md) {
				display: none;
			}
		}
	} 

		
	#header-section {
		.logo {
			text-align: center;
			display: inline-block;
			padding-top: 18px !important;
			float: none;
			width: 100%;
			max-width: 100%;
			margin-bottom: 15px;
		}
	} 

		}

	}


		

	
	