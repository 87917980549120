#modal-5, #modal-6 {
    width: 1080px;
    max-width: 100%;

    @media (max-width: $screen-md-max) {
        padding: 0 20px;
    }

    .md-content {
        padding: 60px 165px;
        position: relative;

        @media (max-width: $screen-md-max) {
            padding: 40px 80px;
        }

        @media (max-width: $screen-xs-max) {
            padding: 60px 0 0;
        }

        &> div {
            line-height: 1;
            padding: 0;
        }
    }

    .md-close {
        cursor: pointer;
        position: absolute;
        top: 60px;
        right: 60px;
        width: 45px;
        height: 45px;
        margin: 0;
        background: none;
        border: none;
        box-shadow: none;
        @include icon-close;

        @media (max-width: $screen-md-max) {
            right: 20px;
            top: 40px;
            width: 30px;
            height: 30px;
        }

        @media (max-width: $screen-xs-max) {
            top: 15px;
        }
    }
}

#modal-6 iframe {
    min-height: 500px;
}


.md-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 50%;
    max-width: 750px;
    min-width: 320px;
    height: auto;
    z-index: 2000;
    visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.md-show {
    visibility: visible;
}

.md-overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    visibility: hidden;
    top: 0;
    left: 0;
    z-index: 1000;
    opacity: 0;
    background: rgba(143,27,15,0.8);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
}

.md-show ~ .md-overlay {
    opacity: 1;
    visibility: visible;
}

/* Content styles */
.md-content {
    background: #fff;
    position: relative;
    border-radius: 3px;
    margin: 0 auto;
}

.md-content iframe {
    width: 100%;
    max-width: 100%;
}

.md-content h3 {
    margin: 0;
    padding: 0.4em;
    text-align: center;
    font-size: 2.4em;
    font-weight: 300;
    opacity: 0.8;
    color: #242424;
    background-color:#fff;
    border-radius: 3px 3px 0 0;
}

.md-content > div {
    padding: 0px 40px 30px;
    margin: 0;
    font-weight: 300;
    font-size: 1.15em;
    padding-left: 0px;
    padding-right: 0px;
   }

.md-content > div p {
    margin: 0;
    padding: 10px 0;
}

.md-content > div ul {
    margin: 0;
    padding: 0 0 30px 20px;
}

.md-content > div ul li {
    padding: 5px 0;
}

.md-content button {
    display: block;
    margin: 0 auto;
    margin-top: 15px;
    font-size: 0.8em;
}

/* Effect 5: fall */
.md-effect-5.md-modal {
    -webkit-perspective: 1300px;
    -moz-perspective: 1300px;
    perspective: 1300px;
}

.md-effect-5 .md-content {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-transform: translateZ(600px) rotateX(20deg); 
    -moz-transform: translateZ(600px) rotateX(20deg); 
    -ms-transform: translateZ(600px) rotateX(20deg); 
    transform: translateZ(600px) rotateX(20deg); 
    opacity: 0;
}

.md-show.md-effect-5 .md-content {
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    -webkit-transform: translateZ(0px) rotateX(0deg);
    -moz-transform: translateZ(0px) rotateX(0deg);
    -ms-transform: translateZ(0px) rotateX(0deg);
    transform: translateZ(0px) rotateX(0deg); 
    opacity: 1;
}

@-webkit-keyframes slit {
    50% { -webkit-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -webkit-animation-timing-function: ease-out;}
    100% { -webkit-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@-moz-keyframes slit {
    50% { -moz-transform: translateZ(-250px) rotateY(89deg); opacity: .5; -moz-animation-timing-function: ease-out;}
    100% { -moz-transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

@keyframes slit {
    50% { transform: translateZ(-250px) rotateY(89deg); opacity: 1; animation-timing-function: ease-in;}
    100% { transform: translateZ(0) rotateY(0deg); opacity: 1; }
}

