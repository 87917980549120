.product-info-wrapper {
	.price-final_price {
		span.price:after {
			content: 'excl VAT';
			font-size: 14px;
			 margin: 0 0 0 15px;
			font-weight: normal;
		}
	}
	.old-price {
		.price:after {
			display: none;
		}
	}
}