.category-container.container {
	padding-top: 0;
}

.category-cms p {
	margin: 0;
}

.category-range-hero {
	img {
		max-width: 2000px;
		display: block;
		margin: 0 auto;
		width: 100%;
	}
}

.category-cms h1 {
	@extend .standard-title;
	margin-top: 35px;
}

.category-cms

.filter.block {
	border: none !important;
}



.page-products {

	.columns {
		padding: 0;
		display: block;
	}

	.toolbar-segment-colum {
		@media(max-width: $screen-lg) {
			padding: 0;
		}
	}

	.row.products.list.items {
		margin-left: -15px;
		margin-right: -15px;
		@media(max-width: $screen-lg) {
			margin-left: -30px;
			margin-right: -30px;
		}
		@media(max-width: $screen-sm) {
			margin-left: -25px;
			margin-right: -25px;
		}
		display: flex;
		flex-wrap: wrap;
	}

	.column.main {
		min-height: 0;
	}



	.category-image {
		display: none;
	}
	
	.sorter {
		#sorter {
		    display: inline-block;
		    width: 100%;
		    background-repeat: no-repeat;
		    background-image: url(../images/icon-set/plus-expand.png);
		    background-size: 15px 15px;
		    padding-top: 3px;
		    @media(max-width: $screen-lg) {
		    	max-width: 100% !important;
		    }
		}
	}

	.toolbar {
		select#sorter {
		    border: 1px solid #a9a9a9;
		    background-color: white;
		    text-transform: none;
		    height: 40px;
		    margin: 0;
		    @include standard-font;
		    max-width: 160px;
		    /* padding: 10px; */
		    line-height: 1;
		    text-transform: uppercase;
		    // background-size: 15px 26px;
		    background-position: center right 15px;
		    box-shadow: none;
		    cursor: pointer;
		    -webkit-appearance: none;

		}
}



	.category-container {
		.column.main {
	    	padding: 0;
		}
	}
	.sorter {
	    width: 100%;
	    text-align: right;
	    margin-top: -8px;
	    max-width: 160px;
	    right: 0;
	    @media(max-width: $screen-md) {
			width: 100%;
		    max-width: calc(50% - 10px);
		    bottom: -22px;
		    right: 0;
		    margin: 0;
		    top: auto;    	
		}
		@media(max-width: $screen-md) {
			bottom: 5px;
		}
	    a {
	    	display: none;
	    }
	}
}

.toolbar-segment-wrapper {
    position: relative;
    width: 100%;
    @media(max-width: $screen-md) {
    	position:static;
    }
}

.toolbar-products {
    margin-bottom: 40px;
    text-align: center;
    position: static;
    	@media(max-width: $screen-md) {
			width: 100%;
			margin-bottom: 0;
		}
}

.products-grid {
	.product-item-name {
		hyphens: none;
	    text-align: center;
	    margin: 0px auto;
	    word-wrap: normal;
	    padding: 37px 0 0 0;
	    // min-height: 66px;

	    a {
	    	@include standard-font-grey;
	    }
	}
}


.product {
	.product-item-info {
		width: 100%;
			&:hover {
				background: none;
			    box-shadow: 0px;
			    margin: 0;
			    padding: 0;
			}
		.primary-image {
			width: 100%;
			transition: opacity 500ms;
		}
		.secondary-image {
			position: absolute;
		    top: 0;
		    width: 100%;
		    opacity: 0;
		    left: 0;
		}
	}
}
.item {
	&.product {
		&.product-item {
			padding-right: 15px;

		}
	} 
}

.products.wrapper.grid.products-grid {
		.product-item-info {
			&:hover {
				background: none;
				box-shadow: 0px;
				margin: 0;
				padding: 0;
				.list-add-to-cart-button {
					//display: block;
					display: none;
				}
				.secondary-image {
					opacity: 1;
				}
				.primary-image {
					opacity: 0;
				}
			}
			img {
				border: none;
			}

			.primary-image, .secondary-image {
				transition: opacity 500ms;
			}

			.secondary-image {
				position: absolute;
				top: 0;
				width: 100%;
				opacity: 0;
				left: 0;
			}

		}
	}




.page-products #layered-filter-block .filter-button-wrapper {
    position: absolute;
    //bottom: -37px;
    bottom: 39px;
    right: 201px;
    max-width: 160px;
    width: 100%;
    max-width: 160px;
    position: absolute;
    text-align: left;
    z-index: 100;
    @media(max-width: $screen-md) {
		left: 0;
		width: calc(50% - 10px);
		max-width: none;
		//bottom: -105px;
		bottom: 31px;
    }
}

.filter .filter-subtitle {
    border: 1px solid $text-primary;
    font-size: 16px;
    font-weight: 300;
    color: $text-primary;
    padding: 11px 10px;
    background-repeat: no-repeat;
    text-transform: uppercase;
    background-size: 15px 15px;
    background-position: top 11px right 15px;
    background-image: url(../images/icon-set/desktop/EXPAND-PLUS-DESKTOP.svg);
    display: block;
}

.products-grid
 {
	
	.product-item-grid-item {
		padding: 0 !important;
	}

	 .product-item-info {
		background: none !important;
		box-shadow: none !important;
		box-shadow: 0 !important;
		margin: 0 !important;
		padding: 0 !important;
		border: none !important;
		position: relative !important;
		// flex: 1;
		// display: flex;
		//flex-direction: column;
			// @media(max-width: 768px) {
			// 	flex: 1;
			// 	display: flex;
			// 	flex-direction: column;
			// }
	}

	.product-item-details {
		flex: 1;
		display: flex;
		flex-direction: column;
	}

	.product-item-photo {
		flex: none;
	}

	.product-item-inner {
	    position: static !important;
	    margin: 0 !important;
	    padding: 0 !important;
	    box-shadow: none;
	    border: none;	
	    display: block !important;
	    opacity: 1 !important;
	    height: auto !important;
	    overflow: visible !important;
	    width: 100% !important;
	}

}

h1 {
	&.title-special-offer {
    	font-size: 24px;	
    	font-weight: 300;
    	margin: 0;
    	@media(max-width: $screen-md) {
    		margin-bottom: 100px;
    	}
	}
}

.category-cms {
	margin-bottom: 0;
}

.page-layout-1column .filter.block {
	border: none !important;
}
