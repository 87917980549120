// Designers Feature text
.container.designer {
    text-align: center;
    max-width: 100%;
    padding-top: 20px;
    @media(max-width: 800px) {
		padding: 48px 26px 0;
	}
	@media(max-width: 767px) {
		padding: 0 26px 0;
	}
}


.designers-slider {
	opacity: 0;
	transition: 250ms all;
	&.fade-in-active {
		opacity: 1;
	}
}

.border-top {
    text-align: center;
    padding: 40px 0 40px 0;
    border-top: 1px solid black;
    text-transform: uppercase;
    @include standard-font-regular;
    @media(max-width: 767px) {
		font-size: 20px;
	}
}
.designer-image {
	background-size: cover;
    background-repeat: no-repeat;
    min-height: 770px;
    background-position: center center;
    position: relative;
    @media(max-width: 767px) {
		min-height: 380px;
	}
}
.designer-content {
	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding-bottom: 54px;
		margin: 0;

		&.sub-content {
			@include standard-font-light;
			text-transform: none;
			padding-bottom: 0;
			margin: 0;
			
		}
	}
	h2 {
		@include standard-font-heading-light;
		margin: 0;
		text-transform: uppercase;
		line-height: 4;
		@media(max-width: $screen-md) {
			font-size: 20px;
			line-height: 1.3;	
			margin: 40px 0;
		}

		span {
			font-weight: 400;
		}
	}

}	
// END Designers Feature text



// Bottom Our designer loves
#catalogues-columns-designer, #catalogues-page-columns-designer {
	padding-top: 35px;
	color: $text-primary;
	@media(max-width: 767px) {
		padding-bottom: 25px;
		padding-left: 15px;
		padding-right: 15px;
		overflow: hidden;
	}

	@media(max-width: 767px) {
		.no-left-padding {
			padding-left: 15px;
		}
		.no-right-padding {
			padding-right: 15px;
		}
	}

	.row {
		&.catalogue-bottom {
			padding-bottom: 38px;
			@media(max-width: 767px) {
				padding-bottom: 0;
			}
		}
	}
	 .textual {
		text-align: center;
		text-transform: uppercase;
		padding-bottom: 60px;
		@media(max-width: $screen-sm) {
			padding-bottom: 25px;
		}
	}

	 h1 {
		font-size: 40px;
		font-weight: 400;
		margin: 0px;
		line-height: 0.7;
		@media(max-width: $screen-sm) {
			padding-left: 15px;
			padding-right: 15px;
		}
	}

	@media(max-width: 767px) {
		 h1 {
			line-height: 1;
			font-size: 20px;
		}
	}

 h1 .top {
	font-weight: 300;
}

 .inner {
	background-size: cover;
	background-repeat: no-repeat;
	min-height: 508px;
	background-position: center center;
	position: relative;
	margin: 0 10px;
	@media(max-width: $screen-sm) {
		min-height: 400px;
	}
}

@media(max-width: 767px) {
	 .col-sm-4 {
		margin-bottom: 25px;
	}
	 .col-sm-4 .inner {
		min-height: 320px;
	}
}

 a {
	position: absolute;
	left: 0;
	top: 0;
	display: block;
	width: 100%;
	height: 100%;
	 -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;}

 a:hover {
	background-color: rgba(0,0,0,0.4);
}

a {
	color: $text-primary;
}

 h2 {
    position: absolute;
    display: inline-block;
    top: 0;
    margin: 0;
    width: 332px;
    max-width: 100%;
    padding-left: 20px;
    padding-right: 15px;
    padding-top: 30px;

    a {
    	color: $text-primary;
    }
		
    background-color: $brand-secondary;
    padding-bottom: 20px;
    text-transform: uppercase;
    font-size: 16px;
    @media(max-width: 767px) {
		padding-top: 15px;
		padding-bottom: 15px;
		width: 370px;
   		max-width: 100%;
	}

	span {
		@include standard-font-heading;
		font-size: 16px;
	}
}


 a:hover h2 {
	color: $heading-color;
}
}
// END Bottom Our designer loves