/*########################
  Typography
########################*/
// Font styles
@mixin standard-font {
	color: $text-color;
	font-size: $standard-font-size;
	font-weight: 300;
	font-family: $font-family-lato;
}

@mixin standard-font-light {
	color: $text-color;
	font-size: $standard-font-size;
	font-weight: 300;
	font-family: $font-family-lato;
}

@mixin standard-font-grey {
	color: $heading-color;
	font-size: $standard-font-size;
	font-weight: 300;
	font-family: $font-family-lato;
}

@mixin standard-font-regular {
	color: $text-color;
	font-size: $standard-font-size;
	font-weight: 400;
	font-family: $font-family-lato;
}

@mixin small-standard-font-heading {
	color: $heading-color;
	font-size: $small-standard-font-size;
	font-weight: 400;
	font-family: $font-family-lato;
}

@mixin standard-heading {
	color: $text-primary;
	font-size: 24px;
}

@mixin standard-font-heading {
	color: $heading-color;
	font-size: $heading-font-size;
	font-weight: 400;
	font-family: $font-family-lato;
}

@mixin standard-font-heading-light {
	color: $heading-color;
	font-size: $heading-font-size;
	font-weight: 300;
	font-family: $font-family-lato;
}

@mixin standard-font-button {
	color: $heading-color;
	font-size: $heading-font-size;
	font-weight: 700;
	font-family: $font-family-lato;
}

@mixin standard-font-heading-large {
	color: $heading-color;
	font-size: $large-heading-font-size;
	font-weight: 300;
	font-family: $font-family-lato;
}

@mixin text-center {
	display: block;
	text-align: center;
}

@mixin standard-font-size {
	font-size: 16px;
}

$weight: (
	hairline:  100,
	thin:      200,
	light:     300,
	regular:   400,
	medium:    500,
	semibold:  600,
	bold:      700,
	black:     900
);