.sections.nav-sections {
	display: none !important;
}

// #navigation-section-sticky-wrapper {
// 	poisiton: relative;
// 	z-index: 200;
// }

#navigation-section {
	poisiton: relative;
	z-index: 200;
}

.navigation-section {
	position: relative;
	z-index: 101 !important;
	background-color: #fff;
	// border-bottom: 1px solid #919191;
	@media(max-width: $screen-md) {
		display: none;
	}
	&--is-sticky {
		position: fixed;
		width: 100%;
		top: 88px;
		@media(max-width: 1200px) {
			top: 76px;
		}
	}
	ul {
		list-style-type: none;
		text-align: center;
		padding: 0;
		margin: 0;

		li {
			display: inline-block;
			margin: 0 0px 0 0;
			text-transform: uppercase;
			padding: 0 25px 15px 25px;
			padding-bottom: 15px;
			@media(max-width: 1050px) {
				padding: 0 15px 15px 15px;
			}
			a {
				font-size: 16px;
				color: #232323;
				text-decoration: none;
				@media(max-width: $screen-lg) {
					font-size: 14px;
				}
			}
			ul {
				position: absolute;
				display: none;
				top: 100%;
				background-color: #f4f4f4;
				width: 100%;
				left: 0;
				border-top: 1px solid #919191;
				padding: 25px 0px;
				li {
					padding: 0px 25px;
				}
				@media(max-width: $screen-lg) {
					display: none;
				}
			}
			&:hover {
				ul {
					display: block;
					// @media(max-width: $screen-lg) {
					// 	display: none;
					// }
				}

			}
		}
	}

}






nav#prime-nav {
	position: static;
		ul {
		    text-align: left;
		}
}


// .sticky-wrapper.is-sticky {
// 	.level-0-submenu-wrapper {
// 	    top: 82px !important;
// 	}
// }
.toggle-mobile {
    display: none;
}
nav#prime-nav {
		.level-0 {
		    // margin: 0;
		    // padding: 0px 0px 26px 0px;
		    display: inline-block;
		    margin: 0 0px 0 0;
		    text-transform: uppercase;
		    padding: 0 25px 15px 25px;
		    padding-bottom: 15px;
		    width: auto;
		  //   	@media(max-width: 1620px) {
				// 	padding: 0px 0px 27px 0px;
				// }
				// @media(max-width: 1434px) {
				// 	padding: 0px 0px 29px 0px;
				// }
		}
		.level-0 > a {
			padding: 3px 13px 0px 5px;
			font-family: $font-family-sans-serif;
			font-size: 16px;
			font-weight: normal;
			@media(max-width: 1200px) {
				font-size: 14px;
			}

			// 	@media(max-width: 1845px) {
			// 		padding: 3px 11px 0px 5px;
			// 	}
			// 	@media(max-width: 1809px) {
			// 		padding: 3px 10px 0px 5px;
			// 		font-size: 15px;
			// 	}
			// 	@media(max-width: 1713px) {
			// 		padding: 3px 10px 0px 0px;
			// 		font-size: 15px;
			// 	}
			// 	// @media(max-width: 1680px) {
			// 	// 	padding: 3px 20px 0px 5px;
			// 	// }
			// 	@media(max-width: 1623px) {
			// 		font-size: 15px;
			// 		padding: 3px 8px 0px 0px;
			// 	}
			// 	@media(max-width: 1587px) {
			// 		font-size: 14px;
			// 		padding: 3px 8px 0px 0px;
			// 	}
			// 	@media(max-width: 1509px) {
			// 		font-size: 13px;
			// 		padding: 3px 8px 0px 0px;
			// 	}
			// 	// @media(max-width: 1494px) {
			// 	// 	padding: 3px 10px 0px 5px;
			// 	// }
			// 	@media(max-width: 1431px) {
			// 		font-size: 13px;
			// 		padding: 3px 6px 0px 0px;
			// 	}
			// 	@media(max-width: 1395px) {
			// 		font-size: 12px;
			// 		padding: 3px 6px 0px 0px;
			// 	}
			// 	// @media(max-width: 1371px) {
			// 	// 	padding: 3px 10px 0px 0px;
			// 	// }
			// 	@media(max-width: 1317px) {
			// 		padding: 3px 5px 0px 0px;
			// 	}
			// 	@media(max-width: 1299px) {
			// 		font-size: 11px;
			// 		padding: 3px 5px 0px 0px;
			// 	}
			// 	@media(max-width: 1281px) {
			// 		padding: 6px 5px 0px 0px;
			// 	}
			// 	// @media(max-width: 1249px) {
			// 	// 	padding: 3px 8px 0px 0px;
			// 	// }
			// 	// @media(max-width: 1203px) {
			// 	// 	font-size: 12px;
			// 	// }
			// 	// @media(max-width: 1140px) {
			// 	// 	padding: 6px 5px 0px 0px;
			// 	// }
			// 	@media(max-width: 1131px) {
			// 		padding: 6px 5px 0px 0px;
			// 		font-size: 10px;
			// 	}
			// 	// @media(max-width: 1113px) {
			// 	// 	font-size: 12px;
			// 	// }
			// 	// @media(max-width: 1092px) {
			// 	// 	padding: 6px 5px 0px 0px;
			// 	// }
			// 	// @media(max-width: 1063px) {
			// 	// 	padding: 6px 5px 0px 0px;
			// 	// }
			// 	@media(max-width: 1050px) {
			// 		padding: 7px 5px 0px 0px;
			// 	}
			// 	@media(max-width: 1038px) {
			// 		font-size: 9px;
			// 	}

			// 	// &:target {
			// 	// 	font-weight: 600;
   //  // 				text-decoration: none;
			// 	// }
			// 	// &:hover {
			// 	// 	font-weight: 600;
   //  // 				text-decoration: none;
			// 	// }
				

			// // @media(max-width: $screen-ipad-landscape) {
			// // 	padding: 10px 24px;
			// // }
		}

		ul {
		    //text-align: left;
		    list-style-type: none;
		    text-align: center;
		    padding: 0;
		    margin: 0;

		    	.level-0:last-of-type > a {
					@media(max-width: 1281px) {
						padding: 6px 5px 0px 0px;
					}
					@media(max-width: 1092px) {
						padding: 6px 0px 0px 0px;
					}
					@media(max-width: 1063px) {
						padding: 6px 0px 0px 0px;
					}
				}
		}

		li {
			a {
				font-size: 16px;
			    color: #232323;
			    text-decoration: none;
			    font-family: "Lato", sans-serif !important;
			    	@media(max-width: 1263px) {
						font-size: 17px;
					}
					@media(max-width: 1115px) {
						font-size: 16px;
					}

					&:hover {
						text-decoration: none;
					}
			}
		}

			

			.level-0-submenu-wrapper {
				background-color: #f4f4f4;
			    border-top: 1px solid black;
			    margin-top: 0px;
			    top: 30.8%;
			    width: 100%;
			    padding: 25px 0 13px 0 !important;
			    	.level-0-submenu {
			    		text-align: center;
			    	}
			}
			

				
		.level-1-wrapper {
		    display: inline-block;
		    padding: 0 20px 0 20px;
		    // width: 12%;
		    
		    box-sizing: border-box;
		    text-align: center;
		    	@media(max-width: 1429px) {
					padding: 0px 10px 0px 10px;
				}
		}
		.level-1 {
			display: block;
		    border-bottom: none; 
		    padding-bottom: 0px;
		    text-align: center;
		    	.cat-title {
		    		display: block;
				    border-bottom: none;
				    padding: 0;
		    	}
		}
}

section#mobile-menu {
    display: none;
		@media(max-width: 991px) {
			display: block;
		}
}
#navigation-section {
		.container {
		    max-width: 100%;
		}
}