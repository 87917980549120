#not-found {
    text-align: center;
    color: #000;
    height: 500px;

    	@media(max-width: 991px) {
			height: 400px;
		}

    	.text-wrapper {
		    position: absolute;
		    top: 220px;
		    right: 0;
		    left: 0;
		    -webkit-transform: translateY(-50%);
		    transform: translateY(-50%);

		    	@media(max-width: 991px) {
					top: 200px;
				}
		}
		h1 {
		    font-size: 27px;
		    font-weight: 300;
		    font-family: $font-family-lato;
		    margin: 0 0 75px 0;
		    line-height: 1;
		    text-transform: none;
		}
		h2 {
		    font-weight: 300;
		    font-size: 20px;
		    font-family: $font-family-lato;
		    margin: 0 0 0 0;
		    line-height: 1;
		    text-transform: none;
		    color: black;
		}
		a {
		    color: #000;
		    font-weight: 400;
		}
}