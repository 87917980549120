.product-quantity-box {
  position: relative;
}

.product-tooltip {
  z-index: 10;
  background-color: #f4f4f4;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 15px;
  width: 100%;
  color: $text-primary;
  text-align: center;
  position:absolute;
  bottom: calc(100% + 25px);
  left: 0;
  padding: 25px 0px;
  opacity: 0;
  pointer-events: none;
  &:after {
    display: block;
    content: '';
    height: 23px;
    width: 34px;
    background-image: url('../images/tooltip-arrow.png');
    background-repeat: no-repeat;
    position: absolute;
    top: 100%;
    right: 15px;
    background-position: center center;
  }
  &.active {
    display: block;
    opacity: 1;
  }
  .product-tooltip-stock {
    font-weight: 600;
  }
}
