body {
	&.cms-corporate-gifting {
		#content-container-full-width {
			max-width: 100%;
			padding-left: 0;
			padding-right: 0;
		}
		.breadcrumbs {
			display: none;
		}
	}
}

// Section with logos
.container.corporate-gifting-logos {
    text-align: center;
    padding: 40px 0 0 0;
    background-color: $brand-primary;
    max-width: 100%;

    @media(max-width: 991px) {
		padding: 20px 0 0 0;
	}

    @media(max-width: 800px) {
		padding: 48px 26px 0;
	}

	@media(max-width: 500px) {
		padding: 20px 26px 0;
	}

	img {
		&.logo {
			margin: 0;
			float: none;
			width: 1600px;
			max-width: 100%;
				@media(max-width: 1580px) {
					padding: 0 15px;
				}
				@media(max-width: 768px) {
					padding: 0;
				}
		}
	}
}
.corporate-gifting-content-logos {
	a {
		color: $heading-color;
		font-weight: 700;
		text-transform: uppercase;
	}

	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		margin: 0;
		
		strong {
			font-weight: 400;
		}

		&.sub-content {
			@include standard-font-light;
			text-transform: none;
			padding: 60px 0 40px 0;
			margin: 0;

			@media(max-width: 991px) {
				padding: 40px 0 20px 0;
			}

			@media(max-width: 500px) {
				padding: 40px 0 20px 0;
			}
		}
	}
}

section.banner {
	padding: 20px 0 0 0;
	.text-banner-our-story {
		text-align: center;
		@include standard-font-light;
		position: absolute;
		top: 70px;
		z-index: 1;

		@media(max-width: 1490px) {
			top: 20px;
		}

		@media(max-width: 1080px) {
			top: 0px;
		}

		@media(max-width: 991px) {
			position: inherit;
			top: 20px;
			padding: 0;
		}

		@media(max-width: $screen-md) {
			p {
				padding: 0 15px;
			}
		}

		h2 {
			@include standard-font-heading-light;
			color: $text-color;
			text-transform: uppercase;
			padding-bottom: 44px;

			@media(max-width: 1080px) {
				margin: 0;
			}

			@media(max-width: 991px) {
				font-size: 20px;
				padding-bottom: 20px;
			}

		}
		p {

			padding-bottom: 100px;

			@media(max-width: 1420px) {
				padding-bottom: 80px;
			}

			@media(max-width: 1080px) {
				padding-bottom: 55px;
			}

			@media(max-width: 991px) {
				padding-bottom: 30px;
			}

		}
	}
}
// Middle content section
.title-out-of-container {
    padding: 44px 0 44px;
    background-color: $brand-primary;
    border-left: none;
    text-align: center;
    font-weight: 600;
    line-height: 1.3;
    margin: 50px 0 95px 0;
    @media(max-width: 1420px) {
		padding-right: 95px;
	}
	@media(max-width: 991px) {
		padding-right: 0;
		margin: 20px 0 0 0;
		padding: 20px 26px 20px 26px;
	}

    	h2 {
    		@include standard-font-heading-light;
    		color: $text-color;
    		margin: 0;
    		padding-bottom: 38px;
    		@media(max-width: 991px) {
				padding-bottom: 20px;
				font-size: 20px;
			}
    	}
    	p {
    		@include standard-font-light;
    	}
}

.container.corporate-gifting {
    text-align: center;
    padding: 15px 0 0 0;
    @media(max-width: 800px) {
		padding: 20px 26px 40px;
	}

	.corporate-gifting-button {
		font-weight: map-get($weight, bold);
	    text-align: center;
	    z-index: 10;
	    display: inline-block;
	    width: 100%;
	    max-width: 430px;
	    margin: 0px auto;
	    font-size: 24px;
	    line-height: 1;
	    color: $text-primary;
	    border: 1px solid $text-primary;
	    text-transform: uppercase;
	    position: relative;
	    text-decoration: none;
	    padding: 15px 0 15px 0px;
    	&:after {
		    width: 0%;
		    height: 100%;
		    top: 0;
		    left: 0;
		    background: $text-primary;
		    content: '';
		    position: absolute;
		    z-index: -1;
		    transition: all 0.3s;
    	}
	  	@media(max-width: 500px) {
			max-width: 273px;
		}
		&:hover {
			color: #fff;
			&:after {
				width: 100%;
			}
		}
	}
}
.corporate-gifting-content {

	a {
		color: $heading-color;
		font-weight: 400;
		text-transform: uppercase;
	}

	p {
		@include standard-font-heading-light;
		text-transform: uppercase;
		padding: 0 0 50px 0;
		margin: 0;

		@media(max-width: 991px) {
			font-size: 20px;
			padding: 0 0 20px 0;
		}

		@media(max-width: 901px) {
			padding: 0 0 22px 0;
		}

		strong {
			font-weight: 400;
		}

		&.sub-content {
			@include standard-font-light;
			text-transform: none;
			padding: 0 0 35px 0;
			margin: 0;
			@media(max-width: 991px) {
				padding: 0 0 22px 0;
			}
		}
	}

}	

@media(max-width: 991px) {
.container.container.corporate-gifting {
	.corporate-gifting-button {
	    font-size: 20px;
	    width: 272px;
		}
	}
	
}
