.container.homepage-contact {
    text-align: center;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    padding: 27px 0;
    	@media(max-width: 768px) {
			max-width: 750px;
			padding: 20px 15px;
		}
}

.home-contact-content {
	p {
		font-size: 24px;
		font-weight: 300;
		margin: 0;
		a {
			color: $heading-color;
			font-weight: 400;
			@media(max-width: $screen-md) {
				font-weight: 600;
			}
		}
		strong {
			font-weight: 400;
			@media(max-width: $screen-md) {
				font-weight: 600;
			}
		}

		@media(max-width: 768px) {
			font-size: 16px;
			font-weight: 400;
			letter-spacing: 0;
		}

		&.next-line {
			margin: 0;
			@media(max-width: 768px) {
				margin: 0 0 17px;
			}
		}
	}
		
}