$default: #232323;
$brand-primary: #eae4e1; 
$brand-secondary: #ffffff;
$colour-error: #cc5252;
$light-grey: #e0e0e0;
$text-primary: #232323;
$text-color: #000000;
$heading-color: #232323;
$button-color: #f0ecea;
$text-signup: #2c2c2c;
$checkout-button : #f4911f;
$grey-font: #5d5d5d;

$colours: (
	default: $default,
	brand-primary: $brand-primary,
	text-primary: $text-primary,
);