
.whole-gallery-wrapper {
	position: relative;

	.loader {
		img {
			width: 32px;
			height: 32px;
		}
	}
	
	.video-button {
		position: absolute;
    	height: 50px;
    	right: 0;
    	bottom: 0;
    	width: calc(100% - 233px);
    	background-color: $brand-primary;
    	font-size: $standard-font-size;
    	z-index: 10;
    	text-align: center;
    	color: #fff;
    	font-weight: normal;
    	line-height: 3;
    	cursor: pointer;
    	text-transform: uppercase;
    	background-size: 15px 15px;
    	span {
			@include icon-video;
			padding-left: 25px;
    		background-position: left center;
    	}
    	@media(max-width: 766px) {
    		width: 100%;
    		height: 35px;
    		line-height: 2;
    	}
	}
}

.product-info-wrapper {    
    .gallery-wrapper {
        position: relative;
        @media(max-width: $screen-sm) {
            padding: 0px;
        }
        .product-social-links {
            margin: 0;
            padding: 0px;
        }

        @media(max-width: 1600px) {
            .fotorama__stage__frame .fotorama__img {
                left: 0;
                top: 0;
                transform: none;
                width: 100%;
            }
        }

        .fotorama__thumb__arr .fotorama__thumb--icon {
            position: absolute;
            left: 50%;
            margin: 0;
            top: 50%;
            transform: translate(-50%, -50%) rotate(90deg);
            display: none;
        }

        .fotorama__stage.fotorama__shadows--bottom:after, .fotorama__nav.fotorama__shadows--bottom:after  {
            display: none;
        }
        .fotorama__stage.fotorama__shadows--bottom:after, .fotorama__nav.fotorama__shadows--bottom:after  {
            display: none;
        }
        .fotorama__thumb-border {
            display: none;
        }

        .fotorama__stage.fotorama__shadows--top:before, .fotorama__nav.fotorama__shadows--top:before {
            display: none;
        }

        .fotorama__nav__frame {
            border: 1px solid transparent;
            padding: 0 !important;
            box-sizing: border-box;
            transition: 250ms all;
            border: 1px solid #d4d4d4;
            img {
            }
        }

        @media(max-width: 767px) {
            .fotorama__arr {
                display: none !important;
            }
        }

        .fotorama__thumb__arr {
            height: 50px;
            opacity: 1;
            transition: 250ms all;
            background-color: rgba(255, 255, 255, 0.45);
            &.fotorama__arr--disabled {
                opacity: 0;
            }

            &.fotorama__thumb__arr--right {
                @include icon-arrow-bottom;
                background-size: 32px 18px;
                background-position: center center;
            }

            &.fotorama__thumb__arr--left {
                @include icon-arrow-top;
                background-size: 32px 18px;
                background-position: center center;
            }
        }

        .fotorama__nav-wrap .fotorama_vertical_ratio .fotorama__img {
            transform: translate(-50%, -50%);
            left: 50%;
            height: auto;
            position: absolute;
            top: 50%;
        }

        .fotorama__nav,
        .fotorama__stage {
        }

        .fotorama__stage {
            margin-bottom: 0;
        }

        .fotorama__nav__frame--thumb  {
            margin-bottom: 30px;
        }

        .product-video {
            width: calc(100% - 30px);
            left: 30px;
            height: 100%;
        }
        .fotorama__stage__shaft {
            .fotorama__img {
                // height: auto;
                // position: static;
                // width: 100%;
                // transform: none;
                // max-height: none;
                // max-width: none;
                padding-left: 30px;
                @media(max-width: $screen-md) {
                    padding-left: 0;
                }
            }
        }

        .fotorama__nav--dots {
            margin-top: -40px;
            padding-bottom: 40px;
        }

        .fotorama__nav--dots .fotorama__nav__frame {
            height: 11px;
            width: 11px;
            border-radius: 100%;
            border: 1px solid $text-primary;
            margin-right: 10px;
            &:last-child {
                margin-right: 0;
            }

            .fotorama__dot {
               border-radius: 100%;
               border: none;
               top: 0;
               left: 0;
               width: 7px;
               height: 7px;
               margin: 1px;
            }

            &.fotorama__active .fotorama__dot {
               background-color: $text-primary;
            }
        }

        .product-addto-links {
            position: absolute;
            right: 45px;
            top: 30px;
            width: auto;
            height: 38px;
            width: 43px;
            background-image: url(../images/heart.svg);
            background-repeat: no-repeat;
            @media(max-width: 767px) {
                height: 33px;
                width: 33px;
                top: 0;
                right: 35px;
                top: 25px;
            }
            a {
                display: block;
                width: 100%;
                height: 100%;
                &:before {
                    display: none;
                }
            }
        }
    }
}