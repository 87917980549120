body {
	#maincontent {
		.checkout-success {
		    max-width: 500px;
		    margin: 0 auto;
		    display: block;
		    text-align: center;

		    	h1 {
		    		@include standard-font-heading-light;
		    	}
		    	p {
		    		margin: 0;
		    		@include standard-font;

		    		&.thanks {
		    			margin-bottom: 25px;
		    			font-size: 18px;
		    		}
		    	}
		    	.order-number {
		    		font-weight: 600;
		    	}
		    	.actions-toolbar {
		    		text-align: center;
		    		margin-top: 35px;
		    		width: 100%;
		    			.primary {
		    				float: none;
		    			}
		    			.action {
		    				font-size: 16px;
						    width: 100%;
						    background: #f4f4f4;
						    color: #5d5d5d;
						    padding: 9px 15px;
		    			}
		    	}
		}
	} 
} 