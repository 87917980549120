.category-brands {
	.category-container.container {
		
		@media(max-width: 767px) {
			padding: 0 15px 0 15px;
		}
	
	}
	
	#category-list {
		.breadcrumbs {
			display: none;
		}
		.sub-cat-col {
			padding-bottom: 30px;
			img {
				width: 100%;
			}
		}
		.sub-category-name {
			position: absolute;
		    display: block;
		    top: 0;
		    margin: 0;
		    width: 300px;
		    max-width: 300px;
		    padding-left: 20px;
		    padding-right: 50px;
		    padding-top: 30px;
		    color: #232323;
		    font-size: 16px;
		    font-weight: 100;
		    font-family: "Lato", sans-serif;
		    background-color: #ffffff;
		    padding-bottom: 20px;
		    text-transform: uppercase;
		    @include line-growth-animation;
		}
	}
}