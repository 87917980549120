body.catalog-product-view .main-content-container {
	padding-top: 15px;
	@media(max-width: $screen-sm) {
		padding-top: 0 !important;
	}

	@media(max-width: $screen-sm) {
		padding-top: 0;
		.column.main {
			display: block;
		}
	}
	.message.success {
		display: none !important;
	}

	.action.mailto.friend {
		display: none;
	}

}

@media(max-width: 767px) {
	.catalog-product-view .main-content-container {
		padding-top: 0;
	}
	.catalog-product-view .column.main {
		padding-bottom: 0;
	}
}

.product-info-wrapper {

	.product-reviews-summary {
		display: none !important;
	}

	.discontinued-notice {
		color: $text-primary;
		margin: 0 0 30px 0;
		text-align: center;
		font-size: 16px;
	}
	.stock-due-notice {
		text-transform: uppercase;
		color: $text-primary;
		margin: 0 0 30px 0;
		text-align: center;
		font-weight: 400;
	}

	#tab-label-reviews {
		display: none;
	}

	@media(max-width: 991px) {
		padding-top: 0px;
	}
	@media(max-width: 768px) {
		padding-top: 0;
		.product.info.detailed {
			margin-bottom: 0;
		}
	}

	.product-info-description-wrapper {
		font-weight: 300;
		margin: 0 0 35px 0;
		color: $text-primary;
		@media(max-width: $screen-md) {
			text-align: center;
		}
		a {
			color: $text-primary;
		}
		@media(max-width: 767px) {
			padding: 0 20px;
		}
	}

	.top-description {
		padding: 0 0 20px 0;
		text-align: left;
		@media(max-width: $screen-md) {
			text-align: center;
		}
	}

	.product-description-read-more {
		text-align: center;
		display: block;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 400;
	}
	.quickview-read-more {
		display: none;
	}

	.full-description {
		text-align: left;
		max-height: 0;
		height: 500px;
		overflow: hidden;
		@media(max-width: $screen-md) {
			text-align: center;
		}
		&.active {
		}
	}

	.product.info.detailed {
		opacity: 0;
	}

	.product-info-price {
		border: none;
		text-align: center;
		display: block;
		padding: 0;
		margin: 0;
		max-width: 100%;

		.price-box {
			display: block;
			width: 100%;
			max-width: 100%;
			// background-color: red;
			padding: 0 0 0 0;

				.special-net-price {
				    font-size: 17px;
				    color: #232323;
				    // color: red;
				    text-align: center;
				    font-weight: 300;
				    margin: 10px 0 0 0;
				}
		}

		span.price {
			font-weight: 400 !important;
			font-size: 26px !important;
			color: #000;
			@media(max-width: $screen-md) {
				font-size: 22px !important;
			}
		}

		span.old-price {
			span.price {
				font-size: 20px !important;
				text-decoration: line-through;
			}
		}

	}

	.box-tocart {
		position: relative;
	}

	.product-info-main-wrapper {
		@media(max-width: $screen-md) {
			padding: 0 15px;
		}
	}

	.product-info-main {
		padding: 30px 0 0 0;
		float: none;
		@media(max-width: 767px) {
			padding: 30px 0 0 0;
		}
			
		.page-title-wrapper {
			margin: 0;
			padding: 0 0 10px 0;
		}


		h1 {
			max-width: 450px;
			padding: 0;
			margin: 0 auto 0 auto !important;
			text-align: center;
			text-transform: none;
			font-weight: normal;
			font-size: 0;
			color: $text-primary;
			span {
				font-size: 26px;
				color: $text-primary;
				padding: 0;
				font-weight: 400;
				@media(max-width: 767px) {
				    font-size: 20px;
				    text-align: center;
				    display: block;
			    	padding: 0px 20px 0 20px;
			    	margin: 0;
	    		}
			}
		}
		.product-info-stock-sku {
			display: block;
			width: 100%;
			padding: 0;
			text-align: center;
			margin: 0 0 35px 0;
			.stock {
				display: none;
			}
			.sku {
				strong {
					display: none;
				}
			}

		}
	}

	.special-net-price {
	    font-size: 17px;
	    color: #232323;
	    text-align: center;
	    font-weight: 300;
	    margin: 10px 0 0 0;
	}

	.product-options-bottom .box-tocart {
		position: relative;
		margin-top: 30px;
	}
	.product.data.items {
		margin: 0 0 20px;
		padding: 0;
		border: none;
	}
	.product.data.items > .item.title {
		box-sizing: border-box;
		float: none;
		width: 100%;
		height: 70px;
		border-bottom: 1px solid $text-primary;
		&:first-child {
			border-top: 1px solid $text-primary;
		}
	}
	.product.data.items > .item.title > .switch {
		display: block;
		background: none;
	}
	.data.item.content {
		&:after {
			display: none !important;
		}
	}

	.product.data.items > .item.content {
		box-sizing: border-box;
		display: block !important;
		border: none;
		float: none;
		margin: 0;
		max-height: 0px;
		overflow: hidden;
		transition: max-height 0.5s cubic-bezier(.29,.8,.29,.81);
		&:before, &:after {
			display: none !important;
		}
		&.active {
			max-height: 350px;
			transition: max-height 1s cubic-bezier(.29,.8,.29,.81);
		}
	}



	.product.data.items > .item.content::before, .product.data.items > .item.content::after {
		content: "";
		display: table;
	}
	.product.data.items > .item.content::after {
		clear: both;
	}
	.product.data.items > .item.content.active {
		display: block;
	}
	.product.data.items > .item.title {
		margin: 0;
		background: url('../images/icon-set/desktop/EXPAND-PLUS-DESKTOP.svg');
		background-size: 15px;
		background-repeat: no-repeat;
		background-position: right 25px center;
		padding: 15px 0 0 0;
		@media(max-width: 767px) {
			padding: 4px 0 0 0;
		}
		.data.switch {
			pointer-events: none;
		}
	}

	.product.data.items > .item.title.active {
		margin: 0;
		background: url('../images/icon-set/desktop/CLOSE-MINUS-DESKTOP.svg');
		background-size: 15px;
		background-repeat: no-repeat;
		background-position: right 25px center;
		border-bottom: none;
	}

	.product.data.items > .item.title > .switch {
		color: $text-primary;
		border: none;
		font-weight: 400;
		height: 72px;
		background: none !important;
		font-size: 16px;
		padding: 5px 0px;
		text-decoration: none;
		border-left: none;
		border-right: none;
		text-transform: uppercase;
		padding: 10px 0px;
		outline: none;
		&:after {
			display: none;
		}
	}
	.product.data.items > .item.title > .switch:visited {
		color: $text-primary;
		text-decoration: none;
	}
	.product.data.items > .item.title > .switch:hover {
		color: $text-primary;
	}
	.product.data.items > .item.title > .switch:active {
		color: $text-primary;
		text-decoration: none;
		background: none;
	}

	.product.data.items > .item.title:not(.disabled) > .switch:focus, .product.data.items > .item.title:not(.disabled) > .switch:hover {
		background: none;
	}

	.product.data.items > .item.content {
		background: #fff none repeat scroll 0 0;
		margin: 0 0 0 0;
		padding: 0;
	}
}
.block-viewed-products-grid {
	display: none;
}