.container.homepage-place-order {
    text-align: center;
    background-color: $brand-primary;
    padding: 38px 0;
    margin: 30px auto 0 auto;

    @media(max-width: $screen-sm) {
		max-width: 750px;
		padding: 20px 0;
	}

	.homepage-order-button {
		@extend .standard-bordered-button;
	    display: inline-block;
	    font-size: 24px;
	    line-height: 1;
	    padding: 10px 30px;
	    @media(max-width: $screen-sm) {
			padding-left: 29px;
		    padding-right: 29px;
		    padding-top: 15px;
		    padding-bottom: 15px;
		}
	}

}
.home-placeorder-content {
	a {
		color: $heading-color;
		font-weight: 700;
		text-transform: uppercase;
	}
	p {
		@include standard-font-heading;
		font-size: 24px;
		text-transform: uppercase;
		margin: 0 0 35px 0;
		@media(max-width: 768px) {
			font-size: 16px;
			font-weight: 100;
			padding-bottom: 10px;
		}
	}
}	

@media(max-width: 768px) {
.container.homepage-place-order {
	.homepage-order-button {
	    font-size: 16px;
		}
	}
}