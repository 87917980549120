#know-mobile {
	display: none;

	@media(max-width: $screen-md) {
		display: block;
	}

	button {
		@extend .standard-button;
	}


	.mobile-overall-total {
		font-size: 20px;
		text-transform: uppercase;
		font-weight: 600;
		text-align: center;
		margin: 0 0 35px 0;
	}


	small {
		margin-bottom: 15px;
		display: block;
		margin-top: 5px;
		padding: 10px 15px 10px 15px;

		&.success {
		    display: block;
    		margin-top: 5px;
    		background-color: #d3eac0;
    		color: #232323;
		}
		&.error {
			background-color: #f59a9a;
			color: #fff;
		}
	}


	.template {
		display: none;
		text-align: right;
	}

	.product-title {
		font-size: 16px;
		text-align: right;
		margin: 0 0 15px 0;
		display: block;
	}

	.product-sku {
		text-align: right;
		display: block;
		font-size: 16px;
		margin: 0 0 15px 0;
	}

	.product-total {
		text-align: right;
		display: block;
		font-size: 20px;
		font-weight: 600;
			@media(max-width: 425px) {
				float: right;
			}
	}

	.product-quantity {
		text-align: right;
		margin: 0 0 10px 0;
	}

	.product-quantity-box {
		height: 40px;
		text-align: center;
		max-width: 150px;
		display: inline-block;
	}

	.mobile-product-form {
		
		&-row {
			margin-bottom: 40px;
		}
		
		input {
			@extend .standard-input;
			margin: 0 0 0 0;
			font-size: 16px;
		}

		button {
			@extend .standard-button;
			height: 46px;
		}
	}
}