#maincontent {
	padding: 40px 15px 40px 15px;
}

body.page-layout-landing-page {
	#maincontent {
		padding: 0;
	}
}

body.page-products {
	#maincontent {
		padding-left: 0;
		padding-right: 0;
		@media(max-width: $screen-md) {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}

body.account {
	#maincontent {
		@media(max-width: $screen-sm) {
			padding: 60px 15px 40px 15px;
		}
	}
}

body.catalog-product-view {
	#maincontent {
		padding: 0px 15px 0px 15px;
	}
}