@mixin icon-standard {
	background-repeat: no-repeat;
}

@mixin icon-arrow-left {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/left-arrow-social.svg');
}

@mixin icon-currency {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/account-currency.png');
}

@mixin icon-discount {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/account-discount.png');
}

@mixin icon-vat {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/VAT-ICON.svg');
	background-size: 25px 25px;
}

@mixin icon-tick {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/TICK.svg');
}

@mixin icon-arrow-top {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/DESKTOP-UP-ARROW.svg');
}

@mixin icon-video {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/video-icon.png');
}

@mixin icon-arrow-bottom {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/DESKTOP-DOWN-ARROW.svg');
}

@mixin icon-related-arrow-left {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/DESKTOP-RELATED-PRODUCTS-LEFT-ARROW.svg');
}

@mixin icon-related-arrow-right {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/DESKTOP-RELATED-PRODUCTS-RIGHT-ARROW.svg');
}

@mixin icon-arrow-right {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/right-arrow-social.svg');
}

@mixin icon-phone {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/phone-icon.svg');
	background-size: 27px 29px;
}

@mixin icon-expand {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/EXPAND-PLUS-DESKTOP.svg');
	background-size: 32px 25px;
}

@mixin icon-email {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/email-icon.svg');
	background-size: 28px auto;
}

@mixin icon-email-white {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/email-icon-white.svg');
	background-size: 28px auto;
}

@mixin icon-name {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/name-icon.svg');
	background-size: 27px 25px;
}

@mixin icon-plus {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/EXPAND-PLUS-DESKTOP.svg');
}

@mixin icon-minus {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/CLOSE-MINUS-DESKTOP.svg');	
}

@mixin icon-order {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/return-icon.svg');
	background-size: 23px 23px;
}

@mixin icon-paypal {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/paypal-icon.svg');
	background-size: 28px 28px;
}

@mixin icon-paypal-white {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/PAYPAL-ICON-WHITE.png');
	background-size: 28px 28px;
}

@mixin icon-paypal-payments {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/PAYPAL-PAYMENTS-ICON.svg');
}

@mixin icon-same-address {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/same-delivery-address-icon.svg');
	background-size: 28px 28px;
}

@mixin icon-delivery {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/delivery-icon.svg');
	background-size: 23px 23px;
}

@mixin icon-delivery-white {
	@include icon-standard;	
	background-image: url('../images/icon-set/desktop/DESKTOP-DELIVERY-ICON-WHITE.svg');
	background-size: 23px 23px;
}

@mixin icon-country {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/country-icon.svg');
	background-size: 28px 28px;
}

@mixin icon-city {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/city-icon.svg');
	background-size: 28px 28px;
}

@mixin icon-street {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/street-name-icon.svg');
	background-size: 28px 28px;
}

@mixin icon-state {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/state-province-icon.svg');
	background-size: 28px 28px;
}

@mixin icon-print {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/print-icon.svg');
	background-size: 28px 28px;
}



@mixin icon-instagram {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/inspiration-instagram.svg');
}

@mixin icon-facebook-inspiration {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/DESKTOP-INSPIRATION-HEADER-FACEBOOK.svg');
    background-size: 33px 43px;
}

@mixin icon-instagram-inspiration {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/DESKTOP-INSPIRATION-HEADER-INSTAGRAM.svg');
	background-size: 40px 40px;
}

@mixin icon-ssl {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/ssl-secure-login.svg');
	background-size: 28px 28px;		
}

@mixin icon-cursor {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/cursor.svg');
	background-size: 28px 28px;
}

@mixin icon-building-number {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/house.svg');
	background-size: 28px 28px;
}

@mixin icon-credit {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/credit-card-icon.svg');
	background-size: 28px 28px;	
}

@mixin icon-credit-white {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/DESKTOP-WHITE-CREDIT-CARD-ICON.svg');
	background-size: 28px 28px;
}

@mixin icon-voucher {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/voucher-code-icon.svg');
	background-size: 28px 27px;	
}

@mixin icon-attach {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/attach.svg');
	background-size: 28px 28px;	
}

@mixin icon-postcode {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/look-up-icon.svg');
	background-size: 28px 28px;	
}

@mixin icon-password {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/password-icon.svg');
	background-size: 28px auto;
}

@mixin icon-shipping-white {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/delivery-address-icon-white-01.svg');
	background-size: 28px 28px;
}

@mixin icon-password-white {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/password-icon-white.svg');
	background-size: 28px auto;
}

@mixin icon-password-confirm {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/password-confirm-icon.svg');
	background-size: 23px 23px;
}

@mixin icon-facebook {
	@include icon-standard;
	//background-image: url('../images/icon-set/desktop/facebook-social-icon.svg');
}

@mixin icon-message {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/message-desktop-icon.svg');
	background-size: 25px;
}

@mixin icon-return {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/return-icon.svg');
	background-size: 23px 23px;
}

@mixin icon-barcode {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/sku-desktop-icon.svg');
	background-size: 22px 23px;
}

@mixin icon-company {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/COMPANY-NAME-ICON.svg');
	background-size: 22px 22px;
}

@mixin icon-dropdown {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/dropdown-arrow-icon.svg');
	background-size: 20px 20px;
}

@mixin icon-error {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/FAIL-ICON.svg');
	background-size: 27px 27px;
}

@mixin icon-close {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/CLOSE-CROSS-DESKTOP.svg');
}

@mixin icon-success {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/SUCCESS-TICK-ICON-01.svg');
	background-size: 27px 27px;
}

@mixin icon-warning {
	@include icon-standard
	background-image: url('../images/icon-set/desktop/WARNING-ICON.svg');
	background-size: 26px 26px;
}

$icon-hover-bag: "../images/icon-set/desktop/DESKTOP-HOVER-ADD-TO-BAG.png";
$icon-hover-quickview: "../images/icon-set/desktop/DESKTOP-HOVER-QUICKVIEW.png";


@mixin icon-footer-facebook {
	@include icon-standard;
	background-image: url('../images/icon-set/footer/DESKTOP-FACEBOOK.svg');
}

@mixin icon-footer-instagram {
	@include icon-standard;
	background-image: url('../images/icon-set/footer/DESKTOP-INSTAGRAM.svg');
}

@mixin icon-footer-pinterest {
	@include icon-standard;
	background-image: url('../images/icon-set/footer/DESKTOP-PINTEREST.svg');
}

@mixin icon-footer-twitter {
	@include icon-standard;
	background-image: url('../images/icon-set/footer/DESKTOP-TWITTER.svg');
}

@mixin icon-search {
	@include icon-standard;
	background-image: url('../images/icon-set/desktop/DESKTOP-SMALL-SEARCH.svg');
}
@mixin state-province-icon {
	@include icon-standard;
	background-image: url('../images/icon-set/state-province-icon.png');
}
@mixin delivery-address-icon {
	@include icon-standard;
	background-image: url('../images/icon-set/delivery-address-icon.png');
}
@mixin website-icon {
	@include icon-standard;
	background-image: url('../images/icon-set/WEBSITE-ICON.png');
}
