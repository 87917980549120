/**
 * = Summary - Checkout SCSS File
 * 
 * This SCSS file is for the checkout summary section on LC Designs
 * This section typically appears as the third item on the checkout
 * 
======================================================================== */

	.opc-wrapper {
		@media (max-width: $screen-md-max) {
			width: 100% !important;
			float: none !important;
		}
	}

	div#iosc-summary {

		@media(max-width: $screen-sm) {
			border-bottom: none !important;
			padding-bottom: 0;
		}

		.totals-tax-summary {
			td, th {
				border: none !important;
				text-transform: uppercase;
				font-weight: 300;
				padding-top: 0;
			}
		}

		.block.items-in-cart {
			margin: 0;
		}

		.details-qty {
			margin: 0 0 20px 0;
		}		
		
		@media (max-width: $screen-md-max) {
			width: 100% !important;
			margin-top: 30px;			
		}

		@media(max-width: $screen-sm) {
			margin-top: 0;
		}

		.minicart-items .product-item:not(:first-child) {
 				border-top: 1px solid $text-primary;
		}

		.minicart-items-wrapper {
			padding: 0 0 0px 0;
			margin: 0 0 15px 0;
			border-bottom: 1px solid #232323;
		}

		.product-item-details {

		}

		 span.step-title {
			font-size: 16px;
			text-transform: uppercase;
			display: block;
			padding: 0 0 50px 0;
			color: $text-primary;
			text-align: center;
			font-weight: 300;
		}

		.not-calculated {
			font-style: normal;
		}


		.product-image-wrapper img {
			border-radius: 0px;
		}

		.table-totals {
			border-color: $text-primary;
			border: none;
			color: $text-primary;
			padding: 15px 0 0 0;
		}

		.mark {
			background: none;
			.value {
				color: $text-primary;
			}
		}
		.product-image-container {
			width: auto !important;
		}

		.totals {
			font-size: 16px;
			font-weight: 300;
			text-transform: uppercase;

			&.sub {
				th.mark {
					padding-top: 0px;
					padding-bottom: 15px;
				}
			}

			&.shipping {
				.label {
					font-weight: 300;
					font-size: 16px;
					color: $text-primary;
					text-align: left;
					display: block;
					padding: 0px;
				}
				th.mark {
					padding-top: 0px;
					padding-bottom: 15px;
				}
			}

			th {
				padding-top: 15px;
				padding-bottom: 15px;
			}

			th, td {
				vertical-align: middle;
				color: $text-primary;
				border: none;
				padding-top: 0;
				font-weight: 300;
			}

			&.grand.totals {
				strong {
					font-weight: 400;
				}
			}
		}

		.product-item .subtotal {
			text-align: right;
			display: block;
			margin-top: 10px;
		}

		.product.options {
			text-align: right;
			font-size: 16px;
			font-weight: 300;
			.toggle {
				display: none;
			}
			.label, .values {
				font-size: 14px;
				font-weight: 300;
				margin: 0 0 5px 0;
				padding: 0;
				color: $text-primary;
			}
			.values {
				font-weight: normal;
			}
			.content {
				display: block !important;
			}
		}

		.product-item-details {
			padding-left: 170px;
		}

		.product-item .product-item-name-block {
			display: block;
			text-align: right;
		}

		.product-item-name {
			font-weight: 300;
			margin: 0 0 30px 0;
		}

		.product-item-inner {
			text-align: right;
			display: block;
		}

		.product-item {
			.price {
				font-weight: 400;
    			font-size: 21px;
    			vertical-align: middle;
			}
		}
		.details-qty {
			.label {
				padding: 0px;
				color: $text-primary !important;
				font-size: 16px;
				font-weight: normal;
			}
		}
	}
